import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../components/Texts/DisplayText'
import ImageContainer from '../../components/ImageContainer'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../../shared/utility'
import { formData } from '../../modals/data'
import ToolbarTable from '../../../../components/Structure/Navigation/ToolbarTable'
import SelectForm from '../../../../components/Forms/SelectForm'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import ChangeImageModal from './ChangeImageModal'
import { request_promotionalbanner, request_categories, request_update_promotionalbanner } from '../requests'
import { catalogs } from '../../../../texts/esp/catalogs'


const EditModal = ({open, onClose, onUpdateCompleted, content, id}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [banner, setBanner] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [disabled, setDisabled] = useState(false)

    //modals
    const [image_modal, setImageModal] = useState(false)

    useEffect(() => {
    const onInitModal = async() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            let _form = onInitEmptyForm(form, content.form)
            _form.enabled.options = catalogs.general_statues
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }else{
            const _banner = await request_promotionalbanner(id)
            setBanner(_banner.promotional_banner)
            let _form = onInitForm(form, _banner.promotional_banner)
            const _categories = await request_categories()
            const cat = onGetCatalogs(_categories.categories_data, 'id_category', 'name')
            _form.category_id.options = cat
            //console.log(catalogs)
            setForm(_form)
        }
    }
    onInitModal()
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetSelectedFormData(form, banner)
        //console.log(data2send)
        //console.log(id)

        setLoading(true)
        setDisabled(true)
        try {
            await request_update_promotionalbanner(id, data2send);
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    const onLocalUpdateCompleted = async () => {
        const _banner = await request_promotionalbanner(id)
        setBanner(_banner.promotional_banner)
        onUpdateCompleted()
    }

    //image web - mobile
    const menu = content.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id) => {
        if(id === 1) setType('web')
        if(id === 2) setType('mobile')
        setImageView(id)
    }

    let contentView = null

    switch(image_view){
        case 1:
            contentView = <ImageContainer image={banner ? banner.banner_web : null} onClick={()=>setImageModal(true)}/>
            break
        case 2:
            contentView = <ImageContainer image={banner ? banner.banner_mobile : null} onClick={()=>setImageModal(true)}/> 
            break
        default:
            break
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm' >
            <ChangeImageModal open={image_modal} onClose={()=>setImageModal(false)} type={type} id={banner ? banner.id_promotional_banner: null}
            onUpdateCompleted={onLocalUpdateCompleted} />
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.edit_title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {contentView}
                            <div style={{margin:'16px 0px'}}>
                            <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.link} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.category_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.order} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.enabled} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.text_1} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.text_2} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.text_3} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.text_4} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.edit_button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default EditModal


