import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import * as actionTypes from '../../../store/actions'
import Page from '../../../components/Structure/Page/Page'
import UserInfoCard from './components/UserInfoCard'
import { catalogs } from '../../../texts/esp/catalogs'
import { onSetErrorsToForm, isFormValid, onInitForm, onGetSelectedFormData, onGetErrorMessage, onInitEmptyForm } from '../../../shared/utility'
import { request_user, request_update_user } from './requests'
import UpdatePasswordModal from './modals/UpdatePasswordModal'
import ChangeImageModal from './modals/ChangeImageModal'
import GeneralInfo from './components/GeneralInfo'
import ContactInfo from './components/ContactInfo'
import AdditionalInfo from './components/AdditionalInfo'
import { translations as translations_esp } from '../../../texts/esp/user-translations'
import ToolbarTable from '../../../components/Structure/Navigation/ToolbarTable'
import MedicalInfo from './components/MedicalInfo'

const DoctorView = props => {

    const content = translations_esp
    const { match } = props
    //global props
    const { onUpdateSending, onUpdateSuccess, onUpdateError } = props
    const userID = match.params.id

    //general
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [sending2, setSending2] = useState(false)
    const [sending3, setSending3] = useState(false)
    const [user, setUser] = useState(null)
    
    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    //modals 
    const [update_image_modal, setUpdateImageModal] = useState(false)
    const [password_modal, setPasswordModal] = useState(false)

    //checks
    const [indigenous_check, setIndigenousCheck] = useState(false)
    

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _user = await request_user(userID)
            //console.log(_user)
            setUser(_user.user)
            let _form = onInitEmptyForm(form, content.form)
            _form = onInitForm(form, _user.user)
            setForm(_form)
            setIndigenousCheck(Boolean(_user.user.indigenous))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onUpdateCompleted = async() => {
        try {
            const _user = await request_user(userID)
            const _form = onInitForm(form, _user.user)
            setUser(_user.user)
            setForm(_form)
            setUpdateImageModal(false)
            setPasswordModal(false)

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    
    const onSubmitGeneral = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        data2send.indigenous = indigenous_check ? 1 : 0
        if(data2send.indigenous === 0){
            data2send.ethnic_group=''
            data2send.ethnic_language=''
        }
        console.log(data2send)
        setSending(true)
        onUpdateSending(true)
        try {
            await request_update_user(userID, data2send);
            const _user = await request_user(userID)
            setUser(_user.user)
            setSending(false)
            onUpdateSending(false)
            //setSucess(content.success_msg)
            onUpdateSuccess(content.success_msg)
        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
            onUpdateError(onGetErrorMessage(error))
            onUpdateSending(false)
            setSending(false)
        }
    }

    const onSubmitContact = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        data2send.indigenous = indigenous_check ? 1 : 0
        if(data2send.indigenous === 0){
            data2send.ethnic_group=''
            data2send.ethnic_language=''
        }
        console.log(data2send)
        setSending2(true)
        onUpdateSending(true)
        try {
            await request_update_user(userID, data2send);
            const _user = await request_user(userID)
            setUser(_user.user)
            setSending2(false)
            onUpdateSending(false)
            onUpdateSuccess(content.success_msg)
            //setSucess(content.success_msg)
        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
            onUpdateError(onGetErrorMessage(error))
            setSending2(false)
            onUpdateSending(false)
        }
    }

    const onSubmitAdditional = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        data2send.indigenous = indigenous_check ? 1 : 0
        if(data2send.indigenous === 0){
            data2send.ethnic_group=''
            data2send.ethnic_language=''
        }
        console.log(data2send)
        setSending3(true)
        onUpdateSending(true)
        try {
            await request_update_user(userID, data2send);
            const _user = await request_user(userID)
            setUser(_user.user)
            setSending3(false)
            onUpdateSending(false)
            //setSucess(content.success_msg)
            onUpdateSuccess(content.success_msg)
        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
            onUpdateError(onGetErrorMessage(error))
            setSending3(false)
            onUpdateSending(false)
        }
    }

    const onGetError = (error) => {
        //setError(onGetErrorMessage(error))
        onUpdateError(onGetErrorMessage(error))
    }

    //views
    const menu = content.menu
    const [view, setView] = useState(1)

    const onChangeView = (id) => {
        setView(id)
    }

    let contentView = null

    switch(view){
        case 1:
            contentView =  <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <GeneralInfo content={content.cards} form={form} onChange={onChange} sending={sending} onSubmit={onSubmitGeneral}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ContactInfo content={content.cards} form={form} onChange={onChange} sending={sending2} onSubmit={onSubmitContact}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <AdditionalInfo content={content.cards} form={form} onChange={onChange} sending={sending3} onSubmit={onSubmitAdditional}
                                    check={indigenous_check} onChangeCheck={()=>setIndigenousCheck(!indigenous_check)}/>
                                </Grid>
                            </Grid>
            break
        case 2:
            contentView = <MedicalInfo content={content} id={userID} onSuccess={()=>onUpdateSuccess(content.success_msg)} 
            onGetError={onGetError} onUpdateSending={onUpdateSending} />
            break
        default:
            break
    }

    
    return(
        <Page title={content.titles.doctor} loading={loading} goback 
        //success={success} onCloseSuccess={() => setSucess(null)}
        //error={error} onCloseErrorModal={()=>setError(null)}
        >
            <UpdatePasswordModal content={content.modals.update_password} userID={userID} open={password_modal} 
            onClose={() => setPasswordModal(false)} onUpdateCompleted ={onUpdateCompleted} 
            onUpdateSuccess={onUpdateSuccess} onUpdateError={onUpdateError}/>
            <ChangeImageModal content={content.modals.change_image} id={userID} open={update_image_modal} 
            onClose={() => setUpdateImageModal(false)} onUpdateCompleted ={onUpdateCompleted}/>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <UserInfoCard content={content.cards} {...user} onChangePicture={() => setUpdateImageModal(true)} onChangePassword={() => setPasswordModal(true)} />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <div style={{marginBottom:12}}>
                    <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
                    </div>
                    {contentView}
                </Grid>
            </Grid>
        </Page>
    )
} 

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(DoctorView)

const formData = {
    /*------ general info ------*/
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    fathers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido paterno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    mothers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido materno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    curp: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'curp',
            type: 'text',
            fullWidth: true,
            label: 'CURP',
            helperText: 'CURP no válida'
        },
        rules: {
            type: 'distance',
            min: 18, max: 18
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha de nacimiento',
            helperText: 'Este campo debe contener una fecha válida'
        },
        rules: {
            type: 'date',
        }
    },
    user_gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'user_gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
    },
    
    /*------ contact info ------*/
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    mobile_country_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.countries],
        config: {
            id: 'mobile_country_id',
            type: 'select',
            fullWidth: true,
            label: 'Código',
            helperText: 'Debe elegir una opción',
        },

    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono celular',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, 
    fixed_phone_country_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.countries],
        config: {
            id: 'fixed_phone_country_id',
            type: 'select',
            fullWidth: true,
            label: 'Código',
            helperText: 'Debe elegir una opción',
        },

    },
    fixed_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'fixed_phone',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono fijo',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, 
    zip: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'text',
            fullWidth: true,
            label: 'Código postal',
            helperText: 'No es un código postal válido'
        },
        rules: {
            type: 'zip',
            //min: 1, max: 256
        }
    },
    city: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'city',
            type: 'text',
            fullWidth: true,
            label: 'Ciudad',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },

    /*------ additional info ------*/
    blood_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.blood_types],
        config: {
            id: 'blood_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de sangre',
            helperText: 'Debe elegir una opción',
        },
    },
    civil_status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.civil_statuses],
        config: {
            id: 'civil_status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estado civil',
            helperText: 'Debe elegir una opción',
        },
    },
    occupation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'occupation',
            type: 'text',
            fullWidth: true,
            label: 'Ocupación',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    scolarity_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.scolarities],
        config: {
            id: 'scolarity_id',
            type: 'select',
            fullWidth: true,
            label: 'Escolaridad',
            helperText: 'Debe elegir una opción',
        },
    },
    religion: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'religion',
            type: 'text',
            fullWidth: true,
            label: 'Religión',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ethnic_group: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ethnic_group',
            type: 'text',
            fullWidth: true,
            label: 'Grupo étnico',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ethnic_language: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ethnic_language',
            type: 'text',
            fullWidth: true,
            label: 'Dialecto',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    currency_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.currency],
        config: {
            id: 'currency_id',
            type: 'select',
            fullWidth: true,
            label: 'Moneda',
            helperText: 'Debe elegir una opción',
        },

    },
}