import React, {useEffect, useState} from 'react'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import Price from './sections/Price'
import GeneralInfo from './sections/GeneralInfo'
import MainCard from './sections/MainCard'
import Tags from './sections/Tags'
import ProductImages from './sections/ProductImages'
import Categories from './sections/Categories'
import Subcategories from './sections/Subcategories'
import Segments from './sections/Segments'
import { request_actives, request_brands, request_categories_by_product, request_product, request_product_images, request_product_price, 
    request_segments_by_product, request_subcategories_by_product, request_subsegments_by_product, request_tags_by_product } from './requests'
import { translations as translations_esp } from '../../../texts/esp/product-translations'
import { onGetErrorMessage } from '../../../shared/utility'
import Subsegments from './sections/Subsegments'
import { actionTypes } from '../../../store/actions'
import ActivesTable from './components/ActivesTable'
import DisplayText from '../../../components/Texts/DisplayText'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import AddActiveModal from './modals/AddActiveModal'
import DeleteActiveModal from './modals/DeleteActiveModal'
import EditActiveModal from './modals/EditActiveModal'

const ProductView = props => {

    const { match, onUpdateSending, onUpdateSuccess, onUpdateError } = props
    const id = match.params.id
    const content = translations_esp
    
    //general
    const [loading, setLoading] = useState(false)
    //const [error, setError] = useState(null)
   
    const [brands, setBrands] = useState([])
    const [images, setImages] = useState([])
    const [prices, setPrices] = useState([])
    const [product, setProduct] = useState(null)
    const [product_categories, setProductCategories] = useState([])
    const [product_segments, setProductSegments] = useState([])
    const [product_subcategories, setProductSubcategories] = useState([])
    const [product_subsegments, setProductSubsegments] = useState([])
    const [product_tags, setProductTags] = useState([])
    const [actives, setActives] = useState([]);

    //modals
    const [active_modal, setActiveModal] = useState(false);
    const [delete_active, setDeleteActive] = useState(false);
    const [edit_active, setEditActive] = useState(false);
    const [id_active, setIdActive] = useState(null);
    const [active, setActive] = useState(null);
    
  
    useEffect(() => {
        const initModule = async() => {
        try {
            setLoading(true)
            //requests
            const _product = await request_product(id)
            setProduct(_product.product_data)
            const _brands = await request_brands()
            setBrands(_brands.brands_data)
            const _images = await request_product_images(id)
            setImages(_images.product_images)
            const _price = await request_product_price(id)
            setPrices(_price.products_data)      
            const _product_categories = await request_categories_by_product(id)
            setProductCategories(_product_categories.product_category_data)
            const _product_segments = await request_segments_by_product(id)
            setProductSegments(_product_segments.product_segment_data)
            const _product_subcategories = await request_subcategories_by_product(id)
            setProductSubcategories(_product_subcategories.product_category_data)
            const _product_subsegments = await request_subsegments_by_product(id)
            setProductSubsegments(_product_subsegments.product_sub_segment_data)
            const _product_tags = await request_tags_by_product(id)
            setProductTags(_product_tags.product_tag_data)
            const _actives = await request_actives(id)
            setActives(_actives.product_active_substances)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            onUpdateError(onGetErrorMessage(error))
            console.log(error)
        }}
        initModule()
    }, [])


    //Updates
    const onUpdateBrandsCompleted = async() => {
        const _brands = await request_brands()
        setBrands(_brands.brands_data)
    }

    const onUpdateActivesCompleted = async() => {
        const _actives = await request_actives(id)
        setActives(_actives.product_active_substances)
    }

    const onUpdateCategoriesCompleted = async() => {
        const _categories = await request_categories_by_product(id)
        setProductCategories(_categories.product_category_data)
    }

    const onUpdateImagesCompleted = async() => {
        const _images = await request_product_images(id)
        setImages(_images.product_images)
    }

    const onUpdateProductCompleted = async() => {
        const _price = await request_product_price(id)
        const _product = await request_product(id)
        setProduct(_product.product_data)
        setPrices(_price.products_data)
    }

    const onUpdateSegmentsCompleted = async() => {
        const _segments = await request_segments_by_product(id)
        setProductSegments(_segments.product_segment_data)
    }

    const onUpdateSubcategoriesCompleted = async() => {
        const _subcategories = await request_subcategories_by_product(id)
        setProductSubcategories(_subcategories.product_category_data)
    }

    const onUpdateSubsegmentsCompleted = async() => {
        const _subsegments = await request_subsegments_by_product(id)
        setProductSubsegments(_subsegments.product_sub_segment_data)
    }

    const onUpdateTagCompleted = async() => {
        const _tags = await request_tags_by_product(id)
        setProductTags(_tags.product_tag_data)
    }

    const onUpdateProduct = async () =>{
        try{
            onUpdateSending(true)
            const _product = await request_product(id)
            setProduct(_product.product_data)
            //onUpdateSuccess(true)
        }catch(error){
            onUpdateError(onGetErrorMessage(error))
        }
        onUpdateSending(false)
    }

    const onDeleteActive = (value) => {
        setIdActive(value)
        setDeleteActive(true)

    }

    const onEditActive = (item) => {
        setIdActive(item.id_product_active_substance)
        setActive(item)
        setEditActive(true)

    }

    
    return(
        <Page title={content.title} loading={loading} goback >
            <AddActiveModal open={active_modal} onClose={()=>setActiveModal(false)} onUpdateCompleted={onUpdateActivesCompleted} id={id}/>
            <EditActiveModal open={edit_active} onClose={()=>setEditActive(false)} onUpdateCompleted={onUpdateActivesCompleted} id={id} id_active={id_active}
                origin={active}/>
            <DeleteActiveModal open={delete_active} onClose={()=>setDeleteActive(false)} onUpdateCompleted={onUpdateActivesCompleted} id={id} id_active={id_active} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} xl={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MainCard id={id} product={product} brands={brands} 
                            onUpdateBrandsCompleted={onUpdateBrandsCompleted} 
                            onUpdateImagesCompleted={onUpdateImagesCompleted}
                            onUpdateProduct={onUpdateProduct} 
                            id_main={product ? product.product_image_id : null}
                            onUpdateSending={onUpdateSending}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Tags id={id} product_tags={product_tags} 
                            onUpdateTagCompleted={onUpdateTagCompleted} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Categories id={id} product_categories={product_categories} 
                            onUpdateCompleted={onUpdateCategoriesCompleted} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Subcategories id={id} product_subcategories={product_subcategories} 
                            onUpdateCompleted={onUpdateSubcategoriesCompleted} product_categories={product_categories} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Segments id={id} product_segments={product_segments} 
                            onUpdateCompleted={onUpdateSegmentsCompleted} product_subcategories={product_subcategories}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Subsegments id={id} product_subsegments={product_subsegments} product_segments={product_segments} 
                            onUpdateCompleted={onUpdateSubsegmentsCompleted} product_subcategories={product_subcategories}
                            />
                        </Grid>                               
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8} xl={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <GeneralInfo id={id} product={product}
                            onUpdateCompleted={onUpdateProductCompleted} 
                            onUpdateSuccess={onUpdateSuccess} onUpdateSending={onUpdateSending}
                            onUpdateError={onUpdateError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleCard>
                                <DisplayText variant='subtitle1'>Activos</DisplayText>
                                <ActivesTable 
                                    data={actives}
                                    //tableFilter={tableFilter}
                                    //search={search}
                                    //total={total}
                                    //loading={loading}                             
                                    onAddRegister={() => setActiveModal(true)}
                                    onDeleteItem={onDeleteActive}
                                    //onChangeSearch={onChangeSearch}
                                    //onUpdateTableFilter={(data) => setTableFilter(data)}
                                    onSelectedItem={onEditActive}
                                    />
                            </SimpleCard>
                        </Grid>
                        <Grid item xs={12}>
                            <Price id={id} prices={prices} product={product} 
                            onUpdateCompleted={onUpdateProductCompleted}
                            onUpdateSuccess={onUpdateSuccess} onUpdateSending={onUpdateSending}
                            onUpdateError={onUpdateError}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ProductImages id={id} images={images} 
                            onUpdateImagesCompleted={onUpdateImagesCompleted}
                            onUpdateProductCompleted={onUpdateProductCompleted}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.SYS_UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.SYS_UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(ProductView) 

