
export const translations = {
    title:'Sucursales',
    table: {
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Nombre'},
            {id:3, label:'Nombre de contacto'},
            {id:4, label:'Email'},
            {id:5, label:'Teléfono'},
            {id:6, label:'Dirección'},
            {id:7, label:''},
        ],
        nodata:'No existen sucursales registradass'
    },
    modal: {
        add_title:'Agregar',
        subtitle:'Por favor ingresa los datos solicitados',
        title2:'Información de la dirección',
        title3:'Servicios',
        button:'Agregar',
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_name:{
                label:'Nombre de contacto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_email:{
                label:'Email',
                helperText:'Email no válido'
            },
            contact_phone:{
                label:'Telefono de contacto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            street:{
                label:'Calle',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            ext:{
                label:'Número exterior',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            int:{
                label:'Número interior',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            additional_indications:{
                label:'Indicaciones adicionales',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            zip_code:{
                label:'Código postal',
                helperText:'Código postal no válido'
            },
            municipality_id:{
                label:'Municipio',
                helperText:'Debe seleccionar una opción',
            },
            state_id:{
                label:'Estado',
                helperText:'Debe seleccionar una opción'
            },
            country_id:{
                label:'País',
                helperText:'Debe seleccionar una opción'
            },
            suburb_id:{
                label:'Colonia',
                helperText:'Debe seleccionar una opción'
            },
            latitude:{
                label:'Latitud',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            longitude:{
                label:'Longitud',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
        },
        formControl:{
            store:'Productos en tienda',
            pickup:'Entrega en tienda',
            delivery:'Entrega a domicilio'
        }
    },
}
