import { Grid, Chip } from '@material-ui/core'
import React, {useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import SearchSubcategories from '../forms/SearchSubcategories'
import AddSubcategoryModal from '../modals/AddSubcategoryModal'
import { request_create_product_subcategory, request_delete_product_subcategory } from '../requests'
import { translations as translations_esp } from '../../../../texts/esp/product-translations'


const Subcategories = ({id, product_subcategories, onUpdateCompleted, product_categories }) => {

    const content = translations_esp.subcategories

    const [add_modal, setAddModal] = useState(false)

    const onDeleteItem = async(el) => {
        await request_delete_product_subcategory(el)
        onUpdateCompleted()
    }

    const onAddProductItem = async (el) => {
        try {
            await request_create_product_subcategory({product_id:id, sub_category_id:el});
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <SimpleCard>
        <AddSubcategoryModal open={add_modal} onClose={()=>setAddModal(false)} onUpdateCompleted={onUpdateCompleted} 
        onAddProductItem={onAddProductItem} content={content.modal}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>                 
                    <DisplayText variant='subtitle1'>{content.title}</DisplayText>                
                </Grid>
                <Grid item xs={12}>
                    <SearchSubcategories onAddItem={()=>setAddModal(true)} existingItems={product_subcategories} 
                    onAddProductItem={onAddProductItem} content={content.search_bar} product_categories={product_categories}/>
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={1}>
                        {product_subcategories.map((item, index)=>{
                            return(
                                <Grid item key={index}>
                                    <Chip label={item.sub_category} onDelete={()=>onDeleteItem(item.id_product_sub_category)} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>      
        </SimpleCard>
    )
}

export default Subcategories