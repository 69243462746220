import { amber, blue, cyan, green, indigo, orange, purple, red, teal, pink, lime } from "@material-ui/core/colors";

export const catalogs = {
    genders:[{value:1, label:'Hombre'},{value:2, label:'Mujer'},{value:3, label:'Otro'},{value:4, label:'Desconocido'}],
    user_statuses:[
        {value:1, color:red[700], label:'No registrado'},
        {value:2, color:blue[700], label:'Invitado'},
        {value:3, color:green[700], label:'Registrado'},
    ],
    currency:[
        {value:1, label:'Dolar (USD)', code:'USD', text_id:301},
        {value:2, label:'Peso (MXN)', code:'MXN', text_id:302},
    ],
    countries:[
        {value:1, label:'México (+52)', phone_code:'+52', flag:'mexico_flag'},
    ],
    blood_types:[
        {value:1, label:'O+'}, {value:2, label:'A+'}, {value:3, label:'B+'}, {value:4, label:'AB+'}, 
        {value:5, label:'O-'}, {value:6, label:'A-'}, {value:7, label:'B-'}, {value:8, label:'AB-'}, 
    ],
    civil_statuses:[
        {value:1, label:'Ninguno'}, {value:2, label:'Soltero'}, {value:3, label:'Unión libre'}, {value:4, label:'Casado'}, 
        {value:5, label:'Separado'}, {value:6, label:'Divorciado'}, {value:7, label:'Viudo'} 
    ],
    scolarities:[
        {value:1, label:'Ninguna'}, {value:2, label:'Educación primaria'}, {value:3, label:'Educación secundaria'}, 
        {value:4, label:'Educación media superios'}, {value:5, label:'Educación superior'}, 
        {value:6, label:'Maestría'}, {value:7, label:'Doctorado'} 
    ],
    languages:[
        {value:1, label:'Español'}, {value:2, label:'Inglés'}
    ],
    specialities:[
        {value:1, label:'Dermatología'}, {value:2, label:'Medicina general'}, {value:3, label:'Nutrición'},
        {value:4, label:'Psicología'}
    ],
    shipping_types:[
        {value:1, label:'Sí'}, {value:2, label:'No'}
    ],
    payment_statuses:[
        {value:-1, color:amber[700],label:'No realizado'},
        {value:1, color:amber[700],label:'Pendiente'},
        {value:2, color:orange[700],label:'Rechazado'},
        {value:3, color:teal[700],label:'Expirado'},
        {value:4, color:green[700],label:'Pagado'},
        {value:5, color:purple[700],label:'Reembolsado'},
        {value:6, color:purple[300],label:'Reembolso parcial'},
        {value:7, color:pink[700],label:'Contracargado'},
        {value:8, color:blue[700],label:'Pendiente de aprobación'},
        {value:9, color:red[700],label:'Cancelado'}   
    ],
    discount_types:[
        {value:1, label:'Descuento'},
        {value:2, label:'3x2'},
        {value:3, label:'Precio mayoreo'},
    ],
    order_statuses:[
        {value:-1, color:amber[700],label:'Todos', colorS:'#fff'},
        {value:1, color:amber[700],label:'Pago Pendiente', colorS:'#ffa000'},
        {value:2, color:indigo[700],label:'En preparación', colorS:'#303f9f'},
        {value:3, color:cyan[700],label:'Enviado', colorS:'#0097a7'},
        {value:4, color:green[700],label:'Entregado', colorS:'#388e3c'},
        {value:5, color:red[700],label:'Cancelado', colorS:'#d32f2f'},
        {value:6, color:orange[700],label:'Rechazado', colorS:'#f57c00'},
        {value:7, color:lime[500],label:'Regresado', colorS:'#cddc39'},
        {value:8, color:purple[700],label:'Reembolsado', colorS:'#7b1fa2'},
        {value:9, color:cyan[900],label:'Error de envio', colorS:'#006064'},
        {value:10, color:amber[900],label:'Error de pago', colorS:'#ff6f00'},
    ],
    route_statuses:[
        {value:1, color:amber[700],label:'Pendiente', colorS:'#ffa000'},
        {value:2, color:teal[700],label:'Asignado', colorS:'#303f9f'},
        {value:3, color:blue[700],label:'Preparando', colorS:'#0097a7'},
        {value:4, color:indigo[700],label:'Listo', colorS:'#388e3c'},
        {value:5, color:purple[700],label:'En transito', colorS:'#d32f2f'},
        {value:6, color:green[700],label:'Completado', colorS:'#f57c00'},
        {value:7, color:red[500],label:'Cancelado', colorS:'#cddc39'},
    ],
    order_types:[
        {value:1, label:'Entrega a domicilio'},
        {value:2, label:'Entrega en sucursal'},
    ],
    payment_types:[
        {value:1, label:'Tarjeta de crédito/débito'},
        {value:2, label:'Paypal'},
        {value:3, label:'Mercado Pago'}
    ],
    order_levels:[
        {value:1, label:'1'}, {value:2, label:'2'}, {value:3, label:'3'}, {value:4, label:'4'}, {value:5, label:'5'},
        {value:6, label:'6'}, {value:7, label:'7'}, {value:8, label:'8'}, {value:9, label:'9'}, {value:10, label:'10'},
        {value:11, label:'11'}, {value:12, label:'12'}, {value:13, label:'13'}, {value:14, label:'14'}, {value:15, label:'15'},
        {value:16, label:'16'}, {value:17, label:'17'}, {value:18, label:'18'}, {value:19, label:'19'}, {value:20, label:'20'},
        {value:21, label:'21'}, {value:22, label:'22'}, {value:23, label:'23'}, {value:24, label:'24'}, {value:25, label:'25'},
        {value:26, label:'26'}, {value:27, label:'27'}, {value:28, label:'28'}, {value:29, label:'29'}, {value:30, label:'30'},
        {value:31, label:'31'}, {value:32, label:'32'}, {value:33, label:'33'}, {value:34, label:'34'}, {value:35, label:'35'},
        {value:36, label:'36'}, {value:37, label:'37'}, {value:38, label:'38'}, {value:39, label:'39'}, {value:40, label:'40'},
        {value:41, label:'41'}, {value:42, label:'42'}, {value:43, label:'43'}, {value:44, label:'44'}, {value:45, label:'45'},
        {value:46, label:'46'}, {value:47, label:'47'}, {value:48, label:'48'}, {value:49, label:'49'}, {value:50, label:'50'},
    ],
    general_statues:[
        {value:0, color:red[700],label:'Inactivo'},
        {value:1, color:green[700],label:'Activo'},
    ],
        
}