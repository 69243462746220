
export const translations = {
    table: {
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Segmento'},
            {id:3, label:'Orden'},
            {id:4, label:'Subcategoría'},
            {id:5, label:'Descripción'},
            {id:6, label:'Color'},
            {id:7, label:''}
        ],
        nodata:'No existen segmentos registrados'
    },
    modal: {
        add_title:'Agregar segmento',
        button:'Agregar',
        form:{
            sub_category_id:{
                label:'Subcategoría',
                helperText:'Seleccione al menos una opción'
            },
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            color:{
                label:'Color',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            description:{
                label:'Descripción',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            order:{
                label:'Orden',
                helperText:'Seleccione una opción'
            },
            url:{
                label:'Url',
                helperText:'Este campo es obligatorio'
            }
        }
    },
}

