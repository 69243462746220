import axios from 'axios'
const { mainServer, BASIC_AUTH_HEADER } = require("../../variables/config");

//gets
export const request_warehouses = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/warehouse/list${params}` )
    return response.data.data
}

export const request_warehouse = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/warehouse/${id}` )
    return response.data.data
}

/*------ address ----- */
export const request_zipinfo = async(zip) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/address/zipinfo?zip=${zip}` )
    return response.data.data
}

export const request_zipcodes = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/address/zipcodes` )
    return response.data.data
}

export const request_suburbs = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/address/suburbs` )
    return response.data.data
}

export const request_states = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/address/states` )
    return response.data.data
}

export const request_municipalities = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/address/municipalities` )
    return response.data.data
}


//posts
export const request_create_warehouse = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/warehouse`, data)
    return response.data.data
}


//patchs
export const request_update_warehouse = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/warehouse/${id}`, data )
    return response.data.data
}

//deletes
export const request_delete_warehouse = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/warehouse/${id}`)
    return response.data.data
}

/*--------MEDIA--------*/
//posts
export const request_upload_storebanner_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/storebanner/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
}

//deletes
export const request_delete_image_file = async (id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    console.log(data)
    let response = await mainServer.delete(`/storebanner/${id}/image/${data}`)
    return response.data.data
}

