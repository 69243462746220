import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import * as actionTypes from '../../../../../store/actions'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import AddCategoryModal from './modals/AddCategoryModal'
import DeleteModal from './modals/DeleteModal'
import { request_categories } from './requests'

const CategoriesView = (props) => {

    const {history} = props
    //global props
    const { onUpdateSending , onUpdateError, onUpdateSuccess } = props

    //general
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [delete_modal, setDeleteModal] = useState(false)
 
    //table
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_category'})
    const [search, setSearch] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            window.scrollTo({top: 0, behavior: 'smooth'})
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            console.log(params)
            const _categories = await request_categories(params)
            setCategories(_categories.categories_data)
            setTotal(_categories.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])

    
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(tableFilter, search)
        const _categories = await request_categories(params)
        setCategories(_categories.categories_data)
        setTotal(_categories.count)
        setAddModal(false)
    }

    const onSelectedItem = (item, type) => {
        const {id_category} = item
        setSelected(id_category)
        if(type === 'edit') history.push(`/catalogs/category/${id_category}`)
        if(type === 'delete') setDeleteModal(true)
    }


    return(
        <div>
            <AddCategoryModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted}/>
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} 
            id={selected}/>
            <SimpleTable
                data={categories} 
                tableFilter={tableFilter}
                search={search}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeSearch={onChangeSearch}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
            />
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(CategoriesView)