import React, { useState } from 'react';
import PictureModal from '../../../../components/Overlays/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../shared/utility';
import { request_upload_image, request_delete_image } from '../requests';
import { translations as translations_esp } from '../../../../texts/esp/modals-translations'

const ChangeImageModal = (props) => {

    const {id, open, onClose, onUpdateCompleted} = props
    const content = translations_esp.change_image_modal
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        setDisabled(true)
        try {
            setLoading(true)
            console.log(file)
            const _file = await onGetImageURL2Blob(file)
            await request_upload_image(id, _file, {type:onGetFileExtension(_file.name)})
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
    }

    const onDelete = async() => {
        setDisabled(true)
        try {
            setError(null)          
            setLoading(true)
            await request_delete_image(id)
            onUpdateCompleted()
        } catch (error) {
            console.log(error.response)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
        //const {id_document} = selected_document
    }

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} disabled={disabled}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} onDeleteFile={onDelete}/>
     );
}
 
export default ChangeImageModal;