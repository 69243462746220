import React, {useState} from 'react'
import { Grid } from '@material-ui/core'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import ProductCheckoutList from './ProductCheckoutList'
import DeleteModal from '../modals/DeleteModal'
import noproduct from '../../../../assets/empty.png'
import { request_update_lineitem } from '../requests'


const ProductsContainer = (props) => {

    const {cart, onUpdateCompleted} = props

    const listItems = cart ? cart.line_items : []
    const order_id = cart ? cart.order_id : null
    const id_cart = cart ? cart.id_cart : null

    const [delete_lineitem_modal, setDeleteLineitemModal] = useState(false)
    const [line_item, setLineItem] = useState(null)

    const onDeleteItem = (data) => {
        setLineItem(data)
        setDeleteLineitemModal(true)
    }

    const onInnerUpdateCompleted = async() => {
        /* try {
            const _order = await request_order(order_id)
            onUpdateData(_order)
        } catch (error) {
            console.log(error)
        }
        setDeleteLineitemModal(false) */
    }

    const onUpdateLineItem = async(id_lineitem, data) => {
        //console.log(id_cart)
        try {
            await request_update_lineitem(id_cart, id_lineitem, data)
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <SimpleCard>
            <DeleteModal data={line_item} open={delete_lineitem_modal} onClose={() => setDeleteLineitemModal(false)}
            onUpdateCompleted={onUpdateCompleted}/>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>Lista de productos</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText align='right' color='textSecondary'>Total</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {listItems.map((item,key)=>{
                                    return(
                                        <Grid item xs={12} key={key.toString()} >
                                            <ProductCheckoutList {...item} onDeleteItem={onDeleteItem} onUpdateLineItem={onUpdateLineItem} order_id={order_id}/>
                                        </Grid>
                                    )
                                })}
                                {listItems.length === 0 ? (
                                    <Grid item xs={12}>
                                        <div style={{padding:40}}>
                                            <Grid container justify='center' direction='column' alignItems='center'>
                                                <Grid item>
                                                    <img src={noproduct} alt='' width={150} />
                                                </Grid>
                                                <Grid item>
                                                    <DisplayText>No existen productos</DisplayText>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>  
                    </Grid> 
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default ProductsContainer