import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React from 'react';
import { withRouter } from 'react-router';
import ProductImage from '../../../../components/Structure/Images/ProductImage';
import DisplayText from '../../../../components/Texts/DisplayText';
import { onGetFormatNumber } from '../../../../shared/utility';
import QuantityButton from './QuantityButton';

const useStyles = makeStyles(theme=>({
    sale:{
        color:red[500],
    },
    old_price:{
        textDecoration:'line-through',
        fontSize:'0.875rem',
        color:theme.palette.text.secondary
    },
    qxq:{
        borderRadius:24,
        backgroundColor:red[700],
        color:'white',
        padding:'2px 8px',
        margin:'4px 0px',
        display:'inline-block'
    }
}))

const ProductCheckoutList = (props) => {

    const classes = useStyles()

    const {main_product_image_mobile, quantity, product, price, sale, id_line_item, product_id, onUpdateLineItem, subtotal, 
        onDeleteItem, order_id} = props

    //sale (discounts)
    const { sale_discount_type_id, sale_discount, sale_price, sale_bundle_quantity, sale_bundle_price, sale_bundle_paid_quantity,
        sale_bundle_free_quantity, sale_bundle_included_free } = props

    const onChangeLineItem = (data) => {
        console.log(data)
        onUpdateLineItem(id_line_item, {quantity:data})

    }

    const singlePrice = onGetFormatNumber(subtotal)

    let saleView = null

    switch(sale_discount_type_id){
        case 1:
            saleView = <Grid container alignItems='center' spacing={1}>
                            <Grid item><DisplayText variant='subtitle1' className={classes.sale}>{onGetFormatNumber(sale_price)}</DisplayText></Grid>
                            <Grid item><DisplayText variant='body2' className={classes.old_price}>{onGetFormatNumber(price)}</DisplayText></Grid>
                            <Grid item><DisplayText variant='caption' color='textSecondary'>En descuento</DisplayText></Grid>
                        </Grid>
            break
        case 2:
            saleView = <Grid container alignItems='center' spacing={1}>
                            <Grid item><DisplayText variant='subtitle1'>{onGetFormatNumber(price)}</DisplayText></Grid>
                            <Grid item>
                                <div className={classes.qxq}>
                                <DisplayText variant='caption'>{(sale_bundle_paid_quantity + sale_bundle_free_quantity).toString() + ' x ' + sale_bundle_paid_quantity.toString()}</DisplayText>
                                </div>
                            </Grid>
                            <Grid item xs={12}><DisplayText variant='caption' className={classes.sale}>{'Pagas ' + quantity + ' y te llevas ' + (sale_bundle_included_free + quantity).toString()}</DisplayText></Grid>
                        </Grid>       
            break
        case 3:
            saleView = <Grid container alignItems='center' spacing={1}>
                            <Grid item><DisplayText variant='h6' className={classes.sale}>{onGetFormatNumber(sale_bundle_price)}</DisplayText></Grid>
                            <Grid item><DisplayText className={classes.old_price}>{onGetFormatNumber(price)}</DisplayText></Grid>
                            <Grid item><DisplayText variant='caption' color='textSecondary'>Precio de mayoreo</DisplayText></Grid>
                        </Grid>
            break
        default:
            break
    }

    return ( 
        <div>
            <Grid container spacing={2} alignItems='center' wrap='nowrap'>
                <Grid item>
                    <a style={{cursor:'pointer', textDecoration:'none', color:'inherit'}} href={`/products/${product_id}`} target='_blank'>        
                        <ProductImage src={main_product_image_mobile} type='CHECKOUT'  quantity={quantity}/>
                    </a>
                </Grid>
                <Grid item xs>
                    <a style={{cursor:'pointer', textDecoration:'none', color:'inherit'}} href={`/products/${product_id}`} target='_blank'>
                        <Grid container>
                            <Grid item xs={12}>
                                <DisplayText >{product}</DisplayText>    
                            </Grid>
                            { sale === 1 ? saleView :<Grid item xs={12}>           
                                <DisplayText variant='subtitle1'>{onGetFormatNumber(price)}</DisplayText>
                            </Grid>}
                        </Grid>
                    </a>
                </Grid>
                {order_id === null ? <Grid item>
                    <QuantityButton value={quantity} onChange={onChangeLineItem} />
                </Grid>: null}
                {order_id === null ? <Grid item>
                    <IconButton onClick={() => onDeleteItem(props)}><Icon>delete</Icon></IconButton>
                </Grid>: null}
                <Grid item >
                    <DisplayText align='right'>{singlePrice}</DisplayText> 
                </Grid>
            </Grid>
        </div>
     );
}
 
export default withRouter(ProductCheckoutList);


const DetailsInfo = ({label, data}) => {
    if(!data) return null
    return(
       <Grid container wrap='nowrap' spacing={1} alignItems='baseline'>
           {label ? <Grid item>
                <DisplayText color='textSecondary' variant='body2'>{label}</DisplayText>
           </Grid> : null}
           <Grid item>
           <    DisplayText variant={!label ? 'subtitle2' : 'body2'}
           color={!label ? 'textSecondary' : 'inherit'}>{data}</DisplayText>
           </Grid>
       </Grid> 
    )
}