const { mainServer } = require("../../../variables/config");

//gets
export const request_mostsoldproducts = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/mostsoldproduct/list${params}` )
    return response.data.data
}

export const request_mostsoldproduct = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/mostsoldproduct/${id}` )
    return response.data.data
}

export const request_products = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/list` )
    return response.data.data
}

//posts
export const request_create_mostsoldproduct = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/mostsoldproduct`, data)
    return response.data.data
}


//patchs
export const request_update_mostsoldproduct = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/mostsoldproduct/${id}`, data )
    return response.data.data
}

//deletes
export const request_delete_mostsoldproduct = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/mostsoldproduct/${id}`)
    return response.data.data
}

