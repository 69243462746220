import { Grid } from '@material-ui/core'
import React, {useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import ToolbarTable from '../../../../components/Structure/Navigation/ToolbarTable'
import { onGetCatalogs } from '../../../../shared/utility'
import ImageContainer from '../components/ImageContainer'
import SearchBrands from '../forms/SearchBrands'
import AddBrandModal from '../modals/AddBrandModal'
import { translations as translations_esp } from '../../../../texts/esp/product-translations'
import UploadImageModal from '../modals/UploadImageModal'


const MainCard = ({id, id_main, product, brands, onUpdateBrandsCompleted, onUpdateProduct, onUpdateImagesCompleted, onUpdateSending}) => {

    const content = translations_esp.main_card

    //console.log(product)

    const brandsCatalogs = brands ? onGetCatalogs(brands, 'id_brand', 'name') : []
    const brand = product ? {value: product.brand_id, label: product.brand} : null


    //modals
    const [brand_modal, setBrandModal] = useState(false)
    const [image_modal, setImageModal] = useState(false)

    const [main_image, setMainImage] = useState(id_main)

    const onSetMainImage = (img) =>{
        setMainImage(img)
    }

    //images web - mobile
    const menu = content.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id_view) => {
        setImageView(id_view)
        if(id_view === 1) setType('web')
        if(id_view === 2) setType('mobile')
    }

    let contentView = <ImageContainer image={product ? product.image_web : null} /> 

    switch(image_view){
        case 1:
            contentView = <ImageContainer onClick={()=>setImageModal(true)} image={product ? product.image_web : null} /> 
            break
        case 2:
            contentView = <ImageContainer onClick={()=>setImageModal(true)} image={product ? product.image_mobile : null} /> 
            break
        default:
            break
    }

    const onUpdateCompleted = () =>{
        onUpdateProduct()
        onUpdateImagesCompleted()
    }

    return(
        <SimpleCard>
        <AddBrandModal open={brand_modal} onClose={()=>setBrandModal(false)} onUpdateCompleted={onUpdateBrandsCompleted}
        content={content.modal}/>
        <UploadImageModal open={image_modal} onClose={()=>setImageModal(false)} onUpdateCompleted={onUpdateCompleted}
        type={type} id={id} main={true} onSetMainImage={onSetMainImage} main_image={main_image}/>
            <Grid container spacing={4}>
                <Grid item xs={12}>                 
                    <div>
                        <div style={{marginBottom:28}}>
                        {contentView}
                        </div>
                        <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                    </div>                 
                </Grid>
                <Grid item xs={12}>
                    <SearchBrands brand={brand} catalogs={brandsCatalogs} id={id} onAddBrand={()=>setBrandModal(true)} onUpdateSending={onUpdateSending}
                    content={content.search_bar}/>          
                </Grid>
            </Grid>      
        </SimpleCard>
    )
}

export default MainCard