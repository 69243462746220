import React from 'react'
import { Document, Page, StyleSheet, View, Text, Font, Image } from '@react-pdf/renderer'
import logo from '../../../../assets/ubelens-logo-color.png'
import { palette } from '../../../../variables/config'
import { blueGrey } from '@material-ui/core/colors'
import moment from 'moment-timezone'
import workSansMedium from '../../../../fonts/worksans/WorkSans-Medium.ttf'
import workSansRegular from '../../../../fonts/worksans/WorkSans-Regular.ttf'
import workSansSemiBold from '../../../../fonts/worksans/WorkSans-SemiBold.ttf'
import { onGetFormatNumber, onGetFullname } from '../../../../shared/utility'
import { catalogs } from '../../../../texts/esp/catalogs'
import { nodata } from '../../../../variables/texts'


const InvoiceDocument = props => {

    const {order_id, created_at, shipping_address, billing_address, line_items, payment_type_id, payment, subtotal, shipping_price, total} = props
    const timezone = moment.tz.guess()
    const invoice_date = moment(created_at).tz(timezone).format('DD/MM/YYYY')
    const _shipping_address = shipping_address ? shipping_address : {}
    const _billing_address = billing_address ? billing_address : {}

    let fullname = null
    let fullname_b = null
    let products = []
    let payment_type = null
    if(shipping_address) fullname = onGetFullname(shipping_address.first_name, shipping_address.last_name) 
    if(billing_address) fullname_b = onGetFullname(shipping_address.first_name, shipping_address.last_name) 
    if(line_items) products = line_items
    if(payment_type_id)  payment_type = catalogs.payment_types.find(el => el.value === payment_type_id)

    return(
        <Document>
            <Page style={styles.page}>
                <View style={styles.row}>
                    <View style={styles.logoContainer}><Image src={logo} style={styles.logo}/></View>
                    <View style={styles.horizontalSpace} ></View>
                    <View style={styles.headerRight}>
                        <Text style={styles.headerBold}>Factura</Text>
                        <Text style={styles.header}>{invoice_date}</Text>
                        <Text style={styles.header}>{`#${order_id}`}</Text>
                    </View>
                </View>
                <View style={[styles.row, {marginTop:16}]}>
                    <View style={[styles.horizontalSpace, {padding:8}]}>
                        <Text style={[styles.subtitle1, {marginTop:20}]}>Ubelens</Text>
                        <Text style={[styles.subtitle1, {marginBottom:0}]}>Colombia</Text>
                    </View>
                    <View style={[styles.horizontalSpace, {padding:8}]}>
                        <Text style={[styles.subtitle1, {marginBottom:8}]}>Dirección de envío</Text>
                        {fullname ? <Text style={[styles.body1]}>{fullname}</Text> : null}
                        {_shipping_address.phone ? <Text style={[styles.body1]}>{_shipping_address.phone}</Text> : null}
                        {_shipping_address.address1 ? <Text style={[styles.body1]}>{_shipping_address.address1}</Text> : null}
                        {_shipping_address.address2 ? <Text style={[styles.body1]}>{_shipping_address.address2}</Text> : null}
                        {_shipping_address.province ? <Text style={[styles.body1]}>{_shipping_address.province}</Text> : null}
                        {_shipping_address.city ? <Text style={[styles.body1]}>{_shipping_address.city}</Text> : null}
                        {_shipping_address.country ? <Text style={[styles.body1]}>{_shipping_address.country}</Text> : null}
                    </View>
                    <View style={[styles.horizontalSpace, {padding:8}]}>
                        <Text style={[styles.subtitle1, {marginBottom:8}]}>Dirección de facturación</Text>
                        {fullname_b ? <Text style={[styles.body1]}>{fullname_b}</Text> : null}
                        {_billing_address.phone ? <Text style={[styles.body1]}>{_billing_address.phone}</Text> : null}
                        {_billing_address.address1 ? <Text style={[styles.body1]}>{_billing_address.address1}</Text> : null}
                        {_billing_address.address2 ? <Text style={[styles.body1]}>{_billing_address.address2}</Text> : null}
                        {_billing_address.province ? <Text style={[styles.body1]}>{_billing_address.province}</Text> : null}
                        {_billing_address.city ? <Text style={[styles.body1]}>{_billing_address.city}</Text> : null}
                        {_billing_address.country ? <Text style={[styles.body1]}>{_billing_address.country}</Text> : null}
                    </View>
                </View>
                <View style={[{marginTop:16}]}>
                    <View style={[styles.row, {backgroundColor:blueGrey[100]}]}>
                        <View style={[styles.horizontalSpace, styles.cell_description]}>
                            <Text style={[styles.subtitle2 ]}>Producto</Text>
                        </View>
                        <View style={[styles.cell_price]}>
                            <Text style={[styles.subtitle2]}>Precio Unitario</Text>
                        </View>
                        <View style={[styles.cell_qty]}>
                            <Text style={[styles.subtitle2 ]}>Cant.</Text>
                        </View>
                        <View style={[styles.cell_price]}>
                            <Text style={[styles.subtitle2]}>Total</Text>
                        </View>
                    </View>
                    {products.map(((item,key)=>{
                        return(
                            <ProductRow {...item} key={key.toString()}/>
                        )
                    }))}
                    <View style={styles.row}>
                        <View style={{width:150, paddingTop:70}}>
                            {/* <HorizontalData label='Método de pago:' value={payment_type ? payment_type.label : nodata}/> */}
                            <HorizontalData label='Método de pago:' value={payment ? payment.type_id  === 2 ? 'Efectivo' : 'Tarjeta de crédito/débito' : nodata}/>
                        </View>
                        <View style={[styles.horizontalSpace, {padding:8}]}/>
                        <PaymentDetails total={total} subtotal={subtotal} shipping_price={shipping_price}/>
                    </View>
                </View>
                <Text style={[styles.body2, {position:'absolute', bottom:32, left:'50%', transform:'translateX(-50%)'}]}>Ubelens - Colombia</Text>
            </Page>
        </Document>
    )
}

export default InvoiceDocument

const PaymentDetails = props => {
    const {total, subtotal, shipping_price} = props
    return(
        <View>
            <PaymentItem label='Subtotal' value={onGetFormatNumber(subtotal)}/>
            <PaymentItem label='Envío' value={onGetFormatNumber(shipping_price)}/>
            <PaymentItem label='Total' value={onGetFormatNumber(total)} bold/>

        </View>
    )
}

const PaymentItem = props => {
    const {label, value, bold} = props
    
    return(
        <View style={[styles.row, {borderBottomWidth:1,borderColor:blueGrey[100], width:175}]}>
             <Text style={[bold ? styles.subtitle1 : styles.body2, {color:blueGrey[900], marginRight:4, width:75, backgroundColor:blueGrey[100], textAlign:'right',padding:8} ]}>{label}</Text>
             <Text style={[bold ? styles.subtitle1 : styles.body2, {width:100, textAlign:'right',padding:8}  ]}>{value}</Text>
        </View>
    )

}

const ProductRow = ({title, price, total_price, quantity, details}) => {

    let detailContent = null
    if(details && details !== ''){
        const detailsdata = JSON.parse(details)
        detailContent = <ProductDetail {...detailsdata}/>

    }

    return(
        <View style={[styles.row, {backgroundColor:'white', borderBottomWidth:1,borderColor:blueGrey[100]}]}>
            <View style={[styles.horizontalSpace, styles.cell_description]}>
                <Text style={[styles.subtitle2 ]}>{title}</Text>
                {detailContent}
            </View>
            <View style={[styles.cell_price]}>
                <Text style={[styles.body2]}>{onGetFormatNumber(price)}</Text>
            </View>
            <View style={[styles.cell_qty]}>
                <Text style={[styles.body2 ]}>{quantity}</Text>
            </View>
            <View style={[styles.cell_price]}>
                <Text style={[styles.body2]}>{onGetFormatNumber(total_price)}</Text>
            </View>
        </View>
    )
}

const ProductDetail = (props) => {
    if(!props) return null
    const {type, axis_left, axis_right, cylinder_left, cylinder_right, sphere_left, sphere_right, 
        addition_left, addition_right, dominance_left, dominance_right, color_left, color_right,
        quantity_left, quantity_right, axis, cylinder, sphere, addition, dominance, color, quantity} = props

    /* if(type === 'DIFF'){
        return(
            <View style={[styles.row]}>
                <View style={[styles.horizontalSpace, {paddingRight:8}]}>
                    <ProductDetailWrapper title='Ojo zquierdo' quantity={quantity_left} 
                    axis={axis_left} cylinder={cylinder_left} sphere={sphere_left}
                    addition={addition_left} dominance={dominance_left} color={color_left}/>
                </View>
                <View style={[styles.horizontalSpace, {paddingRight:8}]}>
                    <ProductDetailWrapper title='Ojo derecho' quantity={quantity_right}
                    axis={axis_right} cylinder={cylinder_right} sphere={sphere_right}
                    addition={addition_right} dominance={dominance_right} color={color_right}/>
                </View>
            </View>
        )
    } */

    if(type === 'RIGHT'){
        return(
            <View style={[styles.row]}>
                <View style={[styles.horizontalSpace, {paddingRight:8}]}>
                    <ProductDetailWrapper title='Ojo derecho' quantity={quantity_right}
                    axis={axis_right} cylinder={cylinder_right} sphere={sphere_right}
                    addition={addition_right} dominance={dominance_right} color={color_right}/>
                </View>
            </View>
        )
    }

    if(type === 'LEFT'){
        return(
            <View style={[styles.row]}>
                <View style={[styles.horizontalSpace, {paddingRight:8}]}>
                    <ProductDetailWrapper title='Ojo zquierdo' quantity={quantity_left} 
                    axis={axis_left} cylinder={cylinder_left} sphere={sphere_left}
                    addition={addition_left} dominance={dominance_left} color={color_left}/>
                </View>
            </View>
        )
    }

    return(
        <ProductDetailWrapper title={type ? 'Misma fórmula' : ' '} quantity={quantity} 
        axis={axis} cylinder={cylinder} sphere={sphere}
        addition={addition} dominance={dominance} color={color}/>
    )
    

}

const ProductDetailWrapper = (props) => {
    const {title, sphere, cylinder, axis, addition, dominance, color, quantity} = props

    return(
        <View>
            <Text style={[styles.subtitle2, {padding:0, margin:0, color:blueGrey[700]}]}>{title}</Text>
            {sphere ? <HorizontalData label='Poder(SPH)' value={sphere}/> : null}
            {cylinder ? <HorizontalData label='Cilindro(CYL)' value={cylinder}/> : null}
            {axis ? <HorizontalData label='Eje(AXIS)' value={axis}/> : null}
            {addition ? <HorizontalData label='Adición(ADD)' value={addition}/> : null}
            {dominance ? <HorizontalData label='Dominance(DOM)' value={dominance}/> : null}
            {color ? <HorizontalData label='Color' value={color}/> : null}
            {quantity ? <HorizontalData label='Cantidad' value={quantity}/> : null}
        </View>
    )
}

const HorizontalData = (props) => {
    const {label, value} = props
    return(
        <View style={[styles.row]}>
            <Text style={[styles.body2, {color:blueGrey[900], marginRight:4} ]}>{label}</Text>
            <Text style={[styles.subtitle2 ]}>{value}</Text>
        </View>
    )
}

Font.register(
    { family: 'Work Sans', fonts:[
        {src:workSansRegular},
        {src:workSansMedium, fontWeight:500},
        {src:workSansSemiBold, fontWeight:600}

    ]},
);

// Create styles
const styles = StyleSheet.create({
    page: {
      backgroundColor: 'white',
      padding:32,
      fontFamily:'Work Sans'
    },
    row:{
        //backgroundColor:'grey',
        width:'100%',
        display:'flex',
        flexDirection:'row',
        
    },
    headerBold:{
        fontWeight:500,
        color:blueGrey[900],
        fontSize:14,
        textTransform:'uppercase',
    },
    header:{
        fontWeight:500,
        color:blueGrey[500],
        fontSize:14,
    },
    body1:{
        color:blueGrey[700],
        fontSize:12,
    },
    subtitle1:{
        fontWeight:500,
        color:blueGrey[900],
        fontSize:12,
    },
    body2:{
        color:blueGrey[700],
        fontSize:10,
    },
    subtitle2:{
        fontWeight:500,
        color:blueGrey[900],
        fontSize:10,
    },
    horizontalSpace:{
        flex:1,
    },
    cell_price:{width:100, padding:8, textAlign:'right'},
    cell_qty:{width:50, padding:8},
    cell_description:{padding:8},
    headerRight:{
        //display:'inline'
        alignItems:'flex-end',
        fontWeight:'extrabold'
    },  
    logoContainer:{
        backgroundColor:palette.layout.primary,
        borderRadius:12,
        padding:'4px 12px',

    },  
    logo:{
        width:150
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
});
