import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { ColorPicker } from 'material-ui-color';
import DisplayText from '../../../../../../components/Texts/DisplayText'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../../components/Forms/InputForm'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../../../shared/utility'
import { request_segments, request_create_subsegment } from '../requests'
import { translations as translations_esp } from '../../../../../../texts/esp/subsegments-translations'
import { catalogs } from '../../../../../../texts/esp/catalogs'

const AddSubcategoryModal = ({open, onClose, onUpdateCompleted}) => {

    const content = translations_esp.modal

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [color, setColor] = useState('#ffffff')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
    const initModal = async() =>{
        if (!open) {
            let _form = onInitEmptyForm(formData, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
            setColor('#fff')
        }else{
            try{
                const _segments = await request_segments()
                const catalogs = onGetCatalogs(_segments.segments_data, 'id_segment', 'name')
                let _form = {...form}
                _form.segment_id.options = catalogs
                setForm(_form)
            }catch(error){
                console.log(error)
            }
        }
    }
    initModal()
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        data2send.color = color
        console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            await request_create_subsegment(data2send);     
            onUpdateCompleted()
            setLoading(false)
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)

    }

    const onChangeColor = (data) =>{
        if(data && data.css){
            setColor(data.css.backgroundColor)
        }else{
            setColor(data)
        }    
        //console.log(data)
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add_title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <SelectForm data={form.segment_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='body2' color='textSecondary'>{content.form.color.label}</DisplayText>
                            <ColorPicker value={color} disableAlpha onChange={onChangeColor} disablePlainColor/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.url} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectForm data={form.order} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddSubcategoryModal


const formData = {
    segment_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'segment_id',
            type: 'selected',
            fullWidth: true,
            label: 'Categoría',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 500
        }
    },
    /* color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'color',
            type: 'text',
            fullWidth: true,
            label: 'Color',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: catalogs.order_levels,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },

}