import React, {useEffect, useState} from 'react'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import AddUserModal from './modals/AddUserModal/AddUserModal'
import {request_users} from './requests'
import { translations as translations_esp } from '../../../texts/esp/users-translations'

const Users = props => {

    const content = translations_esp
    const { history } = props

    //general
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])

    //modals
    const [addModal, setAddModal] = useState(false)

    //table
    const [totalUsers, setTotalUsers] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_user'})
    const [search, setSearch] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            //console.log(params)
            const _users = await request_users(params)
            setUsers(_users.users)
            //console.log(_users.users)
            setTotalUsers(_users.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])

  
    const onSelectedItem = (item) => {
        const {id_user} = item
        history.push(`/users/${id_user}`)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }
  
    const onUpdateUsers = async() => {
        const params = onGetParamsFromState(tableFilter, search)
        const _users = await request_users(params)
        setUsers(_users.users)
        setTotalUsers(_users.count)
        setAddModal(false)
    }

    return(
        <Page title={content.titles.users} >
            <AddUserModal content={content.modal} open={addModal} onClose={() => setAddModal(false)} onRequestUsers={onUpdateUsers} history={history}/>
            <SimpleTable
                data={users} 
                search={search}
                tableFilter={tableFilter}
                total={totalUsers}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeSearch={onChangeSearch}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                content={content.tables.users}
                />
        </Page>
    )
}


export default Users