import React, {useState} from 'react'
import { fade, Fade, Grid, Icon, makeStyles } from '@material-ui/core'
import ProductImage from '../../../../../../components/Structure/Images/ProductImage'
import cx from 'classnames'
import DisplayText from '../../../../../../components/Texts/DisplayText'


const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        paddingTop:'100%',
        //backgroundColor:'red',
        position:'relative'
    },
    container:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top:0,
        left:0,
        '&:hover':{
            cursor:'pointer'
        }
    },
    button:{
        background:fade('#000', 0.30),
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        zIndex:2,
        borderRadius:12
    },
    iconContainer:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:3
    },
    icon:{
        color:'white',
        fontSize:48,
    },
}))

const ImageContainer = props => {

    const classes = useStyles()
    const {image, onClick} = props
    const [hover, setHover] = useState(null)

    return(
 
        <div className={classes.root}>
            <div className={cx({
                [classes.container]:true
            })} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Fade in={hover}>
                    <div className={classes.button} onClick={onClick}>
                        <div className={classes.iconContainer}>
                            <Grid container direction='column' alignItems='center' spacing={1}>
                                <Grid item>
                                    <Icon className={classes.icon}>camera_alt</Icon>
                                </Grid>
                                <Grid item>
                                    <DisplayText variant='body1' style={{color:'white', fontWeight:500}}>EDITAR</DisplayText>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Fade>
                <ProductImage src={image} type='AUTO'/>
            </div>
        </div>
    
    )
}

export default ImageContainer