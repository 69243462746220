import React, {useState} from 'react'
import ToolbarTable from '../../components/Structure/Navigation/ToolbarTable'
import Page from '../../components/Structure/Page/Page'
import BrandsView from './views/BrandsView/BrandsView/BrandsView'
import CategoriesView from './views/CategoriesView/CategoriesView/CategoriesView'
import SegmentsView from './views/SegmentsView/SegmentsView/SegmentsView'
import SubcategoriesView from './views/SubcategoriesView/SubcategoriesView/SubcategoriesView'
import TagsView from './views/TagsView/TagsView'
import { translations as translations_esp } from '../../texts/esp/catalogs-translations'
import { Hidden } from '@material-ui/core'
import PageMenuMobile from '../../components/Structure/Navigation/PageMenuMobile'
import SubsegmentsView from './views/SubsegmentsView/SubsegmentsView/SubsegmentsView'

const Catalogs = (props) => {

    const content = translations_esp

    const {history} = props

    const [view, setView] = useState(1)
  
    const menu = content.menu

    const onChangeView = (id) => {
        let urlwithargs = '/catalogs'
        if(id === 1) urlwithargs = '/catalogs/brand'
        if(id === 3) urlwithargs = '/catalogs/category'
        if(id === 4) urlwithargs = '/catalogs/subcategory'
        if(id === 5) urlwithargs = '/catalogs/segment'
        if(id === 5) urlwithargs = '/catalogs/subsegment'
        window.history.replaceState(null, null, urlwithargs);
        setView(id)
    }
        
    let contentView = null

    switch(view){
        case 1:
            contentView = <BrandsView history={history} />
            break
        case 2:
            contentView = <TagsView />
            break
        case 3:
        contentView = <CategoriesView history={history} />
            break
        case 4:
            contentView = <SubcategoriesView history={history} />
            break
        case 5:
            contentView = <SegmentsView history={history} />
            break
        case 6:
            contentView = <SubsegmentsView history={history} />
            break
        default:
            break
    }

    return(
        <Page title={content.title}>
        <Hidden smDown>
            <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
        </Hidden>
        <Hidden mdUp>
            <PageMenuMobile selected={view} options={menu} onChange={onChangeView} />
        </Hidden>
        <div>
            {contentView}
        </div>
        </Page>
    )
}

export default Catalogs