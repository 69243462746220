import { success_msg } from "./general-translations";

export const translations = {
    table: {
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Tag'},
            {id:3, label:'Descripción'},
            {id:4, label:'Color'},
        ],
        nodata:'No existen tags registradas'
    },
    modal: {
        add_title:'Agregar tag',
        edit_title:'Editar tag',
        button:'Guardar',
        add_button:'Agregar',
        success_msg:success_msg,
        menu:[{id:1, label:'WEB'}, {id:2, label:'MOBILE'}],
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            color:{
                label:'Color',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            description:{
                label:'Descripción',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            url:{
                label:'Url',
                helperText:'Este campo es obligatorio'
            },
        }
    },
}

