import { blueGrey, red } from "@material-ui/core/colors";
import axios from 'axios'


/*--------- CONFIG ---------*/

export const mainServer = axios.create({
    baseURL: "https://api.celudoc.com/prod",
    headers: {'Content-Type': 'application/json'},
})
export const config_data = {
    S3_SERVER_URL: 'https://s3.us-east-1.amazonaws.com/celudoc-dev-public/'
}

export const USERNAME_API = 'trionix'
export const PASSWORD_API = 'qnJVws4V6amWvZS6f25DJC7cjmLpQzBz'
export const BASIC_AUTH_HEADER = `Basic dHJpb25peDpxbkpWd3M0VjZhbVd2WlM2ZjI1REpDN2NqbUxwUXpCeg==`


/*-----------THEME------------*/

const primary_color = '#2A368B'
const secondary_color = '#0291ff'

export const palette = {
    layout:{
        primary:primary_color,
        secondary:secondary_color,
    },
    texts:{
        primary:primary_color,
        secondary:secondary_color,
        white:'#FFF',
        bluegrey_light:blueGrey[300],
        bluegrey:blueGrey[500],
        bluegrey_dark:blueGrey[700],
        bluegrey_super_dark:blueGrey[900],
        red:red[700]
    }
}


/*-------- GENERAL -----------*/

export const USER_TYPES = {
    ADMIN:1,
    DOCTOR:2, 
    PATIENT:3,
}

export const ORDER_STATUS_TYPES = {
    PENDING:1,
    CONFIRMED:2,
    PREPARING:3,
    SENDING:4,
    DELIVERED:5,
    COMPLETED:6,
    CANCELLED:7
}

export const PAYMENT_STATUSES = {
    PENDING:1,
    DECLINED:2,
    EXPIRED:3,
    PAID:4,
    REFUNDED:5,
    PARTIALLY_REFUNDED:6,
    CHARGED_BACK:7,
    PREAUTHORIZED:8,
    VOIDED:9
}

export const nodata = '-'