import { updateObject } from '../shared/utility'
import {actionTypes} from './actions'

const initialState = {
    user:null,
    isauth:false,
    cartItems:0,
    recover_password_modal:false,
    sending:false,
    success:null,
    error:null
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.CHANGE_AUTH_STATUS:
            return updateObject(state, {isauth:action.isauth})

        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, {user:action.user})
        case actionTypes.AUTH_RECOVER_PASSWORD_MODAL:
            return updateObject(state, {recover_password_modal:action.recover_password_modal})
        case actionTypes.SYS_UPDATE_SENDING:
            return updateObject(state, {sending:action.sending})
        case actionTypes.SYS_UPDATE_SUCCESS:
            return updateObject(state, {success:action.success})
        case actionTypes.SYS_UPDATE_ERROR:
            return updateObject(state, {error:action.error})
        default:
            return state;

    }
}

export default reducer;