import { Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import ActionModalBar from '../../../../components/Actions/ActionModalBar';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import DisplayText from '../../../../components/Texts/DisplayText';
import logo from '../../../../assets/orders/order-list.png'
import { USER_TYPES } from '../../../../variables/config';
import { request_create_order } from '../requests';


const AddOrderModal = ({user, open, onClose, history}) => {

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    const onSubmit = async() => {
        try {
            setLoading(true)
            let data2send = {}
            if(user){
                switch (user.user_type_id) {
                    case USER_TYPES.OPTICAL:
                        data2send = {optical_id: user._id}
                        break;
                    case USER_TYPES.OPTOMETRIST:
                        data2send = {optical_id: user.optical_id, optometrist_id: user._id}
                        break;
                    default:
                        break;
                }
            }
            const order_id = await request_create_order(data2send)
            history.push(`/orders/${order_id}`)
            console.log('Crear orden')
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Grid container justify='center'>
                    <Grid item xs={12}>
                        <div>
                            <DisplayText align='center' variant='h6' style={{fontSize:24}}>
                            ¿Está seguro de crear 
                            </DisplayText>
                            <DisplayText align='center' variant='h6' style={{fontSize:24}}>
                             una nueva order?
                            </DisplayText>
                        </div>
                    </Grid>
                    <Grid item>
                        <img src={logo} alt='' className={classes.logo} ></img>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar btnLabel='Crear orden' loading={loading} btnColor='primary' error={error}
                        onSubmit={onSubmit} />
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles(theme => ({
    logo:{
        width:160,
        margin:32
    }
}))
 
export default AddOrderModal;