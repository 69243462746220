import { change_image_error_msg } from "./general-translations";

export const translations = {
    title:'Marca',
    success_msg:'Acción exitosa',
    edit_card:{
        menu:[{id:1, label:'WEB'}, {id:2, label:'MOBILE'}],
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            color:{
                label:'Color',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            description:{
                label:'Descripción',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            order:{
                label:'Orden',
                helperText:'Seleccione una opción'
            },
            url:{
                label:'Url',
                helperText:'Este campo es obligatorio'
            }
        },
        button:'Guardar',
    },
    tables: {
        menu:[{id:1, label:'Productos'}, {id:2, label:'Subcategorías'}],
        products:{
            title:'Productos',
            header:[
                {id:1, label:''},
                {id:1, label:'ID'},
                {id:2, label:'Producto'},
                {id:3, label:''},
            ],
            nodata:'No existen productos asociados a esta marca',
            add_modal:{
                title:'Agregar un producto a la marca',
                button:'Agregar',
                form:{
                    product_id:{
                        label:'Productos',
                        helperText:'Debe elegir una opción'
                    }
                }
            }
        },
    },
    
}

