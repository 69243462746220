import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Page/Page';
import SimpleTable from './components/SimpleTable';
import AddRouteModal from './modals/AddRouteModal/AddRouteModal';
import useRoutesView from './useRoutesView';

const RoutesView = ({user, history}) => {

    const {system, view_data, actions,modals} = useRoutesView({user})

    return ( 
        <Page title='Rutas'>
            <div>
                <AddRouteModal open={modals.add_register} onSubmit={actions.onSubmit} actions={actions}
                    onClose={() => actions.onUpdateModalStatus('add_register', false)}/>
                <SimpleTable
                    loading={system.loading || system.sending}
                    data={view_data.routes} 
                    filter={view_data.filter}
                    tableFilter={view_data.pagination}
                    total={view_data.total} 
                    loading={system.loading}
                    onSelectedItem={(item) => history.push(`/routes/${item.id_route}`)}
                    onAddRegister={() => actions.onUpdateModalStatus('add_register', true)}
                    onUpdateTableFilter={actions.onUpdateTableFilter}
                    onChangeFilter={actions.onChangeFilter} />
            </div>
        </Page>
        
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(RoutesView);