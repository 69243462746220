import { change_image_error_msg } from "./general-translations";
import { success_msg } from "./general-translations";

const price_helper = 'La cantidad debe ser mayor a 0'

export const translations = {
    title:'Información de producto',
    main_card:{
        menu:[{id:1, label:'Web'}, {id:2, label:'Mobile'}],
        search_bar:{
            title:'Marca',
            add_register: {value:-1, label:'Agregar marca', type:'add'}
        },
        modal:{
            title:'Agregar Brand',
            button:'Guardar',
            form:{
                name:{
                    label:'Nombre',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                color:{
                    label:'Color',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                description:{
                    label:'Descripción',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
            }
        }
    },
    general_info: {
        title:'Información general',
        button:'Guardar',
        success_msg:success_msg,
        form:{
            name:{
                label:'Nombre',
                helperText:'Seleccione al menos una opción'
            },
            brand_id:{
                label:'Marca',
                helperText:'Seleccione al menos una opción'
            },
            short_description:{
                label:'Descripción corta',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 1024'
            },
            url:{
                label:'Url',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            details:{
                label:'Detalles',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            weight:{
                label:'Peso',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            height:{
                label:'Altura',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            width:{
                label:'Ancho',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            length:{
                label:'Largo',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            sat_unit_key:{
                label:'Clave unitaria',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            sat_product_key:{
                label:'Clave del producto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            sku:{
                label:'SKU',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            barcode:{
                label:'Barcode',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            description:{
                label:'Descripción',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
        },
        formControl:{
            taxable:'Tiene impuestos',
            sale:'Oferta',
            requires_recipe:'Requiere receta',
            retains_recipe:'Retiene receta'
        },
        subtitles:{
            billing:'Facturación'
        }
    },
    price:{
        title:'Precio',
        button:'Guardar',
        success_msg:success_msg,
        form:{
            currency_id:{
                label:'Moneda',
                helperText:'Seleccione al menos una opción'
            },
            price:{
                label:'Precio',
                helperText:price_helper
            },
            sale_discount:{
                label:'Descuento',
                helperText:'El descuento no puede ser menor que el precio del producto'
            },
            sale_price:{
                label:'Precio con descuento',
                helperText:price_helper
            },
            sale_bundle_price:{
                label:'Precio de mayoreo',
                helperText:price_helper
            },        
        },
        form_product:{   
            sale_bundle_quantity:{
                label:'Cantidad de mayoreo',
                helperText:price_helper
            },
            sale_bundle_paid_quantity:{
                label:'Artículos pagados',
                helperText:price_helper
            },
            sale_bundle_free_quantity:{
                label:'Artículos gratis',
                helperText:price_helper
            },
            multiple_sale:{
                label:'Promociones',
                helperText:price_helper
            },
            multiple_sale_quantity:{
                label:'Cantidad de compras',
                helperText:price_helper
            },
            sale_expiration_date:{
                label:'Descuento válido hasta:',
                helperText:price_helper
            },
            product_discount_type_id:{
                label:'Tipo de descuento',
                helperText:price_helper
            },
            sale:{
                label:'Habilitar descuento',
                helperText:price_helper
            },
            taxable:{
                label:'Incluir iva en el precio',
                helperText:price_helper
            },
        },
        formControl:{
            currency_1:'Peso mexicano',
            currency_2:'Dolar americano'
        }
    },
    product_images:{
        title:'Imágenes del producto',
        menu:[{id:1, label:'Web'}, {id:2, label:'Mobile'}],
        add_image_button: 'Agregar imagen',
        main_check_text1:'Imagen',
        main_check_text2:'principal',
    },
    tags:{
        title:'Tags',
        search_bar:{
            add_register: {value:-1, label:'Agregar tag', type:'add'},
            placeholder:'Buscar...'
        },
        modal:{
            title:'Agregar Tag',
            button:'Guardar',
            form:{
                name:{
                    label:'Nombre',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                color:{
                    label:'Color',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                description:{
                    label:'Descripción',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
            }
        }
    },
    categories:{
        title:'Categorías',
        search_bar:{
            add_register: {value:-1, label:'Agregar categoría', type:'add'},
            placeholder:'Buscar...'
        },
        modal:{
            title:'Agregar Categoría',
            button:'Guardar',
            form:{
                name:{
                    label:'Nombre',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                color:{
                    label:'Color',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                description:{
                    label:'Descripción',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
            }
        }
    },
    subcategories:{
        title:'Subcategorías',
        search_bar:{
            add_register: {value:-1, label:'Agregar subcategoría', type:'add'},
            placeholder:'Buscar...'
        },
        modal:{
            title:'Agregar subcategoría',
            button:'Guardar',
            form:{
                name:{
                    label:'Nombre',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                color:{
                    label:'Color',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                description:{
                    label:'Descripción',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
            }
        }
    },
    segments:{
        title:'Segmentos',
        search_bar:{
            add_register: {value:-1, label:'Agregar segmento', type:'add'},
            placeholder:'Buscar...'
        },
        modal:{
            title:'Agregar segmento',
            button:'Guardar',
            form:{
                name:{
                    label:'Nombre',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                color:{
                    label:'Color',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                description:{
                    label:'Descripción',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
            }
        }
    },
    subsegments:{
        title:'Subsegmentos',
        search_bar:{
            add_register: {value:-1, label:'Agregar subsegmento', type:'add'},
            placeholder:'Buscar...'
        },
        modal:{
            title:'Agregar subsegmento',
            button:'Guardar',
            form:{
                name:{
                    label:'Nombre',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                color:{
                    label:'Color',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                description:{
                    label:'Descripción',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
            }
        }
    }

}
