
export const translations = {
    titles:{
        users:'Usuarios',
        doctors:'Doctores',
        patients:'Pacientes'
    },
    tables: {
        users:{
            header:[
                {id:1, label:'ID'},
                {id:2, label:'Nombre'},
                {id:3, label:'Email'},
                {id:4, label:'Tipo de usuario'},
            ],
            nodata:'No existen usuarios registrados'
        },
        doctors:{
            header:[
                {id:1, label:'ID'},
                {id:2, label:'Nombre'},
                {id:3, label:'Email'},
            ],
            nodata:'No existen usuarios registrados'
        },
        patients:{
            header:[
                {id:1, label:'ID'},
                {id:2, label:'Nombre'},
                {id:3, label:'Email'},
            ],
            nodata:'No existen usuarios registrados'
        },
    },
    modal: {
        titles:{
            users:'Agregar usuario',
            doctors:'Agregar doctor'
        },
        subtitle:'Por favor ingresa los datos solicitados',
        button:'Agregar',
        form:{
            first_name: {
                label: 'Nombre(s)',
                helperText: 'Este campo debe de contener al menos un caracter'
            },
            fathers_last_name: {
                label: 'Apellido paterno',
                helperText: 'Este campo debe de contener al menos un caracter'
            },
            mothers_last_name: {
                label: 'Apellido materno',
                helperText: 'Este campo debe de contener al menos un caracter'
            },
            birth_date: {
                label:'Fecha de nacimiento',
                helperText: 'Este campo debe contener una fecha válida'
            },
            user_gender_id: {
                label: 'Género',
                helperText: 'Debe elegir una opción',
            },
            email: {
                label: 'Dirección de correo',
                helperText: 'Dirección de correo no válido',
            },
            password: {
                label: 'Contraseña',
                helperText: 'Debe contener 6 a 20 caracteres',
            },
            passwordConfirmation: {
                label: 'Validar contraseña',
                helperText: 'Las contraseñas no coinciden'
            },
            pin: {
                label: 'PIN',
                helperText: 'El pin debe estar conformado por 4 números',
            },
            zip: {            
                label: 'Código postal',
                helperText: 'Código postal no válido'        
            },    
        },
        formControl:{
            indigenous:'Pertenece a una comunidad indígena'
        },
        title_medical:'Información medica',
        formMedical:{
            language_id: {
                label: 'Idioma',
                helperText: 'Debe elegir una opción'
            },
            qualifications: {
                label: 'Capacitaciones',
                helperText: 'Este campo debe de contener al menos un caracter',
            },
            bio: {            
                label: 'Biografía',
                helperText: 'Este campo debe de contener al menos un caracter'        
            },
        }
    },
}

