import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import { request_patients } from './requests'
import { translations as translations_esp } from '../../../texts/esp/users-translations'

const Patients = props => {

    const content = translations_esp
    const { history, user } = props

    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])
    //const [addModal, setAddModal] = useState(false)
    const [total, setTotal] = useState(10)
   
    //table
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'id_user',
    })
    const [search, setSearch] = useState(null)
    

    useEffect(() => {
        const initModule = async() => {try {
            if(!user) return
            console.log(user)
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            console.log(params)
            const _pacients = await request_patients(params)
            setPatients(_pacients.users)
            setTotal(_pacients.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [user, tableFilter])

    
    const onSelectedItem = (item) => {
        const {id_user} = item
        history.push(`/users/${id_user}`)
    }
  
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    let can_create = false
    //if(user && (user.user_type_id === 1)) can_create = true

    return(
        <Page title={content.titles.patients} >
            <SimpleTable
                data={patients} 
                search={search}
                tableFilter={tableFilter}
                total={total}
                loading={loading}
                //onAddRegister={can_create ? () => setAddModal(true) : null}
                onChangeSearch={onChangeSearch}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                content={content.tables.patients}/>
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(Patients)