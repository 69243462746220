import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Page/Page';
import useRouteView from './useRouteView';

const RoutesView = ({user, history}) => {

    const {system, view_data, actions,modals} = useRouteView({user})

    return ( 
        <Page title='Detalle de ruta' >
            <div>
                Detalles etc
            </div>
        </Page>
        
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(RoutesView);