import React, {useEffect, useState} from 'react'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string'
import EditModal from './modals/EditModal'
import { translations as translations_esp } from '../../../texts/esp/tables-products-translations'
import { CircularProgress, Grid } from '@material-ui/core'
import DeleteModal from './modals/DeleteModal'
import AddModal from './modals/AddModal'
import { request_saleproducts, request_update_saleproduct } from './requests'

const SaleProducts = props => {

    const content = translations_esp

    const { history } = props

    const [sending, setSending] = useState(false)
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [totalItems, setTotalItems] = useState(10)
    const [filter, setFilter] = useState({
        query:'',
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'order',
    })
    const [search, setSearch] = useState(null)

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            onRequestModelData()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])


    const onRequestModelData = async() => {  
        const {limit, offset, order_by, order} = tableFilter
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}` 

        if(search){
            let filterArgs = [
                {"field":"product","operator":"LIKE","value":`%${search}%`},
            ]
            filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
            params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` 
        }

        const _users = await request_saleproducts(params)
        //console.log(_users.products_data)
        setProducts(_users.sale_product_data)
        setTotalItems(_users.count)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onSelectedItem = (item) => {
        const {product_id} = item
        history.push(`/products/${product_id}`)
    }
  
    const onChangeFilter = (tag, value) => { 
        let newFilter = onUpdateFilter(tag, value)
        let newTableFilter = {...tableFilter}
        newTableFilter.offset = 0
        setFilter(newFilter)
        setTableFilter(newTableFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }

    const onEdit = (item) =>{
        //console.log(item)
        const {id_sale_product} = item
        setSelected(id_sale_product)
        setEditModal(true)
    }

    const onDelete = (item) =>{
        //console.log(item)
        const {id_sale_product} = item
        setSelected(id_sale_product)
        setDeleteModal(true)
    }

    const onUpdateCompleted = async() => {
        onRequestModelData()
        setEditModal(false)
    }

    const onChangeStatus = async (item) => {
        const {id_sale_product, enabled} = item
        const status = enabled === 0 ? 1 : 0
        try{
            //setSending(true)
            await request_update_saleproduct(id_sale_product, {enabled: status})
            onUpdateCompleted()
        }catch(error){
            console.log(error)
        }
        //setSending(false)
    }
 
    return(
        <div>
            <AddModal open={add_modal} onClose={() => setAddModal(false)} history={history} onUpdateCompleted={onUpdateCompleted}
            content={content.modal} existing_products={products}/>
            <EditModal open={edit_modal} onClose={() => setEditModal(false)} history={history} onUpdateCompleted={onUpdateCompleted}
            content={content.modal} id={selected} />
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} 
            id={selected}/>
            {(products.length === 0  && totalItems === 10) ? 
            <Grid container justify='center'><Grid item ><CircularProgress color='primary'/></Grid></Grid> : 
            <SimpleTable
                data={products} 
                search={search}
                onChangeSearch={onChangeSearch}
                tableFilter={tableFilter}
                total={totalItems}
                loading={loading}
                sending={sending}
                onAddRegister={() => setAddModal(true)}
                onChangeFilter={onChangeFilter}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                onEdit={onEdit}
                onDelete={onDelete}
                onChangeStatus={onChangeStatus}
                />
            }
        </div>
    )
}

export default SaleProducts
