import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import OrderStatus from '../../../../components/Structure/DisplayData/OrderStatus';
import PaymentStatus from '../../../../components/Structure/DisplayData/PaymentStatus';
import DisplayText from '../../../../components/Texts/DisplayText';
import { nodata } from '../../../../variables/texts';
import moment from 'moment-timezone'


const HeaderContainer = ({ order, sending }) => {
    
    let date = ''
    if(order){
        const timezone = moment.tz.guess()
        date = moment(order.ordered_at).tz(timezone)
        date = date.isValid() ? date.format('DD MMM YYYY, HH:mm:ss') : nodata
    }

    return ( 
        <div>
            <Grid container spacing={1} alignItems='center'>
                <Grid item>
                    <DisplayText variant='h6'>{`#${order ? order.id_order : nodata}`}</DisplayText>
                </Grid>
                <Grid item>
                    <OrderStatus status_id={order ? order.order_status_id : null}/>
                </Grid>
                <Grid item>
                    <PaymentStatus status_id={order ? order.payment_status_id : null}/>
                </Grid>
                <Grid item>
                    {sending ? <CircularProgress size={32}/> : null}
                </Grid>
                <Grid item xs={12}>
                    <DisplayText color='textSecondary'>{date}</DisplayText>
                </Grid>
            </Grid>
        </div>
     );
}

 
export default HeaderContainer;
