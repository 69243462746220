import React from 'react'
import { Checkbox, Grid } from '@material-ui/core';
import DisplayText from '../Texts/DisplayText';


const InputCheckbox = props => {    

    const {onChange, data, disabled} = props
    const {value, isVisited, isValid} = props.data
    const {id, label,placeholder, helper} = props.data.config


    const handleDateChange = (e, value) => {
        let temp = {...data};
        temp.value = value === true ? 1 : 0 ;
        temp.isValid = true
        temp.isVisited = true;
        onChange(temp);
    }


    const error = isVisited && !isValid;

    return(
        <div>
          <Grid container wrap='nowrap' alignItems='center'>
            <Grid item>
              <Checkbox id={id} checked={value} onChange={handleDateChange}/>
            </Grid>
            <Grid item xs>
              <DisplayText variant='body2'  color={error ? 'error' : 'inherit'}>{label}</DisplayText>
            </Grid>
            
          </Grid>
            
        </div>
    )
}

export default InputCheckbox