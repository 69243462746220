import { Grid, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import DisplayText from '../../../../components/Texts/DisplayText'
import { translations as translations_esp } from '../../../../texts/esp/product-translations'


const useStyles = makeStyles(theme => ({
    root:{
        width:100,
        height:100,
        border:'1px dashed blue',
        borderRadius:12,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer'
        //padding:'8px 16px',
        //boxSizing:'border-box'
    },
    
}))


const AddImage = ({onClick}) =>{

    const content = translations_esp.product_images
    const classes  = useStyles()

    return(
        <div className={classes.root} onClick={onClick}>
            <Grid container justify='center'>
                <Grid item>
                    <div style={{textAlign:'center'}}>
                        <DisplayText variant='body2' color='textSecondary'>{content.add_image_button}</DisplayText>
                    </div>
                </Grid>
                <Grid item>
                    <Icon color='primary'>add_photo_alternate</Icon>
                </Grid>
            </Grid>
        </div>
    )
}

export default AddImage