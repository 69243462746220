import React, {useState, useEffect} from 'react'
import AddSubcategoryModal from './modals/AddSubcategoryModal'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import { request_subcategories } from './requests'
import DeleteModal from './modals/DeleteModal'

const SubcategoriesView = (props) => {

    const {history} = props

    //general
    const [loading, setLoading] = useState(false)
    const [subcategories, setSubcategories] = useState([])

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [delete_modal, setDeleteModal] = useState(false)
 
    //table
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'category_id'})
    const [search, setSearch] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            window.scrollTo({top: 0, behavior: 'smooth'})
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            //console.log(params)
            const _subcategories = await request_subcategories(params)
            console.log(_subcategories)
            setSubcategories(_subcategories.subcategorys_data)
            setTotal(_subcategories.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])

    
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(tableFilter, search)
        const _subcategories = await request_subcategories('')
        setSubcategories(_subcategories.subcategorys_data)
        setTotal(_subcategories.count)
        setAddModal(false)
    }


    const onSelectedItem = (item, type) => {
        const {id_sub_category} = item
        setSelected(id_sub_category)
        if(type === 'edit') history.push(`/catalogs/subcategory/${id_sub_category}`)
        if(type === 'delete') setDeleteModal(true)
    }


    return(
        <div>
            <AddSubcategoryModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted} />
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} 
            id={selected}/>
            <SimpleTable
                data={subcategories} 
                tableFilter={tableFilter}
                search={search}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeSearch={onChangeSearch}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
            />
        </div>
    )
}

export default SubcategoriesView