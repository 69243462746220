import queryString from 'query-string'

export const onGetParamsFromState = (tableFilter, search) => {
    
    const {limit, offset, order_by, order} = tableFilter
    const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
    let params = `?${queryString.stringify(params2send)}`

    if(search){
        let filterArgs = [
            {"field":"name","operator":"LIKE","value":`%${search}%`},
        ]
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
    }

    return params
}
