import React, { useState, useEffect } from 'react'
import { CircularProgress, Fade, Grid, Typography } from '@material-ui/core'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onSetErrorsToForm, onUpdateAuthorizationHeader } from '../../../../../shared/utility'
import { useStyles } from './styles'
import InputForm from '../../../../../components/Forms/InputForm'
import RoundedButton from '../../../../../components/Actions/RoundedButton'
import InputSelectForm from '../../../../../components/Forms/InputSelectForm'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import { mainServer } from '../../../../../variables/config'


const AddRouteModal = props => {

    const { open, onClose, view_data, actions, onRequestUsers, history } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {

        const initModule = async() => {
            setLoading(true)
            try {
                onUpdateAuthorizationHeader()
                const request_warehouses = await mainServer.get(`/warehouse/list`)
                const _warehouses = onGetCatalogs(request_warehouses.data.data.warehouses_data, 'id_warehouse', 'name')
                let _form = JSON.parse(JSON.stringify(formData))
                _form.warehouse_id.options = _warehouses
                setForm(_form)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }

        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false); setSending(false);
        }else{
            //If open
            initModule()
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        setSending(true)
        try {
            onUpdateAuthorizationHeader()
            await mainServer.post(`/route`, data2send)
            await actions.onRefreshData()
            await actions.onUpdateModalStatus('add_register', false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            
        }
        setSending(false)

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                {loading ? <div style={{marginTop:40}}>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <CircularProgress size={40}/>
                        </Grid>
                    </Grid>
                </div> : null}
                {!loading ? <Fade in>
                    <div >
                        <Typography variant='h6'  style={{ fontWeight: 600 }}>Crear ruta</Typography>
                        <div className={classes.form}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant='body2'>Selecciona una sucursal</Typography>
                                    <InputSelectForm data={form.warehouse_id} onChange={onChange} />
                                </Grid>
                            </Grid>
                        </div>
                        <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={sending}>
                            Agregar
                        </RoundedButton>
                        <Typography color='error' align='center'>{error}</Typography>
                    </div>
                </Fade> : null}
            </div>
            
        </SimpleModal>
    )
}

export default AddRouteModal


const formData = {
    warehouse_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'warehouse_id',
            type: 'select',
            fullWidth: true,
            label: '',
            helperText: 'Debe seleccionar una opción'
        },
        rules: {
            type: 'select',
        }
    },
}