import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { ColorPicker } from 'material-ui-color';
import { connect } from 'react-redux'
import * as actionTypes from '../../../../../store/actions'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard'
import Page from '../../../../../components/Structure/Page/Page'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../../../shared/utility'
import { request_products_segments, request_segment, request_update_segment } from './requests'
import ImageContainer from './components/ImageContainer'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import ChangeImageModal from './modals/ChangeImageModal'
import ToolbarTable from '../../../../../components/Structure/Navigation/ToolbarTable'
import AddProductSegmentModal from './modals/AddProductSegmentModal'
import DeleteProductSegmentModal from './modals/DeleteProductSegmentModal'
import { translations as translations_esp } from '../../../../../texts/esp/segment-translations'
import { catalogs } from '../../../../../texts/esp/catalogs'
import SelectForm from '../../../../../components/Forms/SelectForm'

const SegmentView = (props) => {

    const content = translations_esp

    const {match, history} = props
    //global props
    const { onUpdateSending , onUpdateError, onUpdateSuccess } = props

    const id = match.params.id

    //general
    const [loading, setLoading] = useState(false)
    const [success, setSucess] = useState(null)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')
    const [subcategoryID, setSubcategoryID] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [color, setColor] = useState(null)
    const [segment, setSegment] = useState(null)
    const [productsSegment, setProductsSegment] = useState([])

    //table
    const [table_loading, setTableLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:0, order_by:'id_product'})
    const [search, setSearch] = useState(null)

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [image_modal, setImageModal] = useState(false)
    const [id_selected, setIdSelected] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _segment = await request_segment(id)
            setSegment(_segment.segment_data)
            setColor(_segment.segment_data.color)
            setSubcategoryID(_segment.segment_data.sub_category_id)
            let _form = onInitEmptyForm(form, content.edit_card.form)
            setForm(_form)
            _form = onInitForm(form, _segment.segment_data)
            setForm(_form)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    useEffect(() => {
        const initModule = async() => {try {
            setTableLoading(true)
            const products_params = onGetParamsFromState(tableFilter, search, id)
            const _products = await request_products_segments(products_params)
            setProductsSegment(_products.products_data)
            setTotal(_products.count)
            setTableLoading(false)
        } catch (error) {
            setTableLoading(false)
            console.log(error)
        }}
        initModule()
    }, [tableFilter, search])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onSelectedItem = (item) => {
        const {product_id} = item
        history.push(`/products/${product_id}`)
    }

    const onDeleteItem = (item) => {
        const {id_product} = item
        setIdSelected(id_product)
        setDeleteModal(true)
    }

    const onUpdateCompleted = async() => {
        const products_params = onGetParamsFromState(tableFilter, search, id)
        const _products = await request_products_segments(products_params)
        setProductsSegment(_products.products_data)
        setAddModal(false)
        setDeleteModal(false)
    }

    const onUpdateImageCompleted = async() => {
        const _segment = await request_segment(id)
        setSegment(_segment.segment_data)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, segment)
        data2send.color = color
        console.log(data2send)
        
        setSending(true)
        onUpdateSending(true)
        try {
            await request_update_segment(id, data2send);
            setSending(false)
            onUpdateSuccess(content.success_msg)
            //setSucess('Acción exitosa')
        } catch (error) {
            console.log(error)
            //setError('Hubo un error')
            onUpdateError(onGetErrorMessage(error))
            setSending(false)
        }
        onUpdateSending(false)
    }

    const onChangeColor = (data) =>{
        if(data && data.css){
            setColor(data.css.backgroundColor)
        }else{
            setColor(data)
        }    
        //console.log(data)
    }

    //image web - mobile
    const menu = content.edit_card.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id) => {
        if(id === 1) setType('web')
        if(id === 2) setType('mobile')
        setImageView(id)
    }

    let contentView = null

    switch(image_view){
        case 1:
            contentView = <ImageContainer image={segment ? segment.image_web : null} onClick={()=>setImageModal(true)}/>
            break
        case 2:
            contentView = <ImageContainer image={segment ? segment.image_mobile : null} onClick={()=>setImageModal(true)}/> 
            break
        default:
            break
    }

    return(
        <Page title={content.title} loading={loading} goback success={success} onCloseSuccess={() => setSucess(null)}>
            <AddProductSegmentModal open={add_modal} onClose={()=>setAddModal(false)} id={id} 
            onUpdateCompleted={onUpdateCompleted} id_subcategory={subcategoryID}  />
            <DeleteProductSegmentModal open={delete_modal} onClose={()=>setDeleteModal(false)} id={id_selected} 
            onUpdateCompleted={onUpdateCompleted} />
            <ChangeImageModal id={id} open={image_modal} onClose={() => setImageModal(false)} type={type} 
            onUpdateCompleted ={onUpdateImageCompleted}/>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                    <SimpleCard>
                        <Grid container spacing={4} justify='flex-end'>
                            <Grid item xs={12}>
                                {contentView}
                                <div style={{marginTop:12}}>
                                <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                                </div>
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.name} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.url} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DisplayText variant='body2' color='textSecondary'>{content.edit_card.form.color.label}</DisplayText>
                                <ColorPicker value={color} disableAlpha onChange={onChangeColor} disablePlainColor/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectForm data={form.order} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.description} onChange={onChange} />
                            </Grid>
                            <Grid item>
                                <ContainedButton color='primary' loading={sending} onClick={onSubmit}>Guardar</ContainedButton>
                            </Grid>  
                        </Grid>
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={4} justify='flex-end'>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle1'>{content.tables.products.title}</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SimpleTable
                                            data={productsSegment} 
                                            tableFilter={tableFilter}
                                            search={search}
                                            total={total}
                                            loading={table_loading}
                                            onAddRegister={() => setAddModal(true)}
                                            onChangeSearch={onChangeSearch}
                                            onUpdateTableFilter={(data) => setTableFilter(data)}
                                            onSelectedItem={onSelectedItem}
                                            onDeleteItem={onDeleteItem}
                                        />
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    )

}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(SegmentView) 

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255',
            multiline:true,
            rows:3
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 500
        }
    },
    /* color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'color',
            type: 'text',
            fullWidth: true,
            label: 'Color',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: catalogs.order_levels,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
}