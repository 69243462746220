import React, {useState} from 'react'
import { Card, CircularProgress, Grid, makeStyles, Button } from '@material-ui/core'
import {formData} from './data'
import InputForm from '../../components/Forms/InputForm'
import { isFormValid } from '../../components/Forms/customFunctions'
import { request_login } from './requests'
import DisplayText from '../../components/Texts/DisplayText'
import { isTokenValid } from '../../shared/utility'
import { Redirect } from 'react-router-dom'
import logo from '../../assets/logo-text.svg'
import {connect} from 'react-redux'
import * as actionTypes from '../../store/actions'
import { withRouter } from 'react-router-dom'
import ForgottenPasswordModal from '../../modals/ForgottenPasswordModal/ForgottenPasswordModal'
import SVGIcon from '../../components/Structure/DisplayData/SVGIcon'


const useStyles = makeStyles(theme =>({
    root:{
        position:'relative',
        background:theme.palette.primary.main,
        width:'100%',
        minHeight:'100vh'
    },
    subroot:{
        width:500,
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:2
    },
    logo:{
        width:180,
        //background:theme.palette.primary.main,
        color:theme.palette.primary.main,
        padding:'4px 16px',
        borderRadius:90
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(3)
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        padding:theme.spacing(4),
    },
    form:{
        paddingTop:theme.spacing(6),
        paddingBottom:theme.spacing(4),
    },
    linkContainer:{
        marginTop:16,
        marginBottom:16
    },
    link:{
        color:theme.palette.secondary.main,
        fontFamily:theme.typography.fontFamily,
        fontWeight:500,
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.dark,},
        marginTop:24
    },
}))

const SignInView = ({history, onUpdateRecoverPasswordModal}) => {

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [forgottenPassModal , setForgottenPassModal] = useState(false)


    const onChange = (data) => {
        let temp = {...form}
        const id = data.config.id
        temp[id] = {...data}
        setForm(temp)
        setIsValid(isFormValid(temp))
    }

    const onSubmit = async() => {
        if(!isValid){setError('Llene los cambios solicitados')
        return
        }
        let data2send = {}
        Object.keys(form).forEach(item => {data2send = {...data2send, [item]:form[item].value}})
        data2send.device = "web"
        setLoading(true)
        try {   
            const response = await request_login(data2send)
            console.log(response)
            if(response.user_type_id !== 2){
                // User is allowed to enter
                
                await localStorage.setItem('token_data', JSON.stringify(response))
                history.push('/banners')
            }else{
                setError('Usuario sin permisos')
            }
        } catch (error) {
            if(error.response && error.response.data){
                setError(error.response.data.error.message)
            }else{
                setError('Hubo un error interno')
            }
        }
        setLoading(false)
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){      
            onSubmit()
        }
    }

    const isAuth = isTokenValid()
    const redirectContent = isAuth ? <Redirect to='/banners'/> : null

    return(
        <div className={classes.root}>
            <ForgottenPasswordModal open={forgottenPassModal} onClose={()=>{setForgottenPassModal(false)}}/>
            {redirectContent}
            <div className={classes.subroot}>
                <Card className={classes.paper}>
                    <div className={classes.container}>
                        <div>
                            <Grid container direction='column' alignItems='center' spacing={2}>
                                <Grid item>
                                    <div style={{marginBottom:32}}>
                                    <SVGIcon src={logo} width={200} color='#543081' />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <DisplayText variant='h6' color='textSecondary'>Acceso</DisplayText>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.form}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <InputForm data={form.email} onChange={onChange} onKeyPress={handleKeyPress} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.password} onChange={onChange} onKeyPress={handleKeyPress}/>
                                </Grid>
                            </Grid>
                        </div>
                        
                        <Button variant='contained' color='primary' size='large' fullWidth onClick={onSubmit}>
                            Acceder
                            {loading ? (<CircularProgress style={{width:24, height:24,marginLeft:8,color:'white'}}/>) : null}
                        </Button>
                        <div className={classes.link} onClick={(e) => setForgottenPassModal(true)}> Olvidé mi contraseña</div>
                        {/*<div className={classes.linkContainer}>  
                            <Grid container alignItems='center' spacing={1} wrap='nowrap' justify='center'>
                                <Grid item>
                                    <DisplayText color='bluegrey_dark'>¿Aún no tienes una cuenta?</DisplayText>
                                </Grid>
                                <Grid item>
                                    <div className={classes.link}> Regístrate</div>
                                </Grid>
                            </Grid>
                            {/*<div className={classes.link} onClick={() => onOpenModal('forgottenpassword')}> Olvidé mi contraseña</div>}
                        </div>*/}
                        <DisplayText color='error' align='center'>{error}</DisplayText>
                    </div>
                </Card>
            </div>
            
        </div>
    )
}

const mapStateToProps = state => {
    return {
        recover_password_modal:state.recover_password_modal,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateRecoverPasswordModal: (recover_password_modal) => dispatch({type: actionTypes.AUTH_RECOVER_PASSWORD_MODAL, recover_password_modal }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInView))


