import { Grid } from '@material-ui/core'
import React from 'react'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'

const GeneralInfo = ({content, form, onChange, sending, onSubmit}) => {

    return(
        <SimpleCard>
            <Grid container spacing={4} justify='flex-end'>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>{content.general_info}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} >
                            <InputForm data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.fathers_last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.mothers_last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.curp} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.birth_date} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.user_gender_id} onChange={onChange} />
                        </Grid>                           
                    </Grid>
                </Grid>
                <Grid item>
                    <ContainedButton color='primary' loading={sending} onClick={onSubmit}>{content.button}</ContainedButton>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default GeneralInfo