import queryString from 'query-string'

export const onGetParamsFromState = (tableFilter, search) => {
    
    const {limit, offset, order_by, order} = tableFilter
    const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
    let params = `?${queryString.stringify(params2send)}`
    let filterArgs = [
        {"field":"user_type_id","operator":"=","value":"2"},
    ]
    filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
    params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`

    if(search){
        let filterArgs = [
            {"field":"user_type_id","operator":"=","value":"2"},
            {"field":"first_name","operator":"LIKE","value":`%${search}%`},
        ]
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
    }

    return params
}

export const onUpdateURLArgs = (filter, tableFilter) => {
    let params = {...tableFilter}
    params.offset = params.offset*params.limit
    params.query = filter.query    
    const urlwithargs = `?${queryString.stringify(params, {sort: false})}`
    window.history.replaceState(null, null, urlwithargs);
}

export const onGetFiltersFromURL = (search, filter, tableFilter) => {
    const args = queryString.parse(search)
    const {limit, offset, order, order_by, query} = args
    let newFilter = {...filter}
    let newTableFilter = {...tableFilter}

    if(limit !== undefined) newTableFilter.limit = parseInt(limit)
    if(offset !== undefined) newTableFilter.offset = parseInt(offset)/parseInt(limit)
    if(order !== undefined) newTableFilter.order = parseInt(order)
    if(order_by !== undefined) newTableFilter.order_by = order_by

    if(query !== undefined) newFilter.query = query

    return{
        filter: newFilter,
        tableFilter: newTableFilter
    }

  }