import React, { useState } from 'react';
import PictureModal from '../../../../components/Overlays/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../shared/utility';
import { request_delete_image_file, request_upload_image } from '../requests';
import { translations as translations_esp } from '../../../../texts/esp/modals-translations';
import { Checkbox, Typography } from '@material-ui/core';

const ChangeImageModal = (props) => {

    const {id, id_image, type, open, onClose, onUpdateCompleted} = props
    const content = translations_esp.change_image_modal
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [both, setBoth] = useState(true)
    //console.log(id)
    //console.log(id_image)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        setDisabled(true)
        try {
            setLoading(true)

            const _file = await onGetImageURL2Blob(file)
            console.log(_file)
            //console.log(_file)

            //this is to create a new register
            //const _image = await request_create_image(id, _file.name)
            //console.log(_image)
            let data
            if(!both){
                if(type === 'web'){data = {document:"image_web", type:onGetFileExtension(_file.name)}}
                else{data = {document:"image_mobile", type:onGetFileExtension(_file.name)}}
                await request_upload_image(id, id_image, _file, data)
                
            }else{
                const data2web = {document:"image_web", type:onGetFileExtension(_file.name)}
                await request_upload_image(id, id_image, _file, data2web)
                const data2mobile = {document:"image_mobile", type:onGetFileExtension(_file.name)}
                await request_upload_image(id, id_image, _file, data2mobile)
            }
            
            
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
    }

    const onDelete = async() => {
        setDisabled(true)
        try {
            setError(null)
            setLoading(true)
            let data
            if(type === 'web'){data = '?document=image_web'}
            else{data = '?document=image_mobile'}
            console.log(data)
            await request_delete_image_file(id, id_image, data)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
    }

    let message = type === 'web' ? 'Aplicar también en versión mobile' : 'Aplicar también en versión web'

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} disabled={disabled}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} onDeleteFile={onDelete}>
                <div style={{display:'flex', alignItems:'center'}}>
                    <Checkbox checked={both}  onChange={() => setBoth(!both)}  /> <Typography variant='subtitle2'>{message}</Typography>
                </div>
            </PictureModal>
     );
}
 
export default ChangeImageModal;