
export const sidebar_settings = {
    list:[
        {
            icon:'manage_accounts',
            name:'Usuarios',
            url:'/users',
            permissions:[1]
        },
        {
            icon:'shopping_bag',
            name:'Pedidos',
            url:'/orders',
            permissions:[1]
        },
        {
            icon:'map',
            name:'Rutas',
            url:'/routes',
            permissions:[1]
        },
        {
            icon:'health_and_safety',
            name:'Doctores',
            url:'/doctors',
            permissions:[1]
        },
        {
            icon:'assignment_ind',
            name:'Pacientes',
            url:'/patients',
            permissions:[1]
        },
        {
            icon:'source',
            name:'Catálogos',
            url:'/catalogs',
            permissions:[1]
        },
        {
            icon:'local_offer',
            name:'Productos',
            url:'/products',
            permissions:[1]
        },
        {
            icon:'dashboard',
            name:'Banners',
            url:'/banners',
            permissions:[1]
        },
        {
            icon:'business',
            name:'Sucursales',
            url:'/warehouses',
            permissions:[1]
        },
        {
            icon:'shopping_cart',
            name:'Carritos',
            url:'/carts',
            permissions:[1]
        },
        
    ]
}