import React from 'react'
import { TextField, FormControl, InputLabel, Select, MenuItem, makeStyles, FormHelperText, FormControlLabel, Checkbox } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const useStyles = makeStyles(theme => ({
    margin:{},
    paper:{
        maxHeight:300
    }
}))

const CheckForm = props => {

    const {data, onChange} = props
    const classes = useStyles()

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        console.log(temp.value)
        onChange(temp);
    } 

    const {config, value } = data
    const {label} = config

    return(
        <div>
            <FormControlLabel
                control={<Checkbox checked={value === 1 ? true : false} onChange={onInnerChange} />}
                label={label}
            />
        </div>
    )
}

export default CheckForm