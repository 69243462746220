const { mainServer, BASIC_AUTH_HEADER } = require("../../../variables/config");

export const request_products = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/product/filter/currency/2${params}` )
    return response.data.data
}


export const request_create_product = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/product`, data)
    return response.data.data
}