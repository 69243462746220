import React, {useState} from 'react';
import {  Button, Card, ClickAwayListener, Grid,  Grow,  Icon,  makeStyles, Popper } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root:{
        //backgroundColor:'green',
        //width:'70%'
        zIndex:3,
        marginBottom:12
    },
    selected:{
        color:'white',
        borderRadius:8,
        background:theme.palette.primary.main,
        fontWeight:500,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover':{background:theme.palette.primary.main},
        padding:'8px 16px',
        //paddingTop:8
        //border:`1px solid ${theme.palette.primary.main}`
    }
    
}))

const PageMenuMobile = (props) => {

    const {selected, onChange, options} = props

    const [anchorEl, setAnchorEl] = useState(null)
    
    const classes = useStyles()

    const onSelectItem = (id) =>{
        setAnchorEl(null)
        onChange(id)
    }

    const buttonContent = (<Card>
        <Grid container direction='column'>
        {options.map(item => {
            return (<Grid item xs>
                <CustomButton 
                number={item.number} 
                selected={item.id === selected} 
                //onClick={() => onChange(item.id)}
                onClick={() => onSelectItem(item.id)}
                >
                    {item.label}
                </CustomButton>
                </Grid>)
        })}
        </Grid>
    </Card>)

    const onGetName = (op , sel) => {
        let name= null
        op.forEach(element => {
            if(sel === element.id)
            name = element.label
        });
        return name
    }

    return ( 
        <div className={classes.root}>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-start' disablePortal style={{zIndex:10}} >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                    {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <div onClick={(e) => setAnchorEl(e.currentTarget)} >
                {/* <Grid container>
                    <Grid item>
                        <Button className={classes.selected}>{onGetName(options, selected)}</Button>
                    </Grid>
                    <Grid item>
                        <Icon style={{color:blueGrey[700]}}>expand_more</Icon>
                    </Grid>
                </Grid> */}
                <Button className={classes.selected}>    
                    {onGetName(options, selected)}
                    <Icon >expand_more</Icon>                    
                </Button>
            </div>
        </div>
     );
}
 
export default PageMenuMobile;

const useButtonStyles = makeStyles(theme => ({
    root:{position:'relative', zIndex:2},
    button:{
        background:blueGrey[100],
        color:blueGrey[700],
        borderRadius:8,
        padding:'8px 16px',
        '&:hover':{background:blueGrey[200]},
        width:'100%',
        marginTop:4
    },
    selected:{
        color:'white',
        background:theme.palette.primary.main,
        fontWeight:500,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover':{background:theme.palette.primary.main}
        //border:`1px solid ${theme.palette.primary.main}`
    }
}))

const CustomButton = props => {
    const classes = useButtonStyles()
    const {children, selected, onClick} = props



    return(
        
                <Button onClick={onClick} className={cx({
                    [classes.button]:true,
                    [classes.selected]:selected
                })}>{children}</Button> 
           
       
    )
}