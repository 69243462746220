import React from 'react';
import { nodata } from '../../../variables/config';
import { makeStyles } from '@material-ui/styles';

const PillStatus = ({value, bold, variant, catalogs, text_styles}) => {

    const classes = useStyles()
    const status = catalogs.find(el=>el.value === value)

    return ( 
        <div className={classes.root} style={{background: status ? status.color : undefined}}>
            {status ? status.label : nodata}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:'8px 16px',
        borderRadius:40,
        fontFamily:theme.fontFamily,
        fontWeight:500,
        color:'white',
        display:'inline-block'
    }
}))

export default PillStatus