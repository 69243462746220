import React, { useState } from 'react';
import PictureModal from '../../../../components/Overlays/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../shared/utility';
import { request_create_image, request_delete_image_file, request_upload_image } from '../requests';
import { translations as translations_esp } from '../../../../texts/esp/modals-translations';
import { Checkbox, Typography } from '@material-ui/core';

const UploadImageModal = (props) => {

    const {id, id_image, type, open, onClose, onUpdateCompleted, main, main_image, onSetMainImage} = props
    const content = translations_esp.upload_image_modal
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [both, setBoth] = useState(true)

    //console.log(id)
    //console.log(id_image)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        setDisabled(true)
        try {
            setLoading(true)

            const _file = await onGetImageURL2Blob(file)
            //console.log(_file.lastModified.toString())
            //console.log(file)

            //this is to create a new register
            //const _image = await request_create_image(id, _file.name)
            //console.log(_image)
            if(main_image){
                console.log('mainimage')
                let data
                if(!both){
                    if(type === 'web'){data = {document:"image_web", type:onGetFileExtension(_file.name)}}
                    else{data = {document:"image_mobile", type:onGetFileExtension(_file.name)}}
                    //console.log(data)
                    //await request_upload_image(id, id_image, _file, data)
                    await request_upload_image (id, main_image, _file, data)
                    
                }else{
                    const data2web = {document:"image_web", type:onGetFileExtension(_file.name)}
                    await request_upload_image (id, main_image, _file, data2web)
                    const data2mobile = {document:"image_mobile", type:onGetFileExtension(_file.name)}
                    await request_upload_image (id, main_image, _file, data2mobile)
                }
                onUpdateCompleted()
                onClose()
                
            }else{
                console.log('notmainimage')
                //lo que acabo de modificar 
                const _image = await request_create_image(id, _file.lastModified.toString(), main)
                console.log(_image)
                if(main) onSetMainImage(_image.product_image_id)
                let data
                if(!both){
                    if(type === 'web'){data = {document:"image_web", type:onGetFileExtension(_file.name)}}
                    else{data = {document:"image_mobile", type:onGetFileExtension(_file.name)}}
                    await request_upload_image (id, _image.product_image_id, _file, data)
                }else{
                    const data2web = {document:"image_web", type:onGetFileExtension(_file.name)}
                    await request_upload_image (id, _image.product_image_id, _file, data2web)
                    const data2mobile = {document:"image_mobile", type:onGetFileExtension(_file.name)}
                    await request_upload_image (id, _image.product_image_id, _file, data2mobile)
                }
                
                
                onUpdateCompleted()
                onClose()
            }
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
    }

    let message = type === 'web' ? 'Aplicar también en versión mobile' : 'Aplicar también en versión web'

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} disabled={disabled}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} >
                <div style={{display:'flex', alignItems:'center'}}>
                    <Checkbox checked={both}  onChange={() => setBoth(!both)}  /> <Typography variant='subtitle2'>{message}</Typography>
                </div>

            </PictureModal>
     );
}
 
export default UploadImageModal;