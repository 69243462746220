import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import React from 'react'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'

const AdditionalInfo = ({content, form, onChange, sending, onSubmit, check, onChangeCheck}) => {

    let ethnicInfo = null

    if(check === true){
        ethnicInfo = <Grid item xs={12}>
                        <Grid container spacing={4} >
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.ethnic_group} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputForm data={form.ethnic_language} onChange={onChange} />
                            </Grid>
                        </Grid>
                    </Grid>     
    }

    return(
        <SimpleCard>
            <Grid container spacing={4} justify='flex-end'>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>{content.add_info}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.blood_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.civil_status_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.occupation} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.scolarity_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.religion} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.currency_id} onChange={onChange} />
                        </Grid>   
                        <Grid item xs={12} >
                            <FormControlLabel
                                control={<Checkbox checked={check} onChange={onChangeCheck} />}
                                label="Pertenece a una comunidad indígena"
                            />
                        </Grid>
                        {ethnicInfo}                               
                    </Grid>
                </Grid>
                <Grid item>
                    <ContainedButton color='primary' loading={sending} onClick={onSubmit}>{content.button}</ContainedButton>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default AdditionalInfo