import React from 'react'
import { TableCell } from "@material-ui/core"


const TableCellLink = (props) =>{

    return(
        <TableCell className={props.className}>
            <a href={props.href} target='_blank' style={{textDecoration:'none', color:'black'}}>
                {props.children}
            </a>
        </TableCell>
    )
}

export default TableCellLink