import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import moment from 'moment'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayIconData from '../../../../components/Structure/DisplayData/DisplayIconData'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFullAddress } from '../../../../shared/utility'
import { catalogs } from '../../../../texts/esp/catalogs'
import { request_shipping_address, request_warehouse } from '../requests'

const DeliveryCard = ({order, content}) => {


    const order_type = order ? order.order_type_id : null
    //const order_type = 2
    const warehouse_id = order ? order.warehouse_id : null
    const shipping_address_id = order ? order.shipping_address_id : null
    const user_id = order ? order.user_id : null
    const scheduled = order ? order.scheduled : null
    const schedule_time = order ? order.schedule_time : null

    const [warehouse, setWarehouse] = useState(null)
    const [address, setAddress] = useState(null)

    useEffect(() => {
        const initModule = async() => {
            if(warehouse_id){
                try{
                    const _warehouse = await request_warehouse(warehouse_id)
                    setWarehouse(_warehouse.warehouse_data)
                }catch(error){
                    console.log(error)
                }     
            }
            if(shipping_address_id){
                try{
                    const _address = await request_shipping_address(user_id, shipping_address_id)
                    setAddress(_address.user_address)
                }catch(error){
                    console.log(error)
                }  
            }
        }
        initModule()
    }, [])

    const address_info = {
        street: warehouse ? warehouse.street : null, 
        suburb: warehouse ? warehouse.suburb : null, 
        municipality: warehouse ? warehouse.municipality : null, 
        state: warehouse ? warehouse.state : null
    }

    const user_address_info = {
        street: address ? address.street : null, 
        suburb: address ? address.suburb : null, 
        municipality: address ? address.municipality : null, 
        state: address ? address.state : null
    }
   
    let title = order_type ? catalogs.order_types.find(el=>el.value === order_type).label : content.title

    let addressContent = null

    if(order_type === 1){
        addressContent= <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={1}>
                                    <Grid item><DisplayText variant='subtitle2'>{content.address}</DisplayText></Grid>
                                    <Grid item><DisplayText >{onGetFullAddress(user_address_info)}</DisplayText> </Grid>
                                </Grid>
                            </Grid>
                            { scheduled === 1 ? <Grid item xs={12} md={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}><DisplayText variant='subtitle2'>{content.schedule_time}</DisplayText></Grid>
                                    <Grid item><DisplayText>{schedule_time ? moment(schedule_time).format('DD MMM YY, h:mm a') : null}</DisplayText> </Grid>
                                </Grid>
                            </Grid> : null}
                        </Grid>
        
    }
    if(order_type === 2){
        addressContent= <Grid container>
                            <Grid item xs={12} md={6}>
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item ><DisplayText variant='subtitle1'>{content.warehouse}</DisplayText></Grid>
                                    <Grid item ><DisplayText >{warehouse ? warehouse.name : '-'}</DisplayText></Grid>
                                    <Grid item xs={12}><DisplayIconData icon='mail' data={warehouse ? warehouse.contact_email : '-'}/></Grid>
                                    <Grid item xs={12}><DisplayIconData icon='smartphone' data={warehouse ? warehouse.contact_phone : '-'}/></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}><DisplayText variant='subtitle2'>{content.address}</DisplayText></Grid>
                                    <Grid item><DisplayText >{onGetFullAddress(address_info)}</DisplayText> </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
    }

    return(
        <SimpleCard>
            <Grid container spacing={1}>
                <Grid item xs={12}><DisplayText variant='subtitle1'>{title}</DisplayText></Grid>
                {addressContent}
            </Grid>
        </SimpleCard>
    )
}

export default DeliveryCard