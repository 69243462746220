import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import SearchBar from '../../../../components/Actions/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import empty from '../../../../assets/empty.png'
import DisplayText from '../../../../components/Texts/DisplayText'
import { translations as translations_esp } from '../../../../texts/esp/category-translations'
import TableCellLink from '../../../../components/Structure/Navigation/TableCellLink'
import ProductImage from '../../../../components/Structure/Images/ProductImage'

//const content = translations_esp.tables.products

const header = [
    {id:1, label:'Activo'},
    {id:2, label:'Cantidad'},
    {id:3, label:'Unidad'},
    {id:4, label:'Descripción'},
    {id:5, label:''},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        //minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:20,
        padding:'50px 0px'
    }
}))

const ActivesTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onDeleteItem} = props

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            {/* <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid> */}
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {name, quantity, unit, description, id_product_active_substance} = item

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell}  onClick={() => onSelectedItem(item)}><DisplayText>{name}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}  onClick={() => onSelectedItem(item)}><DisplayText>{quantity}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}  onClick={() => onSelectedItem(item)}><DisplayText>{unit}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}  onClick={() => onSelectedItem(item)}><DisplayText>{description}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} >
                                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                                <IconButton onClick={() => onDeleteItem(id_product_active_substance)}><Icon>delete</Icon></IconButton>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}       
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                {/* <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/> */}
            </div> : null}
            {data.length === 0 && !loading  ? (
                
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>no existen activos disponibles</DisplayText>
                        </Grid>
                    </Grid>
                </div>  
                        
            ) : null}       
        </div>
    )
}

export default ActivesTable