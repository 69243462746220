import React, {useEffect, useState} from 'react'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
//import AddModal from './modals/AddModal'
import { request_carts } from './requests'
import { translations as translations_esp } from '../../../texts/esp/carts-translations'


const Carts = props => {

    const content = translations_esp
    const { history, user } = props

    const [loading, setLoading] = useState(false)
    const [carts, setCarts] = useState([])

    //modals
    const [addModal, setAddModal] = useState(false)
 
    //table
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'id_cart',
    })
    const [total, setTotal] = useState(10)
    const [search, setSearch] = useState(null)
    


    useEffect(() => {
        const initModule = async() => {try {  
            console.log(user)
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            console.log(params)
            const _carts = await request_carts(params)
            console.log(_carts)
            setCarts(_carts.cart_data)
            setTotal(_carts.count)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])

    
    const onSelectedItem = (item) => {
        const {id_cart} = item
        history.push(`/carts/${id_cart}`)
    }
  
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(tableFilter, search)
        const _carts = await request_carts(params)
        setCarts(_carts.cart_data)
        setTotal(_carts.count)
        setAddModal(false)
    }

    let can_create = true
    //if(user && (user.user_type_id === 1)) can_create = true

    return(
        <Page title={content.title} >
            {/* <AddModal history={history} user={user} open={addModal} onClose={() => setAddModal(false)} 
            onUpdateCompleted={onUpdateCompleted} content={content.modal} /> */}
            <SimpleTable
                data={carts} 
                search={search}
                tableFilter={tableFilter}
                total={total}
                loading={loading}
                onAddRegister={can_create ? () => setAddModal(true) : null}
                onChangeSearch={onChangeSearch}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                content={content.table}
                />
        </Page>
    )
}

export default Carts