import { change_image_error_msg } from "./general-translations";

export const translations = {
    table: {
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Marca'},
            {id:3, label:'Descripción'},
            {id:4, label:'Color'},
        ],
        nodata:'No existen marcas registradas'
    },
    modal: {
        success_msg:'Acción exitosa',
        add_title:'Agregar marca',
        edit_title:'Editar marca',
        button:'Guardar',
        add_button:'Agregar',
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            color:{
                label:'Color',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            description:{
                label:'Descripción',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            url:{
                label:'Url',
                helperText:'Este campo es obligatorio'
            },
        }
    },
}

