import React from 'react'
import { LinearProgress, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Icon } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import SearchBar from '../../../components/Actions/SearchBar'
import ActionBar from '../../../components/Actions/ActionBar'
import empty from '../../../assets/empty.png'
import AvailableStatus from '../../../components/Structure/DisplayData/AvailableStatus'
import ProductImage from '../../../components/Structure/Images/ProductImage'
import DisplayText from '../../../components/Texts/DisplayText'


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()

    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, 
        loading, content} = props

    const header = content.header
   
    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item><SearchBar value={search} onChange={onChangeSearch} /></Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs><LinearProgress color='primary'/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 0 ? 'checkbox' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' align={item.id === 1 ? 'center' : 'start'} >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_warehouse, name, contact_name, contact_email, contact_phone, street, ext, int, zip_code, suburb,
                                        municipality, state, country} = item

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'edit')}><DisplayText>{id_warehouse}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'edit')}><DisplayText variant='subtitle1'>{name}</DisplayText></TableCell>   
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'edit')}><DisplayText>{contact_name}</DisplayText></TableCell>                         
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'edit')}><DisplayText>{contact_email}</DisplayText></TableCell> 
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'edit')}><DisplayText>{contact_phone}</DisplayText></TableCell>   
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item, 'edit')}><DisplayText>{street}</DisplayText></TableCell> 
                                            {/* <TableCell className={classes.cell}><AvailableStatus onClick={()=>onChangeStatus(item)} status={enabled} loading={sending}/></TableCell>  */}
                                            {/* <TableCell className={classes.cell}><IconButton onClick={()=>onEdit(item)}><Icon>edit</Icon></IconButton></TableCell> */}  
                                            <TableCell className={classes.cell}><IconButton onClick={() => onSelectedItem(item, 'delete')}><Icon>delete</Icon></IconButton></TableCell>                           
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable