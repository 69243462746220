import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { catalogs } from '../../../texts/esp/catalogs'
import cx from 'classnames'
import { blueGrey, green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        fontSize:14,
        color:'white',
        display:'inline-block',
        padding:'8px 16px',
        borderRadius:32,
        cursor:'pointer'
    },
    active:{
        background:green[700]
    },
    inactive:{
        background:red[700]
    }
}))


const AvailableStatus = ({status, onClick, loading}) => {

    const classes = useStyles()

    return(
        <div onClick={onClick} className={cx({
            [classes.root]:true,
            [classes.active]:Boolean(status),
            [classes.inactive]:!Boolean(status)
        })}>
            <Grid container alignItems='center'>
                <Grid item>{Boolean(status) ? 'Activo' : 'Inactivo'}</Grid>
                <Grid item>{loading ? (<CircularProgress style={{ width: 24, height: 24, marginLeft: 8, color: 'white' }} />) : null}</Grid>
            </Grid>
        </div>
    )
}

export default AvailableStatus