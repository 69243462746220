const { mainServer } = require("../../variables/config");

export const request_doctors = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/users/all${params}`)
    return response.data.data
}

export const request_create_user = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    let response = await mainServer.post(`/users`, data)
    return response.data.data
}

export const request_create_doctor_translations = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    let response = await mainServer.post(`/doctor/${id}/translations`, data)
    return response.data.data
}

