import { Grid, Chip } from '@material-ui/core'
import React, {useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import SearchCategories from '../forms/SearchCategories'
import AddCategoryModal from '../modals/AddCategoryModal'
import { request_create_product_category, request_delete_product_category } from '../requests'
import { translations as translations_esp } from '../../../../texts/esp/product-translations'

const Categories = ({id, product_categories, onUpdateCompleted }) => {

    const content = translations_esp.categories

    const [add_modal, setAddModal] = useState(false)

    const onDeleteItem = async(el) => {
        await request_delete_product_category(el)
        onUpdateCompleted()
    }

    const onAddProductItem = async (el) => {
        try {
            await request_create_product_category({product_id:id, category_id:el});
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <SimpleCard>
        <AddCategoryModal open={add_modal} onClose={()=>setAddModal(false)} onUpdateCompleted={onUpdateCompleted} 
        onAddProductItem={onAddProductItem} content={content.modal}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>                 
                    <DisplayText variant='subtitle1'>{content.title}</DisplayText>                
                </Grid>
                <Grid item xs={12}>
                    <SearchCategories onAddItem={()=>setAddModal(true)} existingItems={product_categories} 
                    onAddProductItem={onAddProductItem} content={content.search_bar} />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={1}>
                        {product_categories.map((item, index)=>{
                            return(
                                <Grid item key={index}>
                                    <Chip label={item.category} onDelete={()=>onDeleteItem(item.id_product_category)} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>      
        </SimpleCard>
    )
}

export default Categories