import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../shared/utility'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../components/Texts/DisplayText'
import InputForm from '../../../../components/Forms/InputForm'
import { request_products, request_update_inventory_availability } from '../requests'




const EditAvailabilityModal = ({open, onClose, onUpdateCompleted, content, id}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
    const onInitModal = async() => {
        if (!open) {
            let _form = onInitEmptyForm(formData, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }
    onInitModal()
    }, [open])

    
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            await request_update_inventory_availability(id, data2send);
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal maxWidth='xs' open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.edit_title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >                                  
                            <InputForm data={form.available} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default EditAvailabilityModal

const formData = {
    available: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'available',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min: 1, max: 500000
        }
    },
}
