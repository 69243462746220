import React from "react";
import { Drawer, Grid, makeStyles } from "@material-ui/core";
import UserAvatar from "./components/UserAvatar";
import { isCompatibleURL } from "../../../shared/utility";
import SidebarButton from "./components/SidebarButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sidebar_settings } from "../../../texts/esp/sidebar-translations";

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        boxShadow:'border-box',
        margin:16,
        borderRadius:24,
        //height:'calc(100vh - 32px)',
        //height:'100%',
        paddingBottom:40,
        border:0,
        [theme.breakpoints.down('xs')]: {
            margin:0,
            borderRadius:'0px 24px 24px 0px',
            height:'100%'
        }
        /*position:'fixed',
        top:0,
        zIndex:theme.zIndex.drawer,
        height:'calc(100vh - 32px)',
        height:"auto",
        
        background:theme.palette.primary.main,
        
        
        ,*/
    },
    drawer:{
      background:"transparent"  ,
      border:0
    },
    subroot:{
        padding:24
    },
    container:{
        marginTop:40
    }
}))

const Sidebar = (props) => {

    const classes = useStyles()
    const {history, user} = props

    const onSelectedURL = (url) => history.push(url)

    return(
        <Drawer
        //className={classes.drawer}
        classes={{
          paper: classes.drawer,
        }}
        style={{border:0}}
        anchor="left"
        variant={props.variant}
        open={props.open}
        onClose={props.closeSide ? props.closeSide : null}
        >
        <div className={classes.root}>
            <div className={classes.subroot}>
                <UserAvatar {...user}/>
                <div className={classes.container}>
                    <Grid container direction='column' alignItems='center' justify='center' spacing={1}>
                        {sidebar_settings.list.map(item => {
                            let enabled = true  
                            if(item.permissions.length && user){
                                const findpermission = item.permissions.find(el => el === user.user_type_id)
                                
                                if(!findpermission){
                                    //console.log('------------------')
                                    //console.log(item)
                                    //console.log(findpermission)
                                    enabled = false
                                }
                            }
                            if(!enabled) return null
                            //console.log(item.name)
                            return(
                                <Grid item key={item.url}>
                                    <SidebarButton {...item} selected={isCompatibleURL(history, item.url)} onSelectedURL={onSelectedURL}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                    
                </div>
            </div>
        </div>
        </Drawer>

    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps)(Sidebar))
