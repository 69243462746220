import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './style.css'
import { isFormValid, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../../shared/utility'
import { catalogs } from '../../../../texts/esp/catalogs'
import { request_create_doctor_translations, request_doctor_details, request_doctor_translations, request_update_doctor_details, request_update_doctor_translations } from '../requests'

const MedicalInfo = ({content, id, onSuccess, onGetError, onUpdateSending}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)

    const [form1, setForm1] = useState(JSON.parse(JSON.stringify(formData1)))
    const [form2, setForm2] = useState(JSON.parse(JSON.stringify(formData2)))

    const [doctor, setDoctor] = useState(null)
    const [doctor_trans, setDoctorTrans] = useState(null)
    const [language_selected, setLanguageSelected] = useState(1)
    const [description, setDescription] = useState('')
    const [flag, setFlag] = useState(false)
    const [error_bio, setErrorBio] = useState('')

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _doctor = await request_doctor_details(id)
            const _doctor_trans = await request_doctor_translations(id, language_selected)
            setDoctorTrans(_doctor_trans.doctor_details_translation)
            //console.log(_user)
            setDoctor(_doctor.doctor_details)
            let _form1 = onInitEmptyForm(form1, content.form_medical)
            _form1 = onInitForm(_form1, _doctor.doctor_details)
            setForm1(_form1)
            let _form2 = onInitEmptyForm(form2, content.form_medical)
            _form2 = onInitForm(_form2, _doctor_trans.doctor_details_translation )
            _form2.language.value = language_selected
            setForm2(_form2)
            setDescription(_doctor_trans.doctor_details_translation.bio)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _doctor_trans = await request_doctor_translations(id, language_selected)
            setDoctorTrans(_doctor_trans.doctor_details_translation)
            console.log(_doctor_trans)
            if(_doctor_trans.doctor_details_translation === null){
                setFlag(true)
                let _form2 = onInitEmptyForm(formData2, content.form_medical)
                _form2.language.value = language_selected
                setForm2(_form2)
                setDescription('')
            }
            else{
                let _form2 = onInitForm(form2, _doctor_trans.doctor_details_translation )
                _form2.language.value = language_selected
                setForm2(_form2)
                setDescription(_doctor_trans.doctor_details_translation.bio)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [language_selected])

    const onChange = (data) => {
        let temp = { ...form1 }
        const id = data.config.id
        temp[id] = { ...data }
        setForm1(temp)
    }

    const onChange2 = (data) => {
        let temp = { ...form2 }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'language') setLanguageSelected(temp[id].value)
        if(id === 'bio') setDescription(temp[id].bio)
        setForm2(temp)
    }


    const onSubmit = async() => {
        const errors = isFormValid(form1) 
        const errors2 = isFormValid(form2)
        //console.log(errors)
        /// Check if the form is valid
        if(errors && errors.length){
            //console.log('errors1')
            const new_form = onSetErrorsToForm(form1, errors)
            setForm1(new_form)
            return
        }

        if(errors2 && errors2.length){
            //console.log('Errors2')
            const new_form = onSetErrorsToForm(form2, errors)
            setForm2(new_form)
            return
        }

        if(description === ''){
            setErrorBio(content.bio_error_msg)
            //console.log('hola')
            return
        }

        //Extract data
        let data2send_doctor_details = onGetSelectedFormData(form1, doctor)
        console.log(data2send_doctor_details)

        let data2send = onGetSelectedFormData(form2, doctor_trans)
        data2send.bio = description
        console.log(data2send)
        //console.log(flag)


        setSending(true)
        onUpdateSending(true)
        try {
            await request_update_doctor_details(id, data2send_doctor_details)
            if(flag){
                data2send.language_id = data2send.language
                delete data2send.language
                console.log(data2send)
                await request_create_doctor_translations(id, data2send);
            }else{
                delete data2send.language
                console.log(data2send)
                await request_update_doctor_translations(id, doctor_trans.id_doctor_detail_translation, data2send);
            }
            onSuccess()
            setSending(false)
            onUpdateSending(false)
        } catch (error) {
            console.log(error)
            onGetError(error)
            setSending(false)
            onUpdateSending(false)
        }
    }

    

    return(
        <SimpleCard>
            <Grid container spacing={4} >
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>{content.cards.medical_info}</DisplayText>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectForm data={form1.speciality_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <SelectForm data={form1.sub_speciality_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form1.license} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>{content.cards.personal_info}</DisplayText>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectForm data={form2.language} onChange={onChange2} />
                </Grid>
                <Grid item xs={12} >
                    <InputForm data={form2.qualifications} onChange={onChange2} />
                </Grid>
                <Grid item xs={12} >
                    <DisplayText variant='body2' color='textSecondary'>{content.form_medical.bio.label}</DisplayText>
                    <div style={{height:'300px', overflowY:'scroll', marginTop:'4px'}}>
                        <CKEditor
                            className='ck-editor__editable' 
                            editor={ClassicEditor}
                            data={description}
                            onChange={(e,editor) => {
                                const data = editor.getData()
                                setDescription(data)
                                if(data){setErrorBio('')}
                                else{setErrorBio(content.bio_error_msg)}
                            }}
                            config={{toolbar:{removeItems:['imageUpload' , 'insertMedia' ]}}}                                              
                        />
                    </div>
                    <DisplayText style={{color:'red', fontSize:14}} >{error_bio}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item>
                            <ContainedButton color='primary' loading={sending} onClick={onSubmit}>{content.cards.button}</ContainedButton>
                        </Grid>
                    </Grid>
                </Grid>                           
            </Grid> 
        </SimpleCard>
    )
}

export default MedicalInfo


const formData1 = {
    speciality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.specialities],
        config: {
            id: 'speciality_id',
            type: 'select',
            fullWidth: true,
        },
    },
    sub_speciality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.specialities],
        config: {
            id: 'sub_speciality_id',
            type: 'select',
            fullWidth: true,
        },
    },
    license: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'license',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}

const formData2 = {
    language: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.languages],
        config: {
            id: 'language',
            type: 'select',
            fullWidth: true,
        },
    },
    qualifications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'qualifications',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:3
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    bio: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'bio',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}