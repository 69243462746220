import React from 'react'


const MapView = ({data}) => {

    const lat = data ? data.latitude : '19.4380536'
    const lng = data ? data.longitude : '-99.1568188'

    return(
     
       <div>
         Mapa
       </div>
   
    )

}

export default MapView

