import { success_msg } from "./general-translations";

export const translations = {
    title:'Mi información',
    success_msg:success_msg,
    breadcrumbs_label:'Mis datos',
    user_info_card:{
        button:'Cambiar contraseña',
        modal:{
            title:'Cambio de contraseña',
            subtitle:'Por favor ingresa los datos solicitados',
            form:{
                password:{
                    label:'Contraseña',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                passwordConfirmation:{
                    label:'Confirmar contraseña',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
            },
            button:'Actualizar'
        }
    },
    edit_card:{
        title:'Editar perfil',
        form:{
            first_name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            fathers_last_name:{
                label:'Apellido paterno',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            mothers_last_name:{
                label:'Apellido materno',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            email:{
                label:'Email',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            user_gender_id:{
                label:'Género',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
        },
        button:'Guardar',
    }, 
}

