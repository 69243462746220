import moment from 'moment'
import { ERROR_CODES } from '../texts/esp/errorCodes';
import { mainServer, nodata } from '../variables/config';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const getFileExtension = (filename) => filename.split('.').pop();

export const onGetFullname = (first_name, last_name) => {
    //if(!first_name) return 'N/D'
    return `${first_name ? first_name : ''} ${last_name ? last_name : ''}`
}

export const isValidDocument = (filename) => filename && filename !== "0" && filename !== 0 && filename !== ""

export const onGetCompleteAddress = (data) => {
    if(!data) return '-'
    return `
    ${data.address1 ? data.address1 : ''}, 
    ${data.address2 ? data.address2 : ''}, 
    ${data.city ? data.city : ''}, 
    ${data.province ? data.province : ''}, 
    ${data.zip ? data.zip : ''}`
}

export const onGetFullAddress = (data) => {
    if(!data) return '-'
    return `
    Calle ${data.street ? data.street : ''}, ${data.suburb ? data.suburb : ''}, ${data.municipality ? data.municipality : ''}, ${data.state ? data.state : ''}`
}

export const onGetFormatNumber = (number) => {
    let result = ''
    if(!number) return '$0'
    let temp = parseFloat(number).toFixed(0)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    result = `$${result}`
    return result
}

export const isFormValid = (form) => {
    let errors = []
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isRequired && !form[item].isValid) errors.push(item)
        })
    }
    return errors
} 

export const isFilterFormValid = (form, filter) => {
    let errors = []
    if(form && filter && filter.length){
        filter.forEach(item => {
            if(!form[item].isValid) errors.push(item)
        })
    }
    return errors
} 

export const onSetErrorsToForm = (form, errors) => {
    let temp = {...form}
    if(errors && errors.length) errors.forEach(item => temp[item].isVisited = true)
    return temp
}

export const onGetFormData = (form) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(!origin){
                temp = {...temp, [item]: onCleanData(form[item].value)}
            }else{
                if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:onCleanData(form[item].value)}
            }
        })
    }
    return temp
}

export const onGetSelectedFormDataNumber = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(!origin){
                temp = {...temp, [item]: parseFloat(form[item].value)}
            }else{
                if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]: parseFloat(form[item].value)}
            }
        })
    }
    return temp
}

export const onInitForm = (form, origin) => {
    let response = {...form}
    if(!origin) return response
    Object.keys(form).forEach(item => {
        const value = origin[item]
        if(value) response[item] = {...response[item], value:value, isVisited:true, isValid:true}
    })
    return response
}

export const onInitEmptyForm = (form, translations) => {
    let response = {...form}
    Object.keys(form).forEach(item => {
        if(translations && translations[item]) response[item].config = {...response[item].config, ...translations[item]}
    })
    return response
}

export const s2ab = (s) =>{ 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
}


export const isTokenValid = () => {
    let isValid = false

    const userInfo = JSON.parse(localStorage.getItem('token_data'))
    if(userInfo){
        const currentTime = moment().unix()
        const serverTime = userInfo.exp
        isValid = currentTime < serverTime
    }
    return isValid
}

export const onGetUser = () => {
    let result = null

    const userInfo = JSON.parse(localStorage.getItem('token_data'))
    if(userInfo) result = userInfo
    return result
}

export const isCompatibleURL = (history, path) => history.location.pathname.split('/')[1] === path.split('/')[1]

export const isCartAvailable = async() => {
    let nItems = 0

    const cartID = localStorage.getItem('cartID')
    if(cartID){
        //console.log(cartID)
        //const currentTime = moment().utc()
        //const serverTime = moment(userInfo.expiresAt).utc()
        //isValid = currentTime.diff(serverTime)/(1000*60*60) < 0
    }
    return nItems
}

export const onGetCatalogs = (options, keyValue, keyLabel) => {
    if(!options) return []
    return options.map(item => {
        return{
            value: item[keyValue], label:item[keyLabel] ? item[keyLabel] : ''
        }
    })
}

export const onGetCatalogById = (catalogs, value) => {
    let res = '-'
    if(catalogs && value){
        catalogs.forEach(element => {
            if( element.value === value ) res = element.label
        });
    }
    return res
}

export const onGetCreditCardFormat = (data) => {
    let value = data.split(' ').join('')
    if(value.length <=16) return value.match(/.{1,4}/g).join(' ')
    return value.slice(0,-1).match(/.{1,4}/g).join(' ')
}

export const onGetExpirationFormat = (data) => {
    let value = data.split('/').join('')
    if(value.length <=4) return value.match(/.{1,2}/g).join('/')
    return value.slice(0,-1).match(/.{1,2}/g).join('/')
}

export const isExpDateIsValid = (data) => {
    if(data.split('/').join('').match(/^[0-9]{4}$/g)) return true
    return false
}

export const isCCVIsValid = (data) => {
    if(data.split('/').join('').match(/^[0-9]{3,4}$/g)) return true
    return false
}

export const onGetJSONData = (data) => {
    if(!data) return {}
    let temp = {}
    Object.keys(data).forEach(item => {
        if(data[item].isValid && data[item].isVisited) temp = {...temp, [item]: data[item].value}
    })
    return temp
}

export const onGetFileExtension = (filename) => filename.split('.').pop();

export const onGetErrorMessage = (error) => {
    let message = 'Hubo un error'
    if(!error) return ''
    if(error.response){
        if(error.response.data){
            if(error.response.data.error && error.response.data.error.code){
                const _error = ERROR_CODES[error.response.data.error.code]
                if(_error) message = _error
            }
        }
    }
    return message
}

export const onUpdateDocumentList = (documents, origin) => {
    if(!origin) return documents
    const _new_documents = documents.map(item => {
        let temp = {...item}
        const valid = isValidDocument(origin[item.id])
        temp.status = valid ? true : false
        return temp
    })
    return _new_documents
}

export const onGetImageURL2Blob = async(url) => {
    let blob = await fetch(url).then(r => r.blob());
    console.log(blob)
    let temp = blob.type.split('/');
    let ext = "."+temp[temp.length-1];
    let newfile = new File([blob], "image"+ext, {type:blob.type});
    return newfile;
}


const onGetManufacturer = (provider) => {

    let manufacturer = ''

    if(provider === 'Alcon' || provider === 'Bausch & Lomb') manufacturer = 'ManufacturerA'
    if(provider === 'CooperVision') manufacturer = 'ManufacturerB'
    if(provider === 'ITAL-LENT') manufacturer = 'ManufacturerC'
 
    return manufacturer
}

const onGetZone = (city) => {

    let zone = ''

    const mainCities = ['Bucaramanga', 'Bogotá', 'Cali', 'Medellin', 'Santa Marta', 'Cartagena', 'Sincelejo', 'Valledupar', 'Monteria']
    
    if (mainCities.includes(city)){
        zone = 'secondary'
    }else{
        if (city === 'Barranquilla'){
            zone = 'primary'
        }
        else{
            zone = 'tertiary'
        }
    }

    return zone
}

const onGetVisualCondition = (tags, title) => {

    let category = ''

    if(tags){
        if(tags.includes('MIOPIA')) category='sphere'
        if(tags.includes('ASTIGMATISMO')) category='toric'
        if(tags.includes('PRESBICIA')) category='multifocal'
        if(tags.includes('LENTES COSMETICOS')) category='cosmetic' 
        if(tags.includes('COSMOPROTÉSICO')) category='cosmoprotestic' 
    }

    if(title === 'BIOFINITY TORIC XR') category='toric_xr'
    if(title.includes('HIDROGEL')) category='hidrogel' 
    if(title.includes('sIHY')) category='sihy' 
    
    return category

}

export const onEstimateDeliveryDate = (json, provider, city, tags, title) => {

    const manufacturer = onGetManufacturer(provider)
    const zone = onGetZone(city)
    const visual_condition = onGetVisualCondition(tags, title)

    console.log(manufacturer)
    console.log(zone)
    console.log(visual_condition)

    //console.log(json)
    let deliveryDate
    if(manufacturer !=='' && zone !=='' && visual_condition !=='') {
        deliveryDate =  json[manufacturer][zone][visual_condition]
    }else{
        deliveryDate = -1
    }

    return deliveryDate

}


export const onUpdateAuthorizationHeader = () => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    if(credentials){
        mainServer.defaults.headers.common['Authorization'] = credentials.token
    }
}

export const onGetDateFormat = (data, format) => {
    if(!data) return nodata
    const momentdata = moment(data)
    if(!momentdata.isValid()) return nodata
    if(format) return momentdata.format(format)
    return momentdata.format('YYYY-MM-DD')
}

export const onCleanData = (data) => {
    if(data === undefined || data === null) return undefined
    if(data === "") return "@null!!"
    return data
}