import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import * as actionTypes from '../../../store/actions'
import ContainedButton from '../../../components/Actions/ContainedButton'
import InputForm from '../../../components/Forms/InputForm'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import Page from '../../../components/Structure/Page/Page'
import DisplayText from '../../../components/Texts/DisplayText'
import SelectForm from '../../../components/Forms/SelectForm'
import AddressCard from './components/AddressCard'
import ToolbarTable from '../../../components/Structure/Navigation/ToolbarTable'
import Inventory from './components/Inventory'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../shared/utility'
import { request_municipalities, request_states, request_update_warehouse, request_warehouse, request_zipcodes, request_zipinfo } from '../requests'
import { translations as translations_esp } from '../../../texts/esp/warehouse-translations'



const WarehouseView = (props) => {

    const content = translations_esp

    const {match, history} = props
    //global props
    const { onUpdateSending, onUpdateSuccess, onUpdateError } = props

    const id = match.params.id

    //general
    const [loading, setLoading] = useState(false)
    const [success, setSucess] = useState(null)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [warehouse, setWarehouse] = useState(null)
    const [zip_number, setZipNumber] = useState(null)

    //checks
    const [store, setStore] = useState(false)
    const [pickup, setPickup] = useState(false)
    const [delivery, setDelivery] = useState(false)
   

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _warehouse = await request_warehouse(id)
            setWarehouse(_warehouse.warehouse_data)
            let _form = onInitEmptyForm(form, content.edit_card.form)
            setForm(_form)
            _form = onInitForm(form, _warehouse.warehouse_data)
            setZipNumber(_warehouse.warehouse_data.zip_code)
            const zipcode = await request_zipinfo(_warehouse.warehouse_data.zip_code.toString())
            //console.log(zipcode.zip_information.zip_code)
            const suburbsCatalogs = onGetCatalogs(zipcode.zip_information.sub_urbs, 'id_suburb', 'name') 
            //console.log(suburbsCatalogs)
            _form.suburb_id.options = suburbsCatalogs
            const states = await request_states()
            const statesCatalogs = onGetCatalogs(states.states, 'id_state', 'name')
            const municipalities = await request_municipalities()
            const municipalitiesCatalogs = onGetCatalogs(municipalities.municipalities, 'id_municipality', 'name')
            //console.log(municipalitiesCatalogs)
            _form.state_id.options = statesCatalogs
            _form.municipality_id.options = municipalitiesCatalogs
            setForm(_form)
            setStore(Boolean(_warehouse.warehouse_data.store))
            setPickup(Boolean(_warehouse.warehouse_data.pickup))
            setDelivery(Boolean(_warehouse.warehouse_data.delivery))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    useEffect(() => {
        const onSearchZipAttributes = async(value) => {
            if(value === "" || value === null || value === undefined || value.length < 5) return
            try {
                let zip_info = await request_zipinfo(value)
                zip_info = zip_info.zip_information
                if(zip_info){
                    const {value, id_zip_code, municipality, municipality_id, state, state_id} = zip_info.zip_code
                    let _form = {...form}
                    _form.state_id.value = state_id
                    _form.state_id.isValid = true
                    _form.state_id.options = [{value:state_id, label:state}]
                    _form.municipality_id.value = municipality_id
                    _form.municipality_id.isValid = true
                    _form.municipality_id.options = [{value:municipality_id, label:municipality}]
                    _form.suburb_id.value = ''
                    _form.suburb_id.options = onGetCatalogs(zip_info.sub_urbs,'id_suburb','name')
                    _form.zip_code.value = value
                    _form.zip_code.isValid = true
                    _form.zip_code_id.value = id_zip_code
                    _form.zip_code_id.isValid = true
                    setForm(_form)
                }
            } catch (error) {
                //console.log(error)
            }
        } 
        onSearchZipAttributes(zip_number)
    }, [zip_number])

  
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
        if(id === 'zip_code') setZipNumber(data.value)
    }

    
    const onUpdateCompleted = async() => {
        const _warehouse = await request_warehouse(id)
        setWarehouse(_warehouse.warehouse_data)
    }


    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, warehouse)
        data2send.store = store ? 1 : 0
        data2send.pickup = pickup ? 1 : 0
        data2send.delivery = delivery ? 1 : 0
        console.log(data2send)
        
        setSending(true)
        onUpdateSending(true)
        try {
            await request_update_warehouse(id, data2send);
            onUpdateCompleted()
            setSending(false)
            onUpdateSending(false)
            onUpdateSuccess(content.success_msg)
            //setSucess('Acción exitosa')
        } catch (error) {
            console.log(error)
            //setError('Hubo un error')
            onUpdateError(onGetErrorMessage(error))
            setSending(false)
            onUpdateSending(false)
        }
    }

    //views
    const menu = content.menu
    const [view, setView] = useState(1)

    const onChangeView = (id) => {
        setView(id)
    }

    let contentView = null

    switch(view){
        case 1:
            contentView =  <SimpleCard>
                                <Grid container spacing={4} justify='flex-end'>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle1'>{content.edit_card.title}</DisplayText>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.name} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.contact_name} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.contact_phone} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.contact_email} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.additional_indications} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputForm data={form.map_url} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle2'>{content.edit_card.title_secondary}</DisplayText>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputForm data={form.latitude} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputForm data={form.longitude} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputForm data={form.zip_code} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <SelectForm data={form.suburb_id} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputForm data={form.street} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputForm data={form.ext} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <InputForm data={form.int} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <SelectForm data={form.municipality_id} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <SelectForm data={form.state_id} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <SelectForm data={form.country_id} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle2'>Servicios</DisplayText>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <FormControlLabel
                                            control={<Checkbox checked={store} onChange={()=>setStore(!store)} />}
                                            label={content.edit_card.formControl.store}
                                        />
                                    </Grid>
                                    <Grid item xs={6} >                                  
                                        <FormControlLabel
                                            control={<Checkbox checked={pickup} onChange={()=>setPickup(!pickup)} />}
                                            label={content.edit_card.formControl.pickup}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >                                  
                                        <FormControlLabel
                                            control={<Checkbox checked={delivery} onChange={()=>setDelivery(!delivery)} />}
                                            label={content.edit_card.formControl.delivery}
                                        />
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item>
                                        <ContainedButton color='primary' loading={sending} onClick={onSubmit}>{content.edit_card.button}</ContainedButton>
                                    </Grid>  
                                </Grid>
                            </SimpleCard>
            break
        case 2:
            contentView = <Inventory id={id} content={content.inventory} history={history}/>
            break
        default:
            break
    }


    return(
        <Page title={content.title} loading={loading} goback success={success} onCloseSuccess={() => setSucess(null)}
        error={error} onCloseErrorModal={()=>setError(null)}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                    <AddressCard data={warehouse}/>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <div style={{marginBottom:12}}>
                    <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
                    </div>
                    {contentView}
                </Grid>
            </Grid>
        </Page>
    )

}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(WarehouseView) 

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    contact_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    contact_email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_email',
            type: 'email',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
    contact_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_phone',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    street: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'street',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    ext: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ext',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    int: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'int',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    additional_indications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'additional_indications',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    zip_code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'zip',
        }
    },
    zip_code_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code_id',
            type: 'text',
            fullWidth: true,
        },
    },
    /* street: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'street',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    suburb_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'suburb_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type:'select',
        }
    },
    municipality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'municipality_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    state_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'state_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    country_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[{value:1, label:'México'}],
        config: {
            id: 'country_id',
            type: 'select',
            fullWidth: true,
            disabled:true
        },
        rules: {
            type: 'select',
        }
    },
    map_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'map_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    latitude: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'latitude',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    longitude: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'longitude',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
}