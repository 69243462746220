import { makeStyles } from '@material-ui/core'
import React from 'react'
import { catalogs } from '../../../texts/esp/catalogs'
import cx from 'classnames'
import { blue, green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        fontSize:14,
        color:'white',
        display:'inline-block',
        padding:'8px 16px',
        borderRadius:32
    },
    active:{
        background:green[700]
    },
    inactive:{
        background:red[700]
    },
    invited:{
        background:blue[700]
    }
}))


const UserStatus = ({status_id}) => {

    const classes = useStyles()
    const status = catalogs.user_statuses.find(el=>el.value === status_id)

    return(
        <div className={cx({
            [classes.root]:true,
            [classes.active]:status_id === 3,
            [classes.inactive]:status_id === 1,
            [classes.invited]:status_id === 2
        })}>
            {status.label}
        </div>
    )
}

export default UserStatus