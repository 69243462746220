import { CircularProgress, Grid, Icon, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DisplayText from '../../../../components/Texts/DisplayText';
import { request_update_product } from '../requests';


const SearchBrands = ({catalogs, brand, onAddBrand, id, content, onUpdateSending}) => {

    const classes = useStyles()
    const add_register = content.add_register

    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([add_register]);
    const [value, setValue] = useState(brand)

    const onUpdateBrand = async(value) => {
        onUpdateSending(true)
        try{
            
            await request_update_product(id, {brand_id:value})
            
        }catch(error){
            console.log(error)
        }
        onUpdateSending(false)
    }
   
    const onChange = (e, item) => {
        if(item){
            if(item.type === 'add'){
                console.log('agregar')
                onAddBrand()
                return
            }else{
                setValue(item)
                onUpdateBrand(item.value)
            }
        }else{
            setValue(null)
        }      
    }

    const onInputChange = async(e) => {
        if(!e || e.target.value === 0) return
        try {
            setLoading(true)
            setOptions([add_register, ...catalogs])    
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }


    return (  
        <div>
            <DisplayText variant='subtitle1'>{content.title}</DisplayText>
            <Autocomplete
                id="search-user-id"
                getOptionLabel={(option) => option.label}
                options={options}
                loading={loading} fullWidth
                value={value}
                onChange={onChange}
                onInputChange={onInputChange}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" 
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                    />
                )}
                renderOption={(option, {selected}) => {
                    if(option.type === 'add'){
                        return(
                            <React.Fragment>
                                <div >
                                    <Grid container alignItems='center'>
                                        <Grid item><Icon color='primary' className={classes.add_icon}>add</Icon></Grid>
                                        <Grid item xs><DisplayText>{option.label}</DisplayText></Grid>
                                    </Grid>
                                    
                                </div>
                            </React.Fragment>
                        )
                    }
                    return (
                        <React.Fragment>
                            <div>
                                <DisplayText>{option.label}</DisplayText>
                            </div>
                        </React.Fragment>
                    )
                }}
            />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    add_icon:{
        background:theme.palette.primary.main,
        color:'white',
        borderRadius:'50%',
        marginTop:6, marginRight:8
    }
}))
 
export default SearchBrands;