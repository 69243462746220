import React, { useState, useEffect } from 'react'
import { Grid, Checkbox, FormControlLabel} from '@material-ui/core'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { request_create_product } from '../../requests'
import SelectForm from '../../../../../components/Forms/SelectForm'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import { request_brands } from '../../../ProductView/requests';
import { translations as translations_esp } from '../../../../../texts/esp/products-translations';



const AddUserModal = props => {

    const content = translations_esp.modal

    const { open, onClose, history } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //checks
    const [recipe_check, setRecipeCheck] = useState(false)
    const [retains_check, setRetainsCheck] = useState(false)
    const [sale_check, setSaleCheck] = useState(false)
    const [taxable_check, setTaxableCheck] = useState(false)

    useEffect(() => {
        const initModal = async() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
            const _brands = await request_brands()
            const brandsCatalogs = onGetCatalogs(_brands.brands_data, 'id_brand', 'name')
            let _form = onInitEmptyForm(form, content.form)
            _form.brand_id.options = brandsCatalogs
            setForm(_form)
            setDisabled(false)
        }
        }
        initModal()
    }, [])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        data2send.enabled = 1
        data2send.requires_recipe = recipe_check ? 1 : 0
        data2send.retains_recipe = retains_check ? 1 : 0
        data2send.taxable = taxable_check ? 1 : 0
        data2send.sale = sale_check ? 1 : 0

        //console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            let response = await request_create_product(data2send);
            //console.log(response)
            setLoading(false)
            history.push(`/products/${response.product_id}`)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <DisplayText >{content.subtitle}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.brand_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.short_description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.url} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.details} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputForm data={form.weight} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputForm data={form.height} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputForm data={form.width} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputForm data={form.length} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.sat_unit_key} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.sat_product_key} onChange={onChange} />
                        </Grid> 
                        <Grid item xs={12}>
                            <InputForm data={form.sku} onChange={onChange} />
                        </Grid> 
                        <Grid item xs={12}>
                            <InputForm data={form.barcode} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={taxable_check} onChange={()=>setTaxableCheck(!taxable_check)} />}
                                label={content.formControl.taxable}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={sale_check} onChange={()=>setSaleCheck(!sale_check)} />}
                                label={content.formControl.sale}
                            />
                        </Grid> 
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={recipe_check} onChange={()=>setRecipeCheck(!recipe_check)} />}
                                label={content.formControl.requires_recipe}
                            />
                        </Grid>
                        <Grid item xs={6} >                                  
                            <FormControlLabel
                                control={<Checkbox checked={retains_check} onChange={()=>setRetainsCheck(!retains_check)} />}
                                label={content.formControl.retains_recipe}
                            />
                        </Grid>                     
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddUserModal


const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    short_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'short_description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción Corta',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 1, max: 1024
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 255',
        },
        rules: {
            type: 'distance',
            min: 1, max: 1024
        }
    },
    /* description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    }, */
    details: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'details',
            type: 'text',
            fullWidth: true,
            label: 'Detalles',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    weight: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'weight',
            type: 'text',
            fullWidth: true,
            label: 'Peso',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    height: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'height',
            type: 'text',
            fullWidth: true,
            label: 'Altura',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    width: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'width',
            type: 'text',
            fullWidth: true,
            label: 'Ancho',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    length: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'length',
            type: 'text',
            fullWidth: true,
            label: 'Largo',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    /* warranty: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'warranty',
            type: 'text',
            fullWidth: true,
            label: 'Garantía',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:2,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    }, */
    sat_unit_key: {
        value: 'H87',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        config: {
            id: 'sat_unit_key',
            type: 'text',
            fullWidth: true,
            label: 'Clave unitaria sat',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:1,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    sat_product_key: {
        value: '51101500',
        error: false,
        isVisited: true,
        isRequired: true,
        isValid: true,
        config: {
            id: 'sat_product_key',
            type: 'text',
            fullWidth: true,
            label: 'Clave del producto',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:1,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    /* requires_recipe: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[{value:1, label:'Sí'},{value:0, label:'No'}],
        config: {
            id: 'requires_recipe',
            type: 'selected',
            fullWidth: true,
            label: 'Requiere receta',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    retains_recipe: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[{value:0, label:'No'},{value:1, label:'Sí'},],
        config: {
            id: 'retains_recipe',
            type: 'selected',
            fullWidth: true,
            label: 'Retiene receta',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    sale: {},
    taxable:{}, */
    brand_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'brand_id',
            type: 'selected',
            fullWidth: true,
            label: 'Marca',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    sku: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sku',
            type: 'text',
            fullWidth: true,
            label: 'Sku',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    barcode: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'barcode',
            type: 'text',
            fullWidth: true,
            label: 'Barcode',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
}