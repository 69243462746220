import { FormControl, FormLabel, FormControlLabel, Grid, RadioGroup, Radio, Checkbox } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import { isFormValid } from '../../../../components/Forms/customFunctions'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetErrorMessage, onGetSelectedFormData, onGetSelectedFormDataNumber, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../../shared/utility'
import { request_create_product_prices, request_update_product, request_update_product_prices } from '../requests'
import { translations as translations_esp } from '../../../../texts/esp/product-translations'
import CheckForm from '../../../../components/Forms/CheckForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../texts/esp/catalogs'
import InputCheckbox from '../../../../components/Forms/InputCheckbox'
import moment from 'moment'


const Price = ({prices, id, product, onUpdateCompleted, onUpdateError, onUpdateSuccess, onUpdateSending}) => {

    const content = translations_esp.price

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [form_product, setFormProduct] = useState(JSON.parse(JSON.stringify(formDataProduct)))
    const [sending, setSending] = useState(false)
    const [currency, setCurrency] = useState(1)

    //checks
    //const [sale_check, setSaleCheck] = useState(false)
    //const [taxable_check, setTaxableCheck] = useState(false)

    //const [discount_type, setDiscountType] = useState(1)

    useEffect(() => {
    const initModule = async() => {
        try {  
            let _form = onInitEmptyForm(form, content.form)  
            _form = onInitForm(_form, prices[currency])
            //console.log(product)
            if(Boolean(product && product.taxable)){
                if(parseFloat(_form.price.value) !== 0) _form.price.value = (parseFloat(_form.price.value) * 1.16).toFixed(2)
                if(parseFloat(_form.sale_bundle_price.value) !== 0) _form.sale_bundle_price.value = (parseFloat(_form.sale_bundle_price.value) * 1.16).toFixed(2)             
            }
            setForm(_form)

            let _form_product = onInitEmptyForm(form_product, content.form_product) 
            _form_product = onInitForm(_form_product, product)
            _form_product.product_discount_type_id.options = catalogs.discount_types
            _form_product.sale_expiration_date.value = moment().format('YYYY-MM-DD')
            _form_product.sale.value = product.sale
            _form_product.taxable.value = product.taxable
            _form_product.multiple_sale.value = product.multiple_sale
            setFormProduct(_form_product)
            //console.log('hola use effect')
        } catch (error) {
            console.log(error)
        }
    }
    if(product) initModule()
    }, [product])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'price' || id === 'sale_discount'){ 
            temp['sale_price'].value = temp['price'].value - temp['sale_discount'].value
            if(parseFloat(temp['sale_discount'].value) > parseFloat(temp['price'].value)){
                temp['sale_discount'].isValid = false     
            }
            if(parseFloat(temp['sale_discount'].value) < parseFloat(temp['price'].value)){
                temp['sale_discount'].isValid = true     
            }
            //console.log(temp['sale_discount'].value)
        }
        setForm(temp)
    }

    const onChangeProd = (data) => {
        let temp = { ...form_product }
        const id = data.config.id
        temp[id] = { ...data }
        //if(id === 'sale_expiration_date') console.log(data)
        setFormProduct(temp)
    }

    const onChangeCurrency = (event) => {
        setCurrency(parseInt(event.target.value))
        let temp = JSON.parse(JSON.stringify(formData))
        let _form = onInitForm(temp, prices[parseInt(event.target.value)])
        setForm(_form)
    }

    const onSubmit = async () => {
    
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log(errors)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormDataNumber(form, prices[currency])
        delete data2send.currency_id
        //console.log(data2send)

        //patch product id
        let productdata2send = onGetSelectedFormData(form_product, product)
        /* if(productdata2send.sale) productdata2send.sale = productdata2send.sale === true ? 1 : 0
        if(productdata2send.taxable) productdata2send.taxable = productdata2send.taxable === true ? 1 : 0
        if(productdata2send.multiple_sale) productdata2send.multiple_sale = productdata2send.multiple_sale === false ? 0 : 1 */
       

        if(form_product.taxable.value === 1 ){
            //productdata2send.taxable = 1
            if(data2send.price) data2send.price = parseFloat((data2send.price / 1.16).toFixed(2))
            if(data2send.sale_bundle_price){
                data2send.sale_bundle_price === 0 ? data2send.sale_bundle_price = 0 : data2send.sale_bundle_price = parseFloat((data2send.sale_bundle_price / 1.16).toFixed(2))
            }
            
        }
        

        if(form_product.sale.value === 1 ){
            //console.log('hola')
            //productdata2send.sale = 1
            if(productdata2send.product_discount_type_id === 1){
                delete productdata2send.sale_bundle_free_quantity
                delete productdata2send.sale_bundle_free_quantity
                delete productdata2send.sale_bundle_quantity
                delete data2send.sale_bundle_price
            }
            if(productdata2send.product_discount_type_id === 2){
                delete data2send.sale_discount
                delete data2send.sale_price
                delete productdata2send.sale_bundle_quantity
                delete data2send.sale_bundle_price
            }
            if(productdata2send.product_discount_type_id === 3){
                delete data2send.sale_discount
                delete data2send.sale_price
                delete productdata2send.sale_bundle_paid_quantity
                delete productdata2send.sale_bundle_free_quantity
            }
        }

        


        console.log(productdata2send)
        console.log(data2send)

        console.log(prices)
        let price_id = prices[currency].id_product_price.toString()
        console.log(price_id)

        
        
        setSending(true)
        onUpdateSending(true)
        try {
            if(prices.length === 0){
                //console.log('create price')
                data2send.currency_id = currency === 0 ? 1 : 2
                //console.log(data2send)
                await request_create_product_prices(id, data2send);
            }else{
                //console.log('update price')
                //console.log(data2send)
                await request_update_product_prices(id, price_id, data2send);
                //setSending(false)
            }
            await request_update_product(id, productdata2send);
            onUpdateCompleted()
            onUpdateSuccess(content.success_msg)
            setSending(false)
        } catch (error) {
            //console.log(error)
            onUpdateError(onGetErrorMessage(error))
            setSending(false)
        }
        onUpdateSending(false)
    }

    let discount_view = null

    switch (form_product.product_discount_type_id.value) {
        case 1:
            discount_view = <Grid container spacing={4}>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form.sale_discount} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form.sale_price} onChange={onChange} />
                                </Grid>
                            </Grid>
            break;
        case 2:
            discount_view = <Grid container spacing={4}>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form_product.sale_bundle_paid_quantity} onChange={onChangeProd} />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form_product.sale_bundle_free_quantity} onChange={onChangeProd} />
                                </Grid>
                            </Grid>
            break;
        case 3:
            discount_view = <Grid container spacing={4}>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form_product.sale_bundle_quantity} onChange={onChangeProd} />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form.sale_bundle_price} onChange={onChange} />
                                </Grid>
                            </Grid>
            break;
        default:
            break;
    }

    return(
        <SimpleCard>
            <Grid container spacing={4} justify='flex-end'>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>{content.title}</DisplayText>
                </Grid>
                <Grid item xs={12} >
                    <Grid container>
                        <Grid item md={6} xs={12}>
                            <FormControl component="fieldset">
                            <FormLabel component="legend">{content.form.currency_id.label}</FormLabel>
                            <RadioGroup value={currency} onChange={onChangeCurrency}>
                                <FormControlLabel
                                value={1} control={<Radio />} label={content.formControl.currency_1} />
                                <FormControlLabel value={0} control={<Radio />} label={content.formControl.currency_2} />
                            </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <InputForm data={form.price} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <FormControlLabel
                                        control={<Checkbox checked={taxable_check} onChange={()=>setTaxableCheck(!taxable_check)} />}
                                        label={content.formControl.taxable}
                                    /> */}
                                    <InputCheckbox data={form_product.taxable} onChange={onChangeProd} />
                                </Grid>                     
                            </Grid>             
                        </Grid>
                    </Grid>     
                </Grid>
                <Grid item xs={12} >
                    {/* <FormControlLabel
                        control={<Checkbox checked={sale_check} onChange={()=>setSaleCheck(!sale_check)} />}
                        label={content.formControl.sale}
                    /> */}
                    <InputCheckbox data={form_product.sale} onChange={onChangeProd} />
                </Grid>    
                {Boolean(form_product.sale.value) ?<Grid item xs={12} md={6} >
                     <InputForm data={form_product.sale_expiration_date} onChange={onChangeProd} />
                </Grid> : null}
                {Boolean(form_product.sale.value) ? <Grid item xs={12} md={6} >
                    <SelectForm data={form_product.product_discount_type_id} onChange={onChangeProd} /> 
                </Grid>: null}
                {Boolean(form_product.sale.value) ? <Grid item xs={12} >
                     {discount_view}  
                </Grid> : null}
                <Grid item xs={12} md={6}>
                    <InputCheckbox data={form_product.multiple_sale} onChange={onChangeProd} />
                </Grid>
                <Grid item xs={12} md={6}>
                    {Boolean(form_product.multiple_sale.value) ? <InputForm data={form_product.multiple_sale_quantity} onChange={onChangeProd} /> : null}
                </Grid> 
                <Grid item >          
                    <ContainedButton color='primary' onClick={onSubmit} loading={sending}>{content.button}</ContainedButton>                 
                </Grid>
            </Grid>
        </SimpleCard>
    )

}

export default Price

const formData = {
    
    currency_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[{value:1, label:'Peso mexicano'},{value:2, label:'Dolar americano'}],
        config: {
            id: 'currency_id',
            type: 'selected',
            fullWidth: true,
            label: 'Moneda',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'price',
            type: 'text',
            fullWidth: true,
            label: 'Precio',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    }, 
    sale_discount: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'sale_discount',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_price: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_price',
            type: 'text',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_bundle_price: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_price',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },   
}

const formDataProduct = {
    taxable: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'taxable',
            fullWidth: true,
            //label: 'Incluir iva en el precio',
        },
    },
    sale: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sale',
            fullWidth: true,
            //label: 'Incluir descuento',
        },
    },
    multiple_sale: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'multiple_sale',
            //label: 'Promociones por puntos',
        },
    },
    multiple_sale_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'multiple_sale_quantity',
            type: 'text',
            fullWidth: true,
            //label: 'Cantidad',
            //helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_expiration_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sale_expiration_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de expiración',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'date',
        }
    },
    product_discount_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'product_discount_type_id',
            type: 'selected',
            fullWidth: true,
            label: 'Tipo de descuento',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    sale_bundle_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Cantidad de articulos para precio de mayoreo',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_bundle_paid_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_paid_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Articulos pagados',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_bundle_free_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_free_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Articulos gratis',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
}