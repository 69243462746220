import React, {useState, useEffect} from 'react'
import Page from '../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { request_warehouses } from './requests'
import queryString from 'query-string'
import DeleteModal from './modals/DeleteModal'
import AddModal from './modals/AddModal'
import { translations as translations_esp } from '../../texts/esp/warehouses-translations'

const Warehouses = (props) => {

    const content = translations_esp

    const { history } = props

    const [loading, setLoading] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'zip_code_id',
    })
    const [search, setSearch] = useState(null)

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [selected, setSelected] = useState(null)
   

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            onRequestModelData()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])


    const onRequestModelData = async() => {  
        const {limit, offset, order_by, order} = tableFilter
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}` 

        if(search){
            let filterArgs = [
                {"field":"name","operator":"LIKE","value":`${search}`},
            ]
            filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
            params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` 
        }

        const _warehouses = await request_warehouses(params)
        console.log(_warehouses)
//*
        setWarehouses(_warehouses.warehouses_data)
        setTotal(_warehouses.count)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onSelectedItem = (item, type) => {
//*
        const {id_warehouse} = item
        setSelected(id_warehouse)
        if(type === 'edit') history.push(`/warehouses/${id_warehouse}`)
        if(type === 'delete') setDeleteModal(true)
        //if(type === 'images') setImagesModal(true)
    }

    const onUpdateCompleted = async() => {
        onRequestModelData()
        setDeleteModal(false)
    }

    return(
        <Page title={content.title} >
            <AddModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted}  
            content={content.modal}/>
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} 
            id={selected}/>
            <SimpleTable
                data={warehouses} 
                search={search}
                onChangeSearch={onChangeSearch}
                tableFilter={tableFilter}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                content={content.table}
                />
        </Page>
    )

}


export default Warehouses