import React from 'react'
import { Grid } from '@material-ui/core'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import ProductCheckoutList from './products/ProductCheckoutList'
import noproduct from '../../../../assets/empty.png'


const ProductsContainer = ({ listItems, content }) => {

    return(
        <SimpleCard>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>{content.title}</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {listItems.map((item,key)=>{
                                    return(
                                        <Grid item xs={12} key={key.toString()} >
                                            <ProductCheckoutList  {...item} />
                                        </Grid>
                                    )
                                })}
                                {listItems.length === 0 ? (
                                    <Grid item xs={12}>
                                        <div style={{padding:40}}>
                                            <Grid container justify='center' direction='column' alignItems='center'>
                                                <Grid item>
                                                    <img src={noproduct} alt='' width={150} />
                                                </Grid>
                                                <Grid item>
                                                    <DisplayText>{content.noproducts}</DisplayText>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>  
                    </Grid> 
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default ProductsContainer