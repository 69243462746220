import { Grid } from '@material-ui/core'
import React, {useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import ToolbarTable from '../../../../components/Structure/Navigation/ToolbarTable'
import DisplayText from '../../../../components/Texts/DisplayText'
import AddImage from '../components/AddImage'
import ImageBox2 from '../components/ImageBox2'
import ChangeImageModal from '../modals/ChangeImageModal'
import DeleteImageModal from '../modals/DeleteImageModal'
import UploadImageModal from '../modals/UploadImageModal'
import { request_update_main_image } from '../requests'
import { translations as translations_esp } from '../../../../texts/esp/product-translations'


const ProductImages = ({id, images, onUpdateProductCompleted, onUpdateImagesCompleted}) =>{

    const content = translations_esp.product_images

    //modals
    const [image_modal, setImagemodal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [selected_image, setSelectedImage] = useState(null)

    const onChangeMainImage = async(id_image) => {
        await request_update_main_image(id, id_image)
        onUpdateImagesCompleted()
        onUpdateProductCompleted()
    }

    const onDeleteImage = (id_image) => {
        setSelectedImage(id_image)
        setDeleteModal(true)
    }

    const onEditImage = (id_image) => {
        setSelectedImage(id_image)
        setEditModal(true)
    }

    //images web - mobile
    const menu = content.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id) => {
        if(id === 1) setType('web')
        if(id === 2) setType('mobile')
        setImageView(id)
    }

    let contentView = null

    switch(image_view){
        case 1:
            contentView = <Grid container spacing={3}>
                {images.map((item, index)=>{
                    return(
                        <Grid item key={index}>
                            <ImageBox2 id_image={item.id_product_image}
                            image={item.image_web} main={Boolean(item.main)} 
                            onChangeMain={()=>onChangeMainImage(item.id_product_image)} 
                            onEdit={()=>onEditImage(item.id_product_image)}
                            onDelete={()=>onDeleteImage(item.id_product_image)} 
                            content={content}/>
                        </Grid>
                    )
                })}
                <Grid item>
                    <AddImage onClick={()=>setImagemodal(true)}/>
                </Grid>
            </Grid>
            break
        case 2:
            contentView = <Grid container spacing={3}>
                {images.map((item, index)=>{
                    return(
                        <Grid item key={index}>
                            <ImageBox2 id_image={item.id_product_image}
                            image={item.image_mobile} main={Boolean(item.main)} 
                            onChangeMain={()=>onChangeMainImage(item.id_product_image)} 
                            onEdit={()=>onEditImage(item.id_product_image)}
                            onDelete={()=>onDeleteImage(item.id_product_image)} 
                            content={content}/>
                        </Grid>
                    )
                })}
                <Grid item>
                    <AddImage onClick={()=>setImagemodal(true)}/>
                </Grid>
            </Grid> 
            break
        default:
            break
    }

    

    return(
        <SimpleCard>
        <DeleteImageModal open={delete_modal} onClose={()=>setDeleteModal(false)} id={id} id_image={selected_image} onUpdateCompleted={onUpdateImagesCompleted}/>
        <UploadImageModal open={image_modal} onClose={()=>setImagemodal(false)} id={id} onUpdateCompleted={onUpdateImagesCompleted} type={type}/>
        <ChangeImageModal open={edit_modal} onClose={()=>setEditModal(false)} id={id} id_image={selected_image} type={type} 
        onUpdateCompleted={onUpdateImagesCompleted} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>{content.title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    {contentView}
                    <div style={{marginTop:12}}>
                    <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                    </div>
                </Grid>      
            </Grid>
        </SimpleCard>
    )

}

export default ProductImages 