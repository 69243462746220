import React, {useEffect, useState} from 'react'
import SimpleTable from './components/SimpleTable'
import AddProductModal from './modals/AddProductModal/AddProductModal'
import {request_faveproducts} from './requests'
import queryString from 'query-string'
import { CircularProgress, Grid } from '@material-ui/core'

const FaveProducts = props => {

    const { history } = props

    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [totalItems, setTotalItems] = useState(10)
    const [filter, setFilter] = useState({
        query:'',
    })
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'id_favorite_product',
    })
    const [search, setSearch] = useState(null)
  

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            onRequestModelData()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])


    const onRequestModelData = async() => {  
        const {limit, offset, order_by, order} = tableFilter
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}` 

        if(search){
            let filterArgs = [
                {"field":"product","operator":"LIKE","value":`%${search}%`},
            ]
            filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
            params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` 
        }

        const _users = await request_faveproducts(params)
        //console.log(_users.products_data)
        setProducts(_users.favorite_products_data)
        setTotalItems(_users.count)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onSelectedItem = (item) => {
        const {product_id} = item
        history.push(`/products/${product_id}`)
    }
  
    const onChangeFilter = (tag, value) => { 
        let newFilter = onUpdateFilter(tag, value)
        let newTableFilter = {...tableFilter}
        newTableFilter.offset = 0
        setFilter(newFilter)
        setTableFilter(newTableFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    }
 
    const onUpdateUsers = async() => {
        onRequestModelData()
        setAddModal(false)
    }

    return(
        <div>
            <AddProductModal open={addModal} onClose={() => setAddModal(false)} history={history} onRequestUsers={onUpdateUsers}/>
            {(products.length === 0  && totalItems === 10) ? 
            <Grid container justify='center'><Grid item ><CircularProgress color='primary'/></Grid></Grid> : 
            <SimpleTable
                data={products} 
                search={search}
                onChangeSearch={onChangeSearch}
                tableFilter={tableFilter}
                total={totalItems}
                loading={loading}
                //onAddRegister={() => setAddModal(true)}
                //onChangeFilter={onChangeFilter}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                />
            }
        </div>
    )
}

export default FaveProducts
