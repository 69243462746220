import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import { ColorPicker } from 'material-ui-color';
import { connect } from 'react-redux'
import * as actionTypes from '../../../../../store/actions'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard'
import Page from '../../../../../components/Structure/Page/Page'
import DisplayText from '../../../../../components/Texts/DisplayText'
import AvatarPicture from '../../../../../components/Structure/DisplayData/AvatarPicture'
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../../../shared/utility'
import { request_products, request_update_brand, request_brand } from './requests'
import ImageContainer from './components/ImageContainer'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import ChangeImageModal from './modals/ChangeImageModal'
import ToolbarTable from '../../../../../components/Structure/Navigation/ToolbarTable'
import { translations as translations_esp } from '../../../../../texts/esp/brand-translations'
import { catalogs } from '../../../../../texts/esp/catalogs'

const BrandView = (props) => {

    const content = translations_esp

    const {match, history} = props
    //global props
    const { onUpdateSending , onUpdateError, onUpdateSuccess } = props

    const id = match.params.id

    //general
    const [loading, setLoading] = useState(false)
    const [success, setSucess] = useState(null)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [color, setColor] = useState(null)
    const [brand, setBrand] = useState(null)
    const [products, setProducts] = useState([])

    //tables
    /*products*/
    const [table_loading, setTableLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:5, offset:0, order:0, order_by:'id_product'})
    const [search, setSearch] = useState(null)


    //modals
    const [image_modal, setImageModal] = useState(false)



    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _brand = await request_brand(id)
            setBrand(_brand.brand_data)
            setColor(_brand.brand_data.color)
            let _form = onInitEmptyForm(form, content.edit_card.form)
            _form = onInitForm(_form, _brand.brand_data)
            setForm(_form)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    useEffect(() => {
        const initModule = async() => {try {
            setTableLoading(true)
            const products_params = onGetParamsFromState(tableFilter, search, id)
            console.log(products_params)
            const _products = await request_products(products_params)
            console.log(_products)
            setProducts(_products.products_data)
            setTotal(_products.count)
            setTableLoading(false)
        } catch (error) {
            setTableLoading(false)
            console.log(error)
        }}
        initModule()
    }, [tableFilter, search])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateImageCompleted = async() => {
        const _category = await request_brand(id)
        setBrand(_category.brand_data)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, brand)
        data2send.color = color
        console.log(data2send)
        
        setSending(true)
        onUpdateSending(true)
        try {
            await request_update_brand(id, data2send);
            setSending(false)
            onUpdateSuccess(content.success_msg)
            //setSucess('Acción exitosa')
        } catch (error) {
            console.log(error)
            onUpdateError(onGetErrorMessage(error))
            //setError('Hubo un error')
            setSending(false)
        }
        onUpdateSending(false)
    }

    const onChangeColor = (data) =>{
        if(data && data.css){
            setColor(data.css.backgroundColor)
        }else{
            setColor(data)
        }    
        //console.log(data)
    }

    const onChangeLogo = () =>{
        setType('logo')
        setImageModal(true)
    }

    //image web - mobile
    const menu = content.edit_card.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id) => {
        if(id === 1) setType('web')
        if(id === 2) setType('mobile')
        setImageView(id)
    }

    let contentView = null

    switch(image_view){
        case 1:
            contentView = <ImageContainer image={brand ? brand.image_web : null} onClick={()=>setImageModal(true)}/>
            break
        case 2:
            contentView = <ImageContainer image={brand ? brand.image_mobile : null} onClick={()=>setImageModal(true)}/> 
            break
        default:
            break
    }
 

    return(
        <Page title={content.title} loading={loading} goback success={success} onCloseSuccess={() => setSucess(null)}>
            <ChangeImageModal id={id} open={image_modal} onClose={() => setImageModal(false)} type={type} 
            onUpdateCompleted ={onUpdateImageCompleted}/>
            {/* <DeleteSubcategoryModal open={delete_sub_modal} onClose={()=>setDeleteSubModal(false)} id={id_selected} 
            onUpdateCompleted={onUpdateSubcategoriesCompleted} /> */}
            <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                    <SimpleCard>
                        <Grid container spacing={4} justify='flex-end'>
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item>
                                        <AvatarPicture can_edit imgSrc={brand ? brand.logo : null} onChangePicture={onChangeLogo} />
                                    </Grid>
                                    <Grid item xs>
                                        <InputForm data={form.name} onChange={onChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                          
                                {contentView}                            
                                <div style={{marginTop:12}}>
                                <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                                </div>
                            </Grid>                     
                            <Grid item xs={12} >
                                <InputForm data={form.url} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <DisplayText variant='body2' color='textSecondary'>{content.edit_card.form.color.label}</DisplayText>
                                <ColorPicker value={color} disableAlpha onChange={onChangeColor} disablePlainColor/>
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.description} onChange={onChange} />
                            </Grid>
                            <Grid item>
                                <ContainedButton color='primary' loading={sending} onClick={onSubmit}>{content.edit_card.button}</ContainedButton>
                            </Grid>  
                        </Grid>
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <SimpleTable
                        data={products} 
                        tableFilter={tableFilter}
                        search={search}
                        total={total}
                        loading={table_loading}
                        onChangeSearch={onChangeSearch}
                        onUpdateTableFilter={(data) => setTableFilter(data)}
                        //onSelectedItem={onSelectedItem}
                        //onDeleteItem={onDeleteItem}
                        />  
                </Grid>
            </Grid>
        </Page>
    )

}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(BrandView)

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255',
            multiline:true,
            rows:3
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    /* color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'color',
            type: 'text',
            fullWidth: true,
            label: 'Color',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 500
        }
    },
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: catalogs.order_levels,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
}