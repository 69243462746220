import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components/Texts/DisplayText';
import { onGetCatalogById, onGetFormatNumber } from '../../../../shared/utility';
import { grey } from '@material-ui/core/colors';
import { ORDER_STATUS_TYPES, PAYMENT_STATUSES } from '../../../../variables/config';
import RoundedButton from '../../../../components/Actions/Buttons/RoundedButton';
import PaymentOption from './PaymentOption';
import paymentTypesAccepted from '../../../../assets/payment-cards.jpg'
import {request_mercadopago_payment_link, request_mercadopago_preference, request_mercadopago_payment, request_order} from '../requests'
import { catalogs } from '../../../../texts/esp/catalogs';

const OverviewOrder = ({order, onDispatchError, onUpdateOrder, onDispatchSending, content}) => {

    const classes = useStyles()

    const [payment_option, setPaymentOption] = useState(1)
    const [mp_link, setMpLink] = useState()
    const [mp_modal, setMpModal] = useState(false)
    const [cash_modal, setCashModal] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [delivery_modal, setDeliveryModal] = useState(false)

  
    const onCheckout = async() => {
        const {billing_address, shipping_address, line_items, user_id, _id} = order
        if(!user_id){
            onDispatchError('Por favor registre a un paciente')
            return
        }
        if(!billing_address){
            onDispatchError('Por favor ingrese una dirección de facturación')
            return
        }
        if(!shipping_address){
            onDispatchError('Por favor ingrese una dirección de envío')
            return
        }
        if(!line_items.length){
            onDispatchError('Por favor seleccione al menos un producto')
            return
        }

        onDispatchSending(true)
        setDisabled(true)
        if(payment_option === 1){ // Pago con tarjeta de credito
            const {payment} = order
            if(payment){
                const {token_id, payment_url, payment_id, gateway} = payment
                if(gateway === 'mercadopago'){
                    const _payment = await request_mercadopago_payment(_id) //Check if there is a valid payment, watch out! Pending or rejected payments has not beeen updated
                    if(_payment.results.length === 0){
                        const mercadopago_link =  await onCheckMercadoPagoPreference(order)
                        setMpLink(mercadopago_link)
                        setMpModal(true)
                    }else{
                        const last_item = _payment.results.length - 1
                        const payment_info = _payment.results[last_item]
                        if(payment_info.status === 'rejected' || payment_info.status === 'in_process'){
                            const mercadopago_link = await onCheckMercadoPagoPreference(order)
                            setMpLink(mercadopago_link)
                            setMpModal(true)
                        }
                    }
                }
                
            }else{
                console.log('Proceder a generar link de compra')
                const mercardoPagoLink = await request_mercadopago_payment_link(order._id)
                const _order = await request_order(order._id)
                onUpdateOrder(_order)
                setMpLink(mercardoPagoLink)
                setMpModal(true)
            }
            
        }
        if(payment_option === 2) setCashModal(true)
        onDispatchSending(false)
        setDisabled(false)


    }


    const onCheckMercadoPagoPreference = async(order) => {
        try {
            const {token_id, payment_url, payment_id, gateway} = order.payment
            const _preference = await request_mercadopago_preference(token_id) // If not payment, review token_id
            if(_preference.items.length){
                if(parseInt(_preference.items[0].unit_price) === parseInt(order.total)){ // If there is no change, one can reuse the token
                    return payment_url
                }else{ // There is a change, a new token muts be generated
                    const mercardoPagoLink = await request_mercadopago_payment_link(order._id) 
                    const _order = await request_order(order._id)
                    onUpdateOrder(_order)
                    return mercardoPagoLink
                }
            }
        } catch (error) {
            console.log('Hubo un error')
        }
    }


    let subtotal; let status_id; let total; let shipping_cost; let taxes
    let discount; let payment_status_id; let payment_type_id
    if(order){
        console.log(order)
        subtotal = order.payment_subtotal; 
        status_id = order.order_status_id; 
        total = order.payment_total
        shipping_cost = order.shipping_cost
        discount = order.code_discount
        taxes = order.payment_taxes
        payment_status_id = order.payment_status_id
        payment_type_id = order.cart ? order.cart.payment_type_id : -1
        //payment_type_id = 1
    }


    let checkoutButton = null

    if(status_id === ORDER_STATUS_TYPES.PENDING){
        checkoutButton = (
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1'>Forma de pago</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <PaymentOption label='Pago con Tarjeta de Crédito/Debito' image={{src:paymentTypesAccepted, width:200}}
                        selected={payment_option === 1} onChange={() => setPaymentOption(1)} />
                    </Grid>
                    <Grid item xs={12}>
                        <PaymentOption label='Pago en efectivo' 
                        selected={payment_option === 2} onChange={() => setPaymentOption(2)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <RoundedButton disabled={disabled} color='primary' fullWidth onClick={onCheckout}>Procesar pago</RoundedButton>
                    </Grid>
                </Grid>
            </Grid>
            
        )
    }

    

    return ( 
        <div>
            {/* <CashPaymentModal open={cash_modal} onClose={() => setCashModal(false)} order={order} onUpdateCompleted={onUpdateOrder}/>
            <MercadoPagoModal open={mp_modal} onClose={() => setMpModal(false)} url={mp_link}/>
            <DeliveryModal open={delivery_modal} onClose={()=>setDeliveryModal(false)} data={line_items} /> */}
            <SimpleCard>
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayText  variant='subtitle1' style={{marginBottom:16}}>{content.title}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <DataInfo label={content.subtotal} data={onGetFormatNumber(subtotal)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DataInfo label={content.discount} data={`-${onGetFormatNumber(discount)}`}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DataInfo label={content.taxes} data={onGetFormatNumber(taxes)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DataInfo label={content.shipping_cost} data={onGetFormatNumber(shipping_cost)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DataInfo label={content.total} data={onGetFormatNumber(total)} bold/>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <div style={{marginTop:14, display:'flex', justifyContent:'flex-end'}}>
                            <Button size='small' color='primary' disabled={total === 0 ? true : false } onClick={()=>setDeliveryModal(true)}
                            >Ver tiempos de entrega</Button>
                        </div>
                    </Grid> */}
                    { (payment_status_id !== null && payment_status_id !== PAYMENT_STATUSES.PENDING) ?
                    <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.divider}/>
                    </Grid>       
                    <Grid item xs={12}><DataInfo label={content.payment_type} data={onGetCatalogById(catalogs.payment_types, payment_type_id)} bold/></Grid>
                    </Grid>
                    : null}
                </Grid>
            </SimpleCard>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    divider:{
        margin:'24px 0px',
        borderTop:`1px solid ${grey[300]}`
    }
}))
 
export default OverviewOrder;

const DataInfo = ({label, data, bold}) => {
    return(
        <Grid container wrap='nowrap'>
            <Grid item xs>
                <DisplayText color='textSecondary' variant={bold ? 'subtitle1' : 'body1'}>{label}</DisplayText>
            </Grid>
            <Grid item >
                <DisplayText  variant={bold ? 'subtitle1' : 'body1'}>{data}</DisplayText>
            </Grid>
        </Grid>
    )
}