import { Button, Grid } from '@material-ui/core'
import React from 'react'
import SimpleCard from '../../../components/Structure/Cards/SimpleCard'
import AvatarPicture from '../../../components/Structure/DisplayData/AvatarPicture'
import DisplayIconData from '../../../components/Structure/DisplayData/DisplayIconData'
import DisplayText from '../../../components/Texts/DisplayText'
import { onGetFullname } from '../../../shared/utility'
import nouser from '../../../assets/nouser.png'


const UserInfoCard = (props) => {

    const {image, first_name, fathers_last_name, mothers_last_name, email, mobile, onChangePicture, onChangePassword, 
        content} = props

    const last_name = onGetFullname(fathers_last_name, mothers_last_name)
    const fullname = onGetFullname(first_name, last_name)

    
    return(
        <div>
            <SimpleCard>
                <Grid container justify='center' spacing={2}>
                    <Grid item>
                        <AvatarPicture can_edit nopicture={nouser} onChangePicture={onChangePicture} imgSrc={image}/>
                    </Grid>              
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1' align='center'>{fullname}</DisplayText>
                    </Grid>                  
                    <Grid item xs={12}>
                        <div style={{wordWrap:'break-word', wordBreak:'break-all'}}>
                            <DisplayIconData icon='mail' data={email}/>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayIconData icon='phone' data={mobile}/>
                    </Grid>
                    <Grid item>
                        <Button onClick={onChangePassword} variant='text'>{content.button}</Button>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}

export default UserInfoCard