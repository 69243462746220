import { success_msg } from "./general-translations";

export const translations = {
    title:'Subsegmento',
    success_msg:success_msg,
    edit_card:{
        menu:[{id:1, label:'WEB'}, {id:2, label:'MOBILE'}],
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            color:{
                label:'Color',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            description:{
                label:'Descripción',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            order:{
                label:'Orden',
                helperText:'Seleccione una opción'
            },
            url:{
                label:'Url',
                helperText:'Este campo es obligatorio'
            }
        },
        button:'Guardar',
    },
    tables: {
        //menu:[{id:1, label:'Productos'}, {id:2, label:'Segmentos'}],
        products:{
            header:[
                {id:1, label:''},
                {id:2, label:'ID'},
                {id:3, label:'Producto'},
                {id:4, label:''},
            ],
            nodata:'No existen productos asociados a este subsegmento',
            add_modal:{
                title:'Agregar un producto al subsegmento',
                button:'Agregar',
                caption:'Si no encuentra el medicamento deseado, asegúrese de que está dado de alta en el segmento correspondiente',
                form:{
                    product_id:{
                        label:'Productos',
                        helperText:'Debe elegir una opción'
                    }
                }
            }
        },
        /* segments:{
            header:[
                {id:1, label:'ID'},
                {id:2, label:'Segmento'},
                {id:3, label:''},
            ],
            nodata:'No existen segmentos asociados a esta subcategoría',
            add_modal:{
                title:'Agregar segmento',
                button:'Agregar',
                form:{
                    name:{
                        label:'Nombre',
                        helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                    },
                    color:{
                        label:'Color',
                        helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                    },
                    description:{
                        label:'Descripción',
                        helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                    },
                }
            }
        } */
    },
    
}

