import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { request_create_user } from '../../requests'
import SelectForm from '../../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../../texts/esp/catalogs'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'


const AddUserModal = props => {

    const { open, onClose, onRequestUsers, history, content } = props

    const classes = useStyles()

    //general
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //checks
    const [indigenous_check, setIndigenousCheck] = useState(false)

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            let _form = onInitEmptyForm(form, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.passwordConfirmation
        data2send.timezone = "America/Mexico_City"
        data2send.language_id = 1
        data2send.user_type_id = 1
        //data2send.mobile_country_id = 1
        data2send.currency_id = 2
        data2send.indigenous = indigenous_check ? 1 : 0

        //console.log(data2send)

        setLoading(true)
        setDisabled(true)
        try {
            const _newUser = await request_create_user(data2send);
            //console.log(_newUser)
            onRequestUsers()
            history.push(`/users/${_newUser.user_id}`)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.titles.users}</DisplayText>
                <DisplayText >{content.subtitle}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.fathers_last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.mothers_last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControlLabel
                                    control={<Checkbox checked={indigenous_check} onChange={()=>setIndigenousCheck(!indigenous_check)} />}
                                    label={content.formControl.indigenous}
                                />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.user_gender_id} onChange={onChange} />
                        </Grid> 
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.zip} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.password} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.passwordConfirmation} onChange={onChange} />
                        </Grid>    
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddUserModal


const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    fathers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido paterno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    mothers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido materno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha de nacimiento',
            helperText: 'Este campo debe contener una fecha válida'
        },
        rules: {
            type: 'date',
        }
    },
    user_gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'user_gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
        },
        rules: {
            type: 'email',
        }
    },
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label: 'Contraseña',
            helperText: 'Debe contener 6 a 20 caracteres',
            autoComplete:'new-password'
        },
        rules: {
            type: 'distance',
            min:6, max:20
        }
    },
    passwordConfirmation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'passwordConfirmation',
            type: 'password',
            fullWidth: true,
            label: 'Validar contraseña',
            helperText: 'Las contraseñas no coinciden'
        },
        rules: {
            type: 'equals',
        }
    },
    pin: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'pin',
            type: 'text',
            fullWidth: true,
            label: 'PIN',
            helperText: 'El pin debe estar conformado por 4 números',
        },
        rules: {
            type: 'distance',
            min: 4, max: 4
        }
    },
    zip: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'text',
            fullWidth: true,
            label: 'Código postal',
            helperText: 'Código postal no válido'
        },
        rules: {
            type: 'zip',
        }
    },    
}