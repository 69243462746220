export const ADD_SESSION = 'ADD_SESSION'

export const CHANGE_AUTH_STATUS = 'CHANGE_AUTH_STATUS' 
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER'
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS'
export const UPDATE_APOLLO_CLIENT = 'UPDATE_APOLLO_CLIENT'

/// AUTHENTIFICATION
export const AUTH_RECOVER_PASSWORD_MODAL = 'AUTH_RECOVER_PASSWORD_MODAL'

export const UPDATE_SENDING = 'UPDATE_SENDING'
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
export const UPDATE_ERROR = 'UPDATE_ERROR'

export const actionTypes = {
    AUTH_UPDATE_USER: 'AUTH_UPDATE_USER',
    AUTH_UPDATE_STATUS: 'AUTH_UPDATE_STATUS' ,
    
    SYS_UPDATE_DRAWER_STATUS: 'SYS_UPDATE_DRAWER_STATUS',
    SYS_UPDATE_IP_ADDRESS: 'SYS_UPDATE_IP_ADDRESS',
    SYS_UPDATE_DRAWER: 'SYS_UPDATE_DRAWER',
    SYS_UPDATE_BLUR: 'SYS_UPDATE_BLUR',
    SYS_UPDATE_TOKEN:'SYS_UPDATE_TOKEN',
    SYS_UPDATE_SESSION_STATUS:'SYS_UPDATE_SESSION_STATUS',
    SYS_UPDATE_ERROR:'SYS_UPDATE_ERROR',
    SYS_UPDATE_SENDING:'SYS_UPDATE_SENDING',
    SYS_UPDATE_SUCCESS:'SYS_UPDATE_SUCCESS',
}
