import { mainServer, BASIC_AUTH_HEADER } from '../../variables/config';

/* export const request_recover_password = async(data) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  await mainServer.post(`/login/recoverpassword`, data)
} */

export const request_recover_password = async(data) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  await mainServer.post(`/login/admin/recoverpassword`, data)
}
