import { Hidden } from '@material-ui/core'
import React, {useState} from 'react'
import PageMenuMobile from '../../components/Structure/Navigation/PageMenuMobile'
import ToolbarTable from '../../components/Structure/Navigation/ToolbarTable'
import Page from '../../components/Structure/Page/Page'
import { translations as translations_esp } from '../../texts/esp/banners_menu-translations'
import PromotionalBanners from './PromotonialBanners/PromotionalBanners'
import StoreBanners from './StoreBanners/StoreBanners'
import TelemedicineBanners from './TelemedicineBanners/TelemedicineBanners'


const BannersMenu = (props) => {

    const content = translations_esp

    const {history} = props

    const [view, setView] = useState(1)
  
    const menu = content.menu

    const onChangeView = (id) => {
        /* let urlwithargs = '/catalogs'
        if(id === 3) urlwithargs = '/catalogs/category'
        if(id === 4) urlwithargs = '/catalogs/subcategory'
        if(id === 5) urlwithargs = '/catalogs/segment'
        window.history.replaceState(null, null, urlwithargs); */ 
        setView(id)
    }
        
    let contentView = null

    switch(view){
        case 1:
            contentView = <StoreBanners history={history} />
            break
        case 2:
            contentView = <PromotionalBanners />
            break
        case 3:
            contentView = <TelemedicineBanners />
            break
        default:
            break
    }

    return(
        <Page title={content.title}>
        <Hidden smDown>
            <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
        </Hidden>
        <Hidden mdUp>
            <PageMenuMobile selected={view} options={menu} onChange={onChangeView} />
        </Hidden>
        <div style={{marginTop:12}}>
            {contentView}
        </div>
        </Page>
    )
}

export default BannersMenu