import { success_msg } from "./general-translations"

const default_select_helperText = 'Debe elegir una opción'
const default_input_helperText = 'Este campo debe de contener al menos un caracter'

export const translations = {
    titles:{
        user:'Información de usuario',
        doctor:'Información de doctor',
    },
    success_msg:success_msg,
    bio_error_msg:'Este campo debe contener al menos un caracter',
    cards:{
        general_info:'Información general',
        contact_info:'Información de contacto',
        add_info:'Información adicional',
        button:'Guardar',
        change_password:'Cambiar contraseña',
        personal_info:'Información personal',
        medical_info:'Información médica'
    },
    modals:{
        update_password:{
            title:'Cambio de contraseña',
            subtitle:'Por favor ingresa los datos solicitados',
            button:'Actualizar',
            success_msg:'Contraseña actualizada',
        },
    },
    menu:[{id:1, label:'Información general'}, {id:2, label:'Información médica'}],
    form:{
        //genral info
        first_name: {
            label: 'Nombre(s)',
            helperText: default_input_helperText
        },
        fathers_last_name: {
            label: 'Apellido paterno',
            helperText: default_input_helperText
        },
        mothers_last_name: {
            label: 'Apellido materno',
            helperText: default_input_helperText
        },
        birth_date: {
            label:'Fecha de nacimiento',
            helperText: 'Fecha no válida'
        },
        user_gender_id: {
            label: 'Género',
            helperText: default_select_helperText
        },
        curp: {
            label: 'CURP',
            helperText: 'CURP no válida'
        },

        //contact info
        email: {
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
        },
        mobile_country_id: {
            label: 'Código',
            helperText: default_select_helperText
        },
        mobile: {
            label: 'Teléfono celular',
            helperText: 'Número de teléfono no válido',
        }, 
        fixed_phone_country_id: {
            label: 'Código',
            helperText: default_select_helperText
        },
        fixed_phone: {
            label: 'Teléfono fijo',
            helperText: 'Número de teléfono no válido',
        },    
        zip: {            
            label: 'Código postal',
            helperText: 'Código postal no válido'        
        },  
        city: {            
            label: 'Ciudad',
            helperText: default_input_helperText        
        }, 
        
        //additional info
        blood_type_id: {
            label: 'Tipo de sangre',
            helperText: default_select_helperText
        },
        civil_status_id: {
            label: 'Estado civil',
            helperText: default_select_helperText
        },
        occupation: {
            label: 'Ocupación',
            helperText: default_input_helperText
        },
        scolarity_id: {
            label: 'Escolaridad',
            helperText: default_select_helperText
        },
        religion: {
            label: 'Religión',
            helperText: default_input_helperText
        },
        ethnic_group: {
            label: 'Grupo étnico',
            helperText: default_input_helperText
        },
        ethnic_language: {
            label: 'Dialecto',
            helperText: default_input_helperText
        },
        currency_id: {
            label: 'Moneda',
            helperText: default_select_helperText
        },
    },
    form_medical:{
        speciality_id: {
            label: 'Especialidad',
            helperText: default_select_helperText
        },
        sub_speciality_id: {
            label: 'Subespecialidad',
            helperText: default_select_helperText
        },
        license: {
            label: 'Cédula profesional',
            helperText: default_input_helperText
        },
        language: {
            label: 'Seleccione el idioma',
            helperText: default_select_helperText
        },
        qualifications: {
            label: 'Curriculum',
            helperText: default_input_helperText
        },
        bio: {
            label: 'Biografía',
            helperText: default_input_helperText
        },

    }
}

