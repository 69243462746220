import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import DeleteOrderModal from './modals/DeleteOrderModal'
import AddOrderModal from './modals/AddOrderModal'
import { USER_TYPES } from '../../../variables/config'
import { s2ab } from '../../../shared/utility'
import { onGetParamsFromState, onCleanData2Excel } from './custom_functions'
import {request_orders, request_orders_report} from './requests'
import { request_update_order } from '../OrdersView/requests'


const Orders = props => {

    const { history, user } = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [orders, setOrders] = useState([])
    
    //table
    const [totalItems, setTotalItems] = useState(10)
    const [search, setSearch] = useState(null)
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'ordered_at',
    })

    //modals
    const [add_order_modal, setAddOrderModal] = useState(false)
    const [delete_order_modal, setDeleteOrderModal] = useState(false)
    const [add_modal, setAddModal] = useState(false)
    const [selected_product, setSelectedProduct] = useState(null)

    //filters
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
    const [status, setStatus] = useState(-1)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            await onGetListItems()
        } catch (error) {
            console.log(error)
        }setLoading(false)}  
        
        initModule()
    }, [search, tableFilter, user, startDate, endDate])

    const onGetListItems = async() => {
        try {
            if(user){
                let _orders = []
                const params = onGetParamsFromState(tableFilter, search)
                //params.start_date = startDate
                //params.end_date = endDate
                //nuevo
                //if(status !== -1) params.status_id = status 
                _orders = await request_orders(params)
                console.log(_orders)
                setOrders(_orders.orders)
                setTotalItems(_orders.count)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const onSelectedItem = (item) => {
        const {id_order} = item
        history.push(`/orders/${id_order}`)
    }
  
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    /* const onUpdateUsers = async() => {
        const params = onGetParamsFromState(filter, tableFilter)
        const _users = await request_orders(params)
        setProducts(_users.data)
        setTotalItems(_users.count)
        setAddModal(false)
    } */

    const onDeleteItem = (data) => {
        setSelectedProduct(data)
        setDeleteOrderModal(true)
    }

    const onUpdateCompleted = async() => {
        try {
            await onGetListItems()
            setDeleteOrderModal(false)
        } catch (error) {
            console.log(error)
        }
    }

    const onUpdateOrder = async(id, data) => {
        try {
            setLoading(true)
            //console.log(data)
            //console.log(id)
            await request_update_order(id, data)
            let _users = []
            console.log(user._id)
            const params = onGetParamsFromState(tableFilter, search)
            params.start_date = startDate
            params.end_date = endDate
            //nuevo
            //let params = {limit:tableFilter.limit, offset:tableFilter.offset*tableFilter.limit, order_by:'created_at', order:-1, start_date:startDate, end_date:endDate, query:filter.query, status_id:status}
            if(status !== -1) params.status_id = status
            if(user.user_type_id === USER_TYPES.ADMIN) _users = await request_orders(params)
            if(user.user_type_id === USER_TYPES.OPTICAL) _users = await request_orders({...params, optical_id:user._id})
            if(user.user_type_id === USER_TYPES.OPTOMETRIST) _users = await request_orders({...params, optical_id:user.optical_id}) 
            console.log(_users.data)
            setOrders(_users.data)
            setTotalItems(_users.count)
            //const _order = await request_order(order_id)
            //setOrder(_order)

        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    const onChangeDate = (value, id) => {
        let aux
        if(id === 'start_date'){
            aux = moment(value)
            console.log()
            if(moment(endDate).diff(aux) > 0) setStartDate(value)
        }
        if(id === 'end_date'){
            aux = moment(value)
            if(aux.diff(moment(startDate)) > 0) setEndDate(value)
        }
    }

    const onGenerateReport = async() => {
        try {
            setSending(true)
            const params = onGetParamsFromState(tableFilter, search)
            params.start_date = startDate
            params.end_date = endDate
            delete params.query; delete params.limit; delete params.offset;
            if(status !== -1) params.status_id = status
            //let _users = []
            let _users = await request_orders_report(params)
            console.log(_users.data)
            const excel_data = onCleanData2Excel(_users.data)
            console.log(excel_data)
            const sheets = [
                {name:'Reporte', data:excel_data}
            ]
    
            let wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Ubelens Report",
                Subject: "Detailed Data",
                Author: "Trionix Technologies",
                CreatedDate: new Date()
            };
    
            for(let i=0; i<sheets.length; i++){
                wb.SheetNames.push(sheets[i].name);
                const temp = XLSX.utils.aoa_to_sheet(sheets[i].data)
                wb.Sheets[sheets[i].name] = temp;
            }
    
            let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'report.xlsx');
        } catch (error) {
            
        }
        setSending(false)

    }


    let can_add = true

    let report_available = false
    if(user && user.user_type_id === USER_TYPES.ADMIN) report_available = true

    return(
        <Page title='Pedidos' sending={sending}>
            <DeleteOrderModal onUpdateCompleted={onUpdateCompleted} selected_item={selected_product} open={delete_order_modal} onClose={()=>setDeleteOrderModal(false)}/>
            <AddOrderModal history={history} user={user} open={add_order_modal} onClose={() => setAddOrderModal(false)} />
            <SimpleTable
                data={orders} 
                search={search}
                tableFilter={tableFilter}
                total={totalItems}
                loading={loading}
                onAddRegister = {can_add ? () => setAddOrderModal(true) : null}
                onGenerateReport={report_available ? onGenerateReport : null}
                onChangeSearch={onChangeSearch}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                onUpdateOrder={onUpdateOrder}
                onChangeDate={onChangeDate}
                endDate={endDate}
                startDate={startDate}
                isadmin={report_available}
                status={status}
                onDeleteItem={onDeleteItem}
            />
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(Orders)