import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import * as actionTypes from '../../../../store/actions'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import AddTagModal from './modals/AddTagModal'
import EditTagModal from './modals/EditTagModal'
import { request_tags } from './requests'

const TagsView = ( props ) => {

    const { onUpdateSending , onUpdateError, onUpdateSuccess } = props

    //general
    const [loading, setLoading] = useState(false)
    const [tags, setTags] = useState([])

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)

    const [id_selected, setIdSelected] = useState(null)

    //table
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_tag'})
    const [search, setSearch] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            window.scrollTo({top: 0, behavior: 'smooth'})
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            console.log(params)
            const _tags = await request_tags(params)
            setTags(_tags.tags_data)
            setTotal(_tags.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])

    
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(tableFilter, search)
        const _tags = await request_tags(params)
        setTags(_tags.tags_data)
        setTotal(_tags.count)
        setAddModal(false)
        setEditModal(false)
    }

    const onSelectedItem = (item) => {
        setIdSelected(item.id_tag)
        setEditModal(true)
    }


    return(
        <div>
            <AddTagModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted} />
            <EditTagModal open={edit_modal} onClose={() => setEditModal(false)} id={id_selected} 
            onUpdateCompleted={onUpdateCompleted} onUpdateError={onUpdateError} onUpdateSuccess={onUpdateSuccess} 
            onUpdateSending={onUpdateSending}/>
            <SimpleTable
                data={tags} 
                tableFilter={tableFilter}
                search={search}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeSearch={onChangeSearch}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
            />
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(TagsView)