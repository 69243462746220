import React, {useEffect, useState} from 'react'
import SimpleTable from './SimpleTable'
import queryString from 'query-string'
import AddModal from '../modals/AddModal'
import DeleteModal from '../modals/DeleteModal'
import { request_increment_inventory, request_inventory_warehouse } from '../requests'
import EditAvailabilityModal from '../modals/EditAvailabilityModal'

const Inventory = ({id, content, history}) => {

    const [loading, setLoading] = useState(false)
    const [inventory, setInventory] = useState([])

    //table
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({
        limit:5,
        offset:0,
        order:1,
        order_by:'id_inventory',
    })
    const [search, setSearch] = useState(null)

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [selected, setSelected] = useState(null)
   

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            onRequestModelData()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])


    const onRequestModelData = async() => {  
        const {limit, offset, order_by, order} = tableFilter
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}` 

        if(search){
            let filterArgs = [
                {"field":"name","operator":"LIKE","value":`${search}`},
            ]
            filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
            params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` 
        }

        const _inventory = await request_inventory_warehouse(id, params)
        console.log(_inventory)
//*
        setInventory(_inventory.inventories_data)
        setTotal(_inventory.count)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onSelectedItem = (item, type) => {
//*
        const {product_id, id_inventory} = item
        if(type === 'edit') {
            setSelected(product_id)
            history.push(`/products/${product_id}`)
        }
        if(type === 'delete') {
            setSelected(id_inventory)
            setDeleteModal(true)
        }
        if(type === 'edit_availability'){
            setSelected(id_inventory)
            setEditModal(true)
        }
    }

    const onUpdateCompleted = async() => {
        onRequestModelData()
        setDeleteModal(false)
    }

    const onIncrement = async(item) =>{
        const { id_inventory } = item
        try{
            await request_increment_inventory(id_inventory, {increment:1})
            onUpdateCompleted()
        }catch(error){
            console.log(error)
        }
    }

    return(
        <div>
            <AddModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted}
            content={content.modal} id={id}/>
            <EditAvailabilityModal open={edit_modal} onClose={() => setEditModal(false)} onUpdateCompleted={onUpdateCompleted}
            content={content.modal} id={selected}/>
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} 
            id={selected}/>
            <SimpleTable
                data={inventory} 
                search={search}
                onChangeSearch={onChangeSearch}
                tableFilter={tableFilter}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                content={content.table}
                onIncrement={onIncrement}
                />
        </div>
    )

}

export default Inventory