import React from 'react'
import { TextField, Typography } from '@material-ui/core'
import { ruleValidation } from './customFunctions'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const RichTextForm = props => {

    const {label, value, data, onChange, imgIcon, onKeyPress} = props

    const onInnerChange = (event, editor) => {

        const response = editor.getData()
        //console.log(response)
        //let temp = {...data};
        //temp.value = response;
        //temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        //temp.isVisited = true;
        onChange(response);
    } 

    //const {config, value, isValid, isVisited} = data
//
    //const isError = isVisited && !isValid
    //let interConfig = {...config}
    //if(!isError) interConfig.helperText = ''
//
    //console.log(value)*/

    return(
        <div>
           <Typography variant='body2' color='textSecondary'>{label}</Typography>
            <div style={{height:'300px', overflowY:'scroll', marginTop:'4px'}}>
                {value !== null ? <CKEditor
                    id={`rt-${label}`}
                    className='ck-editor__editable' 
                    editor={ClassicEditor}
                    onError={() => console.log('Hubo un error en ckeditr')}
                    onReady={() => console.log('Estuvo listo')}
                    data={value}
                    onChange={onInnerChange}
                    config={{toolbar:{removeItems:['imageUpload' , 'insertMedia' ]}}}                                              
                /> : null}
            </div>
        </div>
    )
}

export default RichTextForm