import React from 'react'
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import SearchBar from '../../../../../../components/Actions/SearchBar'
import ActionBar from '../../../../../../components/Actions/ActionBar'
import empty from '../../../../../../assets/empty.png'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import { translations as translations_esp } from '../../../../../../texts/esp/categories-translations'
import { Link } from 'react-router-dom'
import TableCellLink from '../../../../../../components/Structure/Navigation/TableCellLink'
import { nodata } from '../../../../../../variables/config'

const content = translations_esp

const header = content.table.header


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }, 
    link:{
        textDecoration:'none',
        color:'black'
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading} = props

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_category, name, description, color, order} = item

                                    return(
                                        <TableRow key={key.toString()} className={classes.row}>
                                            <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText>{id_category ? id_category : nodata}</DisplayText></TableCell>
                                            <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText>{name ? name : nodata}</DisplayText></TableCell>
                                            <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText>{order ? order : nodata}</DisplayText></TableCell>
                                            <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText>{description ? description : nodata}</DisplayText></TableCell>
                                            <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText>{color ? color : nodata}</DisplayText></TableCell>
                                            <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell} padding='checkbox'>
                                                <IconButton onClick={() => onSelectedItem(item, 'delete')}><Icon>delete</Icon></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}       
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.table.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>           
            ) : null}       
        </div>
    )
}

export default SimpleTable