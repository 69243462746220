import React, {useState, useEffect} from 'react'
import { createMuiTheme, Hidden, makeStyles, MuiThemeProvider } from '@material-ui/core'
import Topbar from '../Topbar/Topbar'
import { withRouter } from 'react-router-dom'
import SingInModal from '../../../modals/SignInModal/SingInModal'
import ForgottenPasswordModal from '../../../modals/ForgottenPasswordModal/ForgottenPasswordModal'
import { isTokenValid } from '../../../shared/utility'
import {connect} from 'react-redux'
import * as actionTypes from '../../../store/actions'
import SignUpModal from '../../../modals/SignUpModal/SignUpModal'
import { request_profile } from './requests'
import Sidebar from '../Sidebar/Sidebar'
import { theme } from '../../../theme'


const useStyles = makeStyles(theme => ({
    root:{
        background:'#f6f7fb',//'#f1f5f8',//'#f8f8f8',//'#f4f6f8',
        
    },
    subroot:{
        minHeight:'100vh',
        paddingTop:100,
        marginLeft:134,
        //background:'red'
        //add by me
        [theme.breakpoints.down('sm')]: {
            marginLeft:0,
          },
        [theme.breakpoints.up('sm')]: {
            marginLeft:134,
          },
    }
}))

const Frame = props => {


    const classes = useStyles()
    const [modals, setModals] = useState({
        signin:false,
        signup:false,
        forgottenpassword:false
    })
    const {isauth, cartItems, history} = props
    const {onChangeAuthStatus, onUpdateCartItems, onUpdateUser, onUpdateRecoverPasswordModal} = props

    //added by me
    const [sideBar, setSideBar] = useState(false)

    useEffect(() => {
        const initModule = async() => {try {
            console.log('Incializar')
            const validToken = isTokenValid()
            onChangeAuthStatus(validToken)
            if(validToken){
                const user = await request_profile()
                onUpdateUser(user)
            }else{
                history.push('/signin')
            }
        } catch (error) {
            console.log(error)
        }}
        initModule()
    }, [])

    const onOpenModal = (item) => {
        console.log(item)
        if(item === 'signin' || item === 'signup' || item === 'forgottenpassword'){
            let temp = {...modals}
            temp[item] = true
            setModals(temp)
            return
        }
        if(item === 'profile') history.push('/profile')
        if(item === 'orders') history.push('/orders')
        if(item === 'forgottenpassword') onUpdateRecoverPasswordModal(true)

    }

    const onCloseModal = (item) => {
        let temp = {...modals}
        temp[item] = false
        setModals(temp)
    }

    const onChangeModal = (from, to) => {
        let temp = {...modals}
        temp[from] = false
        temp[to] = true
        setModals(temp)
    }

    const onTriggerSideBar = () => {
        setSideBar(!sideBar);
    }



    return(
        <MuiThemeProvider theme={theme}>
            <div className={classes.root} >
                <SingInModal open={modals.signin} onClose={onCloseModal} onOpenModal={onOpenModal}/>
                <SignUpModal open={modals.signup} onClose={onCloseModal} onOpenModal={onOpenModal} onChangeModal={onChangeModal}/>
                <ForgottenPasswordModal open={modals.forgottenpassword} onClose={onCloseModal}/>
                <Hidden xsDown>
                    <Sidebar 
                        variant="permanent"
                        open={true}
                    />
                    
                </Hidden> 
                <Topbar sideBarHandler={onTriggerSideBar} />
                <Hidden smUp>
                    <Sidebar 
                        variant="temporary"
                        open={sideBar}
                        closeSide={onTriggerSideBar}
                    />
                </Hidden>
                <div className={classes.subroot}>
                    {props.children}
                </div>
            </div>
        </MuiThemeProvider>
        
    )

    
    
}

const mapStateToProps = state => {
    return {
        isauth: state.isauth,
        cartItems: state.cartItems,
        recover_password_modal:state.recover_password_modal,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChangeAuthStatus: (isauth) => dispatch({type: actionTypes.CHANGE_AUTH_STATUS, isauth }),
        onUpdateCartItems: (cartItems) => dispatch({type: actionTypes.UPDATE_CART_ITEMS, cartItems }),
        onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateRecoverPasswordModal: (recover_password_modal) => dispatch({type: actionTypes.AUTH_RECOVER_PASSWORD_MODAL, recover_password_modal }),

    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Frame))