import React from 'react'
import { Avatar,  Grid, makeStyles } from '@material-ui/core'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import user_image from '../../../../assets/nouser.png'
import { grey } from '@material-ui/core/colors'
import { nodata } from '../../../../variables/texts'


const UserInfoCard = (props) => {

    const { user, email, mobile, content } = props
    const classes = useStyles()

    /* //modals
    const [add_patient_modal, setAddPatientModal] = useState(false)
    const [select_address_modal, setSelectAddressModal] = useState(false)


    const onDeletUser = () => {
        if(userType === USER_TYPES.ADMIN) onUpdateOrder({user_id:null, optical_id:null, optometrist_id:null})
        if(userType !== USER_TYPES.ADMIN) onUpdateOrder({user_id:null })
    }

    const onAddUser = (user_id) => {
        onUpdateOrder({user_id:user_id})
        setAddPatientModal(false)
        setSelectAddressModal(true)
    }  */   
  
    return(
        <div>
            <SimpleCard> 
                <div>
                    {/* <SelectAddressModal open={select_address_modal} onClose={()=>setSelectAddressModal(false)} patient={user} basic_optical={optical} 
                    onUpdateAddressCompleted={onUpdateAddressCompleted} orderID={orderID} />  */}      
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container alignItems='center'>
                                <Grid item xs>
                                    <DisplayText variant='subtitle1'>{content}</DisplayText>
                                </Grid>
                                {/* {status_id === ORDER_STATUS_TYPES.PENDING ? <Grid item><IconButton size='small' onClick={onDeletUser}><Icon>close</Icon></IconButton></Grid> : null} */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <Grid container wrap='nowrap' spacing={1}>
                                    <Grid item>
                                        <Avatar scr={user_image} alt='' className={classes.avatar}/>
                                    </Grid>
                                    <Grid item xs>
                                        <DisplayText variant='body2'>{user ? user : nodata}</DisplayText>
                                        <DisplayText variant='body2' color='textSecondary'>{email ? email : nodata}</DisplayText>
                                        <DisplayText variant='body2' color='textSecondary'>{mobile ? mobile : nodata}</DisplayText>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </SimpleCard>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    avatar:{
        width:48,
        height:48,
        border:`1px solid ${grey[300]}`
    }
}))

export default UserInfoCard