import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './style.css'
import { isFormValid, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, onGetFormData, onGetErrorMessage, onCleanData } from '../../../../shared/utility'
import { request_update_product } from '../requests'
import { translations as translations_esp } from '../../../../texts/esp/product-translations'
import RichTextForm from '../../../../components/Forms/RichTextForm'


const GeneralInfo = ({product, id, onUpdateCompleted, onUpdateError, onUpdateSuccess, onUpdateSending}) => {

    const content = translations_esp.general_info

    //general
    const [sending, setSending] = useState(false)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))


    const [pharmaceutical_form, setFarmaceuticalForm] = useState(null)
    const [details, setDetails] = useState(null)
    const [indications, setIndications] = useState(null)
    const [contraindications, setContraindications] = useState(null)
    const [pregnancy_and_lactation, setPregnancyAndLactation] = useState(null)
    const [precautions_and_warnings, setPrecautionsAndWarnings] = useState(null)

    const [description, setDescription] = useState(null)
    
    //Boolean data
    const [for_adult, setForAdult] = useState(false)
    const [for_children, setForChildren] = useState(false)

    const [recipe_check, setRecipeCheck] = useState(false)
    const [retains_check, setRetainsCheck] = useState(false)
    //const [sale_check, setSaleCheck] = useState(false)
    //const [taxable_check, setTaxableCheck] = useState(false)

    useEffect(() => {
    const initModule = async() => {
        try {
            if(product){
                console.log(product.description)
                let _form = onInitEmptyForm(form, content.form)    
                _form = onInitForm(_form, product)
                setForm(_form)
                /////// Rich text data
                setDescription(product.description ? product.description : '')
                setFarmaceuticalForm(product.pharmaceutical_form ? product.pharmaceutical_form : '')
                setDetails(product.details ? product.details : '')
                setIndications(product.indications ? product.indications : '')
                setContraindications(product.contraindications ? product.contraindications : '')
                setPregnancyAndLactation(product.pregnancy_and_lactation ? product.pregnancy_and_lactation : '')
                setPrecautionsAndWarnings(product.precautions_and_warnings ? product.precautions_and_warnings : '')
                ///////
                setRecipeCheck(Boolean(product.requires_recipe))
                setRetainsCheck(Boolean(product.retains_recipe))
                setForChildren(Boolean(product.for_children))
                setForAdult(Boolean(product.for_adult))
                //setSaleCheck(Boolean(product.sale))
                //setTaxableCheck(Boolean(product.taxable))
            }
        } catch (error) {
            console.log(error)
        }
    }
    if(product) initModule()
    }, [product])

    useEffect(() => {window.scrollTo(0,0)}, [])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log(errors)
            return
        }


        let data2send = onGetSelectedFormData(form, product)

        console.log(data2send)

        // Boolean data
        data2send.requires_recipe = recipe_check ? 1 : 0
        data2send.retains_recipe = retains_check ? 1 : 0
        data2send.for_adult = for_adult ? 1 : 0
        data2send.for_children = for_children ? 1 : 0

        // Rich text data
        data2send.pharmaceutical_form = onCleanData(pharmaceutical_form)
        data2send.details =  onCleanData(details)
        data2send.indications =  onCleanData(indications)
        data2send.contraindications =  onCleanData(contraindications)
        data2send.pregnancy_and_lactation =  onCleanData(pregnancy_and_lactation)
        data2send.precautions_and_warnings =  onCleanData(precautions_and_warnings)

        //setSending(true)
        onUpdateSending(true)
        try {
            await request_update_product(id, data2send);
            onUpdateCompleted()
            //onUpdateSuccess(content.success_msg)
            //setSending(false)
        } catch (error) {
            console.log(error)
            onUpdateError(onGetErrorMessage(error))
            //setSending(false)
        }
        onUpdateSending(false)
    }

    return(
        <SimpleCard>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems='center' style={{marginBottom:16}}>
                        <Grid item xs>
                            <DisplayText variant='subtitle1'>{content.title}</DisplayText>
                        </Grid>
                        <Grid item >          
                            <ContainedButton color='primary' onClick={onSubmit} loading={sending}>{content.button}</ContainedButton>                 
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4} justify='flex-end'>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.sub_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.active_substance} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label='Forma farmacéutica y formulación' value={pharmaceutical_form} 
                            onChange={(value)=>setFarmaceuticalForm(value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.pharmaceutical_form_short} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.presentation} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.administration_route} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label='Información del producto / detalles' value={details} 
                            onChange={(value)=>setDetails(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label='Indicaciones Terapéuticas' value={indications} 
                            onChange={(value)=>setIndications(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label='Contraindicaciones' value={contraindications} 
                            onChange={(value)=>setContraindications(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label='Embarazo y Lactancia' value={pregnancy_and_lactation} 
                            onChange={(value)=>setPregnancyAndLactation(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label='Precauciones y Advertencias' value={precautions_and_warnings} 
                            onChange={(value)=>setPrecautionsAndWarnings(value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.short_description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={for_adult} onChange={()=>setForAdult(!for_adult)} />}
                                label='Adulto'
                            />
                        </Grid>
                        <Grid item xs={6} >                                  
                            <FormControlLabel
                                control={<Checkbox checked={for_children} onChange={()=>setForChildren(!for_children)} />}
                                label='Niño'
                            />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.package} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.product_quantity} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.measure} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                            <InputForm data={form.additional_measure} onChange={onChange} />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <InputForm data={form.cofepris} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.sku} onChange={onChange} />
                        </Grid>

                        <Grid item xs={12} >
                            <InputForm data={form.url} onChange={onChange} />
                        </Grid>
                       
                        
                        {/* <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={taxable_check} onChange={()=>setTaxableCheck(!taxable_check)} />}
                                label={content.formControl.taxable}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={sale_check} onChange={()=>setSaleCheck(!sale_check)} />}
                                label={content.formControl.sale}
                            />
                        </Grid> */}
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={recipe_check} onChange={()=>setRecipeCheck(!recipe_check)} />}
                                label={'¿Requiere receta?'}
                            />
                        </Grid>
                        <Grid item xs={6} >                                  
                            <FormControlLabel
                                control={<Checkbox checked={retains_check} onChange={()=>setRetainsCheck(!retains_check)} />}
                                label={'¿Retiene receta?'}
                            />
                        </Grid>
                         <Grid item xs={12}>
                            <DisplayText variant='subtitle2'>{content.subtitles.billing}</DisplayText>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.sat_unit_key} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.sat_product_key} onChange={onChange} />
                        </Grid>               
                        <Grid item >          
                            <ContainedButton color='primary' onClick={onSubmit} loading={sending}>{content.button}</ContainedButton>                 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default GeneralInfo

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Marca',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    sub_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sub_name',
            type: 'text',
            fullWidth: true,
            label: 'Sub Marca',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    active_substance: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'active_substance',
            type: 'text',
            fullWidth: true,
            label: 'Principio Activo / Denominación',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    presentation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'presentation',
            type: 'text',
            fullWidth: true,
            label: 'Presentación',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    administration_route: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'administration_route',
            type: 'text',
            fullWidth: true,
            label: 'Vía de administración',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 255',
        },
        rules: {
            type: 'distance',
            min: 1, max: 1024
        }
    },
    short_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'short_description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción Corta',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:3,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 1, max: 1024
        }
    },
    pharmaceutical_form_short: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'pharmaceutical_form_short',
            type: 'text',
            fullWidth: true,
            label: 'Forma farmacéutica corta',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:3,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 1, max: 1024
        }
    },
    description: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    package: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'package',
            type: 'text',
            fullWidth: true,
            label: 'Empquetado',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    product_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'product_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Cantidad',
            helperText: 'Este campo debe ser mayor a cero',
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    measure: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'measure',
            type: 'text',
            fullWidth: true,
            label: 'Medida',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    additional_measure: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'additional_measure',
            type: 'text',
            fullWidth: true,
            label: 'Medida adicional',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    weight: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'weight',
            type: 'text',
            fullWidth: true,
            label: 'Peso',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    height: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'height',
            type: 'text',
            fullWidth: true,
            label: 'Altura',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    width: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'width',
            type: 'text',
            fullWidth: true,
            label: 'Ancho',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    length: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'length',
            type: 'text',
            fullWidth: true,
            label: 'Largo',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    warranty: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'warranty',
            type: 'text',
            fullWidth: true,
            label: 'Garantía',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:2,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    sat_unit_key: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sat_unit_key',
            type: 'text',
            fullWidth: true,
            label: 'Clave unitaria sat',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:1,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    sat_product_key: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sat_product_key',
            type: 'text',
            fullWidth: true,
            label: 'Clave del producto',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:1,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    requires_recipe: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[{value:1, label:'Sí'},{value:0, label:'No'}],
        config: {
            id: 'requires_recipe',
            type: 'selected',
            fullWidth: true,
            label: 'Requiere receta',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    retains_recipe: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[{value:0, label:'No'},{value:1, label:'Sí'},],
        config: {
            id: 'retains_recipe',
            type: 'selected',
            fullWidth: true,
            label: 'Retiene receta',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    brand_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'brand_id',
            type: 'selected',
            fullWidth: true,
            label: 'Marca',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    sku: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sku',
            type: 'text',
            fullWidth: true,
            label: 'Sku',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    cofepris: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'cofepris',
            type: 'text',
            fullWidth: true,
            label: 'Cofepris',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    barcode: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'barcode',
            type: 'text',
            fullWidth: true,
            label: 'Barcode',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
}