import { useEffect } from "react"
import { useState } from "react"
import { onUpdateAuthorizationHeader } from "../../../shared/utility"
import { mainServer } from "../../../variables/config"

const useRoutesView = ({user}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)

    const [routes, setRoutes] = useState([])

    const [total, setTotal] = useState(0)
    const [filter, setFilter] = useState({query:''})
    const [pagination, setPagination] = useState({limit:50,offset:0})

    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [user])


    const actions = {
        onInitModule: async() => {
            try {
                onUpdateAuthorizationHeader()
                const request_routes = await mainServer.get(`/route/list`)
                setRoutes(request_routes.data.data.routes)
                setTotal(request_routes.data.data.total)
            } catch (error) {
                
            }
        },
        onRefreshData: async() => {
            try {
                onUpdateAuthorizationHeader()
                const request_routes = await mainServer.get(`/route/list`)
                setRoutes(request_routes.data.data.routes)
                setTotal(request_routes.data.data.total)
            } catch (error) {
                
            }
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),

    }


    const system = {loading, sending, error}
    const view_data = {routes, total, filter, pagination}


    return {system, view_data, actions, modals}

}

export default useRoutesView