import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Page from '../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import AddModal from './modals/AddModal'
import { request_doctors } from './requests'
import { translations as translations_esp } from '../../texts/esp/users-translations'


const Doctors = props => {

    const content = translations_esp
    const { history, user } = props

    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])

    //modals
    const [addModal, setAddModal] = useState(false)
 
    //table
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'id_user',
    })
    const [total, setTotal] = useState(10)
    const [search, setSearch] = useState(null)
    


    useEffect(() => {
        const initModule = async() => {try {
            if(!user) return
            console.log(user)
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            console.log(params)
            const _pacients = await request_doctors(params)
            setPatients(_pacients.users)
            setTotal(_pacients.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [user, tableFilter])

    
    const onSelectedItem = (item) => {
        const {id_user} = item
        history.push(`/doctors/${id_user}`)
    }
  
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(tableFilter, search)
        const _pacients = await request_doctors(params)
        setPatients(_pacients.users)
        setTotal(_pacients.count)
        setAddModal(false)
    }

    let can_create = true
    //if(user && (user.user_type_id === 1)) can_create = true

    return(
        <Page title={content.titles.doctors} >
            <AddModal history={history} user={user} open={addModal} onClose={() => setAddModal(false)} 
            onUpdateCompleted={onUpdateCompleted} content={content.modal} />
            <SimpleTable
                data={patients} 
                search={search}
                tableFilter={tableFilter}
                total={total}
                loading={loading}
                onAddRegister={can_create ? () => setAddModal(true) : null}
                onChangeSearch={onChangeSearch}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                content={content.tables.doctors}
                />
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        user: state.user
    }
}

export default connect(mapStateToProps)(Doctors)