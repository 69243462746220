import { Grid, Chip } from '@material-ui/core'
import React, {useState} from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../components/Texts/DisplayText'
import SearchTags from '../forms/SearchTags'
import AddTagModal from '../modals/AddTagModal'
import { request_create_product_tag, request_delete_product_tag } from '../requests'
import { translations as translations_esp } from '../../../../texts/esp/product-translations'


const Tags = ({id, product_tags, onUpdateTagCompleted }) => {

    const content = translations_esp.tags

    const [tag_modal, setTagModal] = useState(false)

    const onDeleteTag = async(id_tag) => {
        console.log(id_tag)
        await request_delete_product_tag(id_tag)
        onUpdateTagCompleted()
    }

    const onAddProductTag = async (el) => {
        try {
            await request_create_product_tag({product_id:id, tag_id:el, main:0});
            onUpdateTagCompleted()
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <SimpleCard>
        <AddTagModal open={tag_modal} onClose={()=>setTagModal(false)} onUpdateCompleted={onUpdateTagCompleted} 
        onAddProductTag={onAddProductTag} content={content.modal}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>                 
                    <DisplayText variant='subtitle1'>{content.title}</DisplayText>                
                </Grid>
                <Grid item xs={12}>
                    <SearchTags onAddTag={()=>setTagModal(true)} existingTags={product_tags} 
                    onAddProductTag={onAddProductTag} content={content.search_bar}/>
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={1}>
                        {product_tags.map((item, index)=>{
                            return(
                                <Grid item key={index}>
                                    <Chip label={item.category} onDelete={()=>onDeleteTag(item.id_product_tag)} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>      
        </SimpleCard>
    )
}

export default Tags