import React from 'react'
import { LinearProgress, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Icon } from '@material-ui/core'
import DisplayText from '../../../../components/Texts/DisplayText'
import { grey } from '@material-ui/core/colors'
import SearchBar from '../../../../components/Actions/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import empty from '../../../../assets/empty.png'
import moment from 'moment'
import { translations as translations_esp } from '../../../../texts/esp/tables-products-translations'
import AvailableStatus from '../../../../components/Structure/DisplayData/AvailableStatus'
import ProductImage from '../../../../components/Structure/Images/ProductImage'
import TableCellLink from '../../../../components/Structure/Navigation/TableCellLink'

const content = translations_esp.table

const header = content.header

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, 
        loading, onEdit, onDelete, onChangeStatus, sending} = props

   
    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item><SearchBar value={search} onChange={onChangeSearch} /></Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs><LinearProgress color='primary'/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'checkbox' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {product_id, product, title, description, image_mobile, order, enabled} = item

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><ProductImage src={image_mobile} type='TABLE'/></TableCell>
                                            <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText>{product_id}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='subtitle1'>{product}</DisplayText></TableCell>   
                                            <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText>{title}</DisplayText></TableCell>                         
                                            <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText>{description}</DisplayText></TableCell> 
                                            <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText>{order}</DisplayText></TableCell>   
                                            <TableCell className={classes.cell}><AvailableStatus onClick={()=>onChangeStatus(item)} status={enabled} loading={sending}/></TableCell> 
                                            <TableCell className={classes.cell} padding='checkbox'>
                                                <Grid container spacing={1} wrap='nowrap'>
                                                    <Grid item><IconButton onClick={()=>onEdit(item)}><Icon>edit</Icon></IconButton></Grid>
                                                    <Grid item><IconButton onClick={()=>onDelete(item)}><Icon>delete</Icon></IconButton></Grid>
                                                </Grid>
                                            </TableCell>                           
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable