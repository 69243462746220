import React from 'react'
import { makeStyles, Button, fade, CircularProgress } from '@material-ui/core'
import { blueGrey, red } from '@material-ui/core/colors'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    button:{
        padding:10,
        paddingRight:32,
        paddingLeft:32,
        fontSize:16,
        color:blueGrey[900],
        borderRadius:32
    },
    primary:{
        color:'white',
        background:theme.palette.primary.main,
        '&:hover':{background:theme.palette.primary.dark}
    },
    secondary:{
        color:'white',
        background:theme.palette.secondary.main,
        '&:hover':{background:theme.palette.secondary.dark}
    },
    red:{
        color:'white',
        backgroundColor: red[800],
        '&:hover':{background:red[700]}
    }
}))

const ContainedButton = ({color, onClick, children, loading, fullWidth, disabled}) => {

    const classes = useStyles()



    return(
        <Button variant='contained' onClick={onClick} fullWidth={fullWidth} disabled={disabled}
        className={cx({
            [classes.button]:true,
            [classes.primary]: color === 'primary',
            [classes.secondary]: color === 'secondary',
            [classes.red]:color === 'red',
        })}>
            {children}
            {loading ? (<CircularProgress style={{ width: 24, height: 24, marginLeft: 8, color: 'white' }} />) : null}
        </Button>
    )
}

export default ContainedButton