
export const translations={
    user_button:{
        my_profile:'Mi perfil',
        log_out:'Cerrar sesión',
        admin:'Administrador',
        doctor:'Doctor',
        patient:'Paciente'
    },
    
}