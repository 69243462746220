import { Grid } from '@material-ui/core'
import { ColorPicker } from 'material-ui-color';
import React, {useState, useEffect} from 'react'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../components/Texts/DisplayText'
import ImageContainer from '../components/ImageContainer';
import ToolbarTable from '../../../../../components/Structure/Navigation/ToolbarTable'
import { isFormValid, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../../../shared/utility'
import { request_tag, request_update_tag } from '../requests'
import { translations as translations_esp } from '../../../../../texts/esp/tags-translations'
import ChangeImageModal from './ChangeImageModal';

const EditTagModal = ({open, onClose, onUpdateCompleted, id, onUpdateSending, onUpdateSuccess, onUpdateError}) => {

    const content = translations_esp.modal
   
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [color, setColor] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    const [tag, setTag] = useState(null)

    const [image_modal, setImageModal] = useState(false)


    useEffect(() => {
    const initModule = async() => {
        if(!open){
            let _form = onInitEmptyForm(form, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
        if(open){
            const _tag = await request_tag(id)
            setTag(_tag.tag_data)
            setColor(_tag.tag_data.color)
            let _form = onInitForm(form, _tag.tag_data) 
            setForm(_form)
        }
    }
    initModule()
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetSelectedFormData(form, tag)
        data2send.color = color 
        //console.log(data2send)
        
        setLoading(true)
        onUpdateSending(true)
        setDisabled(true)
        try {
            await request_update_tag(id, data2send);
            setLoading(false)
            onUpdateSending(false)
            onUpdateSuccess(content.success_msg)
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            //setError('Hubo un error')
            onUpdateError(onGetErrorMessage(error))
            setLoading(false)
            onUpdateSending(false)
        }
        setDisabled(false)
    }

    const onUpdateImageCompleted = async() => {
        const _tag = await request_tag(id)
        setTag(_tag.tag_data)
    }

    const onChangeColor = (data) =>{
        if(data && data.css){
            setColor(data.css.backgroundColor)
        }else{
            setColor(data)
        }    
        //console.log(data)
    }

    //image web - mobile
    const menu = content.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id) => {
        if(id === 1) setType('web')
        if(id === 2) setType('mobile')
        setImageView(id)
    }

    let contentView = null

    switch(image_view){
        case 1:
            contentView = <ImageContainer image={tag ? tag.image_web : null} onClick={()=>setImageModal(true)}/>
            break
        case 2:
            contentView = <ImageContainer image={tag ? tag.image_mobile : null} onClick={()=>setImageModal(true)}/> 
            break
        default:
            break
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <ChangeImageModal id={id} open={image_modal} onClose={() => setImageModal(false)} type={type} onUpdateCompleted ={onUpdateImageCompleted}/>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.edit_title}</DisplayText>
                {/* <DisplayText >Por favor ingresa los datos solicitados</DisplayText> */}
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            {contentView}                            
                            <div style={{marginTop:12}}>
                                <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>                
                        <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='body2' color='textSecondary'>{content.form.color.label}</DisplayText>
                            <ColorPicker value={color} disableAlpha onChange={onChangeColor} 
                                 disablePlainColor/>
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.url} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default EditTagModal


const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255',
            multiline:true,
            rows:3
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 500
        }
    },
    /* color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'color',
            type: 'text',
            fullWidth: true,
            label: 'Color',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
}