import React, {useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import Frame from '../../components/Structure/Frame/Frame'
import PageRouter from '../../routes/pageRouter'
import { isTokenValid } from '../../shared/utility'
import { connect } from 'react-redux'
import {request_me} from './requests'
import { CircularProgress, Grid, Icon, makeStyles, Snackbar } from '@material-ui/core'
import DisplayText from '../../components/Texts/DisplayText'
import ErrorModal from '../../modals/ErrorModal'
import { green, blue } from '@material-ui/core/colors'
import { actionTypes } from '../../store/actions'


const useStyles = makeStyles(theme => ({
    success:{
        background:green[700]
    },
    sending:{
        background:blue[700]
    }
}))


const Dashboard = props => {

    const classes = useStyles()

    const {history, onUpdateUser, sending, success, error, onUpdateSuccess, onUpdateError} = props

    useEffect(() => {
        const initModule = async() => {
            console.log('Revisar')
            const _user = await request_me()
            onUpdateUser(_user.user)
        }
        if(!isTokenValid()){
            history.push('/signin')
            return
        }
        initModule()
    }, [])

    console.log('sending...', sending)


    const isAuth = isTokenValid()
    const redirectContent = !isAuth ? <Redirect to='/signin'/> : null

    const successContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.success}} 
    open={Boolean(success)} autoHideDuration={1500} 
    onClose={()=>onUpdateSuccess(null)}  
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><DisplayText style={{color:'white'}}>{success}</DisplayText></Grid>
    </Grid>}/>

    const sendingContent = <Snackbar  ContentProps={{className:classes.sending}} open={Boolean(sending)} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><CircularProgress size={18} style={{color:'white'}}/></Grid>
        <Grid item><DisplayText style={{color:'white'}}>Enviando...</DisplayText></Grid>
    </Grid>}  anchorOrigin={{vertical:'bottom', horizontal:'left'}}/>

    return(
        <Frame >
            <ErrorModal error={error} onClose={()=>onUpdateError(null)}  />
            {redirectContent}
            {isAuth ? <PageRouter /> : null}  
            {sendingContent}
            {successContent}
        </Frame>
    )
}

const mapStateToProps = state => {
    return {
        sending:state.sending,
        success:state.success,
        error:state.error
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.SYS_UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
