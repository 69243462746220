import React, {useState, useEffect} from 'react'
import { Card, ClickAwayListener, Divider, Grid, Grow, Icon, IconButton, makeStyles, Popper, Hidden } from '@material-ui/core'
import DisplayText from '../../../../Texts/DisplayText';
import { blueGrey } from '@material-ui/core/colors';
import UserAvatar from '../../../Sidebar/components/UserAvatar';
import { onGetFullname } from '../../../../../shared/utility';
import { USER_TYPES } from '../../../../../variables/config'
import { translations as translations_esp } from '../../../../../texts/esp/topbar-translations'

const useStyles = makeStyles(theme=>({
    root:{},
    card:{marginTop:8, borderRadius:16, boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',},
    menu:{marginTop:10, marginBottom:10, width:188},
    iconButton:{
        background:'white',
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover':{
            background:'white',
        },
    },
    buttonRoot:{
        //backgroundColor:'red'
        borderRadius:32,
        //border:`1px solid ${theme.palette.primary.main}`,
        backgroundColor:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    text:{
        marginLeft:8,
        marginRight:20,
        paddingTop:4
    },
    userType:{
        fontSize:'0.8rem'
    }
}))

const UserButtonAvatar = props => {

    const classes = useStyles();
    const content = translations_esp.user_button
    const {onItemSelected, isAuth, onLogOut, history, user} = props
    const [anchorEl, setAnchorEl] = useState(null)

    const [name, setName] = useState('')
    const [userType, setUserType] = useState('')

    useEffect(() => {
        const initModule = async() => {try {
            const last_name = onGetFullname(user.fathers_last_name, user.mothers_last_name)
            if(user.first_name !== null && last_name !== null){
                setName(user.first_name + ' ' + last_name)
            }
            if(user.business_name && user.business_name !== null){
                setName(user.business_name)
            }
            if(user.user_type_id === USER_TYPES.ADMIN) setUserType(content.admin)   
            if(user.user_type_id === USER_TYPES.DOCTOR) setUserType(content.doctor)
            if(user.user_type_id === USER_TYPES.PATIENT) setUserType(content.patient)
        } catch (error) {
            console.log(error)
        }}
        if(user) initModule()
    }, [user])


    const onInnerItemSelected = (identifier) => {
        setAnchorEl(null)
        if(identifier === 'logout'){
            onLogOut()
            return
        }
        if(onItemSelected) onItemSelected(identifier)
    }

    const buttonContent = <Card className={classes.card}>
        <div className={classes.menu}>
            <Grid container>
                <Grid item xs={12}>
                    <OptionComponent icon='person' label={content.my_profile} id='/profile' onItemSelected={onInnerItemSelected}/>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
                    <OptionComponent icon='login' label={content.log_out} id='logout' onItemSelected={onInnerItemSelected}/>
                </Grid>
            </Grid>
        </div>
    </Card>

    return(
        <div className={classes.root}> 
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-end' disablePortal >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                        {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <div className={classes.buttonRoot}>
                <Grid container>
                    <Grid item><UserAvatar {...user} topbar={true} /></Grid>
                    <Hidden smDown>
                    <Grid item>
                        <div className={classes.text}>
                        <DisplayText >{name}</DisplayText>
                        <DisplayText className={classes.userType} >{userType}</DisplayText>
                        </div>
                    </Grid>
                    </Hidden>
                    <Grid item>
                        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} className={classes.iconButton}>
                            <Icon style={{color:blueGrey[700]}}>expand_more</Icon>
                        </IconButton>
                    </Grid>
                </Grid> 
            </div>
        </div>
    )
}

export default UserButtonAvatar

const useStyles4Option = makeStyles(theme=>({
    root:{
        padding:theme.spacing(1),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            background:blueGrey[50],
            transition:theme.transitions.create(['background'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    icon:{color:theme.palette.primary.main}
}))

const OptionComponent = props => {

    const {id, icon, label, onItemSelected} = props
    const classes = useStyles4Option()

    return(
        <div className={classes.root} onClick={() => onItemSelected(id)}>
            <Grid container spacing={1} wrap='nowrap'>
                <Grid item><Icon className={classes.icon}>{icon}</Icon></Grid>
                <Grid item><DisplayText color='bluegrey'>{label}</DisplayText></Grid>
            </Grid>
        </div>
    )
}