import React, {useState, useEffect} from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Page from '../../components/Structure/Page/Page'
import DisplayText from '../../components/Texts/DisplayText'
import { catalogs } from '../../texts/esp/catalogs'
import { isFormValid, isTokenValid, onGetErrorMessage, onGetJSONData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../shared/utility'
import InputForm from '../../components/Forms/InputForm'
import SelectForm from '../../components/Forms/SelectForm'
import { connect } from 'react-redux'
import * as actionTypes from '../../store/actions'
import { request_profile, request_update_profile } from './requests'
import UserInfoCard from './components/UserInfoCard'
import SimpleCard from '../../components/Structure/Cards/SimpleCard'
import ContainedButton from '../../components/Actions/ContainedButton'
import UpdatePasswordModal from './modals/UpdatePasswordModal'
import ChangeImageModal from './modals/ChangeImageModal'
import { translations as translations_esp } from '../../texts/esp/profile-translations'


const useStyles = makeStyles(theme => ({
    root:{

    },
    image:{
        width:300
    },
    form:{
        marginTop:32
    }
}))


const ProfileView = (props) => {

    const content = translations_esp

    const breadcrumbs = [
        {id:1, label:content.breadcrumbs_label, url:'/profile', selected:true}
    ]
    
    const {history, onUpdateUser} = props
    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [valid, setValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)
    const [sending, setSending] = useState(false)
    const [userID, setUserID] = useState(null)
    const [type, setType] = useState(null)

    //Modals 
    const [update_image_modal, setUpdateImageModal] = useState(false)
    const [password_modal, setPasswordModal] = useState(false)


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _user = await request_profile()
            console.log(_user)
            let _form  = {...form}
            _form = onInitEmptyForm(form, content.edit_card.form)
            let temp = {..._form}
            Object.keys(form).forEach(item => {
                if(_user.user[item]){
                    temp[item].value = _user.user[item]
                    temp[item].isVisited = true
                    temp[item].isValid = true
                }
            })
            setUser(_user.user)
            setUserID(_user.user.id_user)
            setType(_user.user.user_type_id)
            setValid(isFormValid(temp))
            setForm(temp)
            onUpdateUser(_user.user)
        } catch (error) {
            setError(onGetErrorMessage(error))
        } setLoading(false)}
        if(isTokenValid()){initModule()}else{history.push('/')}
    }, [])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
        setValid(isFormValid(temp))
    }

    const onSubmit = async() => {
     
        const errors = isFormValid(form)
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        setSending(true)
        try {
            const data2send = onGetJSONData(form)
            const updatedUser = await request_update_profile(data2send) 
            console.log(updatedUser)
            onUpdateCompleted(updatedUser)

        } catch (error) {
            setError(JSON.stringify(error))
            console.log(error)
        }
        setSending(false)        
    }

    const onUpdateCompleted = async() => {
        try {
            const _user = await request_profile()
            const _form = onInitForm(form, _user.user)
            setUser(_user.user)
            setForm(_form)
            setUpdateImageModal(false)
            setPasswordModal(false)

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }


    return(
        <Page title={content.title} breadcrumbs={breadcrumbs} loading={loading} error={error} onCloseErrorModal={() => setError(null)}>
            <UpdatePasswordModal userID={userID} type={type} open={password_modal} onClose={() => setPasswordModal(false)} 
            onUpdateCompleted ={onUpdateCompleted} content={content.user_info_card.modal}/>
            <ChangeImageModal id={userID} type={type} open={update_image_modal} onClose={() => setUpdateImageModal(false)} onUpdateCompleted ={onUpdateCompleted}/>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <UserInfoCard {...user} type={type} onChangePicture={() => setUpdateImageModal(true)} 
                    onChangePassword={() => setPasswordModal(true)} content={content.user_info_card} />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <SimpleCard>
                        <Grid container spacing={4} justify='flex-end'>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle1'>{content.edit_card.title}</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} >
                                        <InputForm data={form.first_name} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <InputForm data={form.fathers_last_name} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <InputForm data={form.mothers_last_name} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputForm data={form.email} onChange={onChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectForm data={form.user_gender_id} onChange={onChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <ContainedButton color='primary' loading={sending} onClick={onSubmit}>{content.edit_card.button}</ContainedButton>
                            </Grid>
                        </Grid>
                    </SimpleCard>
                </Grid>
            </Grid>
        </Page>
    )

}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user })
    }
}

export default connect(null, mapDispatchToProps)(ProfileView)

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    fathers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido paterno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    mothers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido materno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true

        },
        rules: {
            type: 'email',
        }
    },
    user_gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'user_gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
    },
}