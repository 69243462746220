import React from 'react';
import PublicRouters from './routes/router';
import { createBrowserHistory } from "history";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './store/reducer'
import { blueGrey } from '@material-ui/core/colors';
import { theme } from './theme';


const store = createStore(reducer);


const App = (props) => {

  return(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <PublicRouters history={createBrowserHistory()}/>
      </MuiThemeProvider>
    </Provider>
  )
}

export default App;
