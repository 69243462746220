import { Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../components/Texts/DisplayText'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../shared/utility'
import { request_create_newproduct, request_products } from '../requests'
import { catalogs as cat } from '../../../../texts/esp/catalogs'

const AddModal = ({open, onClose, onUpdateCompleted, content, existing_products}) => {

    
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
    const onInitModal = async() => {
        if (!open) {
            let _form = onInitEmptyForm(form, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }else{
            const products = await request_products()
            const catalogs = onGetCatalogs(products.products_data, 'id_product', 'name')

            let newCatalogs = []
            if(existing_products.length !== 0){

                const opt = catalogs.map((item)=>{
                    let ex= true
                    for (let index = 0; index < existing_products.length; index++) {
                        if (item.value === existing_products[index].product_id) ex = false
                    }
                    if(ex){
                        return item
                    }else{
                        return null
                    }
                })
                let final = []
                opt.forEach(el => {
                    if(el !== null) final.push(el)
                })
                newCatalogs = onGetCatalogs(final, 'value', 'label')
            }else{
                newCatalogs = [...catalogs]
            }

            let _form = {...form}
            _form.product_id.options = newCatalogs
            setForm(_form)
        }
    }
    onInitModal()
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        data2send.enabled = 1
        console.log(data2send)
        

        setLoading(true)
        setDisabled(true)
        try {
            await request_create_newproduct(data2send);
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add_title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <SelectForm data={form.product_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.title} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.order} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.add_button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddModal


const formData = {
    product_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'product_id',
            type: 'text',
            fullWidth: true,
            label: 'Producto',
            helperText: 'Debe elegir una opción'
        },
        rules: {
            type: 'select',
        }
    },
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
            label: 'Título',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...cat.order_levels],
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
            label: 'Orden',
            helperText: 'Debe elegir una opción'
        },
        rules: {
            type: 'select',
        }
    },
}