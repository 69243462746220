import React from 'react'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import SimpleModal from '../components/Overlays/SimpleModal'
import DisplayText from '../components/Texts/DisplayText'
import ActionModalBar from '../components/Actions/ActionModalBar'
import { translations as translations_esp } from '../texts/esp/modals-translations'


const useStyles = makeStyles(theme => ({
    icon:{
        color:red[700],
        fontSize:54,
        border:`2px solid ${red[700]}`,
        padding:8,
        borderRadius:'50%'
    },
}))

const WarningModal = props => {

    const content = translations_esp.warning_modal
 
    const { open, onClose, onSubmit, loading, error, disabled } = props

    const classes = useStyles()
   
    return(
        <SimpleModal open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <Icon fontSize='large' className={classes.icon}>clear</Icon>
                            </Grid>
                            <Grid item>
                                <DisplayText variant='h6'  align='center'>{content.title}</DisplayText>
                            </Grid>
                            <Grid item>
                                <DisplayText variant='body1' align='center'>{content.message}</DisplayText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar loading={loading} error={error} btnLabel={content.button} btnColor='red' 
                        onSubmit={onSubmit} disabled={disabled}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default WarningModal