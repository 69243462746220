import { CircularProgress, Grid, Icon, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DisplayText from '../../../../components/Texts/DisplayText';
import { onGetCatalogs } from '../../../../shared/utility';
import { request_categories } from '../requests';


const SearchCategories = ({onAddItem, existingItems, onAddProductItem, content}) => {

    const classes = useStyles()
    const add_register = content.add_register


    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([add_register]);
    const [value, setValue] = useState(null)
  
    const onChange = (e, item) => {
        if(item){
            if(item.type === 'add'){
                //console.log('agregar')
                onAddItem()
                setValue({value:-1, label:''})
                return
            }else{
                setValue(item)
                onAddProductItem(item.value)
                //console.log(item)
                setValue({value:-1, label:''})
            }
        }else{
            setValue(null)
        }      
    }

    const onInputChange = async(e) => {
        if(!e || e.target.value === 0) return
        try {
            setLoading(true)
            let _categories = await request_categories()
            const categories = [..._categories.categories_data]   
            //console.log(categories)    
            const opt = categories.map((item)=>{
                let ex= true
                for (let index = 0; index < existingItems.length; index++) {
                    if (item.id_category === existingItems[index].category_id) ex = false
                }
                if(ex){
                    return item
                }else{
                    return null
                }
            })
            let final = []
            opt.forEach(el => {
                if(el !== null) final.push(el)
            })
            const catalogs = onGetCatalogs(final, 'id_category', 'name')
            setOptions([add_register, ...catalogs])    
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }


    return (  
        <div>
            <Autocomplete
                id="search-user-id"
                getOptionLabel={(option) => option.label}
                options={options}
                loading={loading} fullWidth
                value={value}
                onChange={onChange}
                onInputChange={onInputChange}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={content.placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                    />
                )}
                renderOption={(option, {selected}) => {
                    if(option.type === 'add'){
                        return(
                            <React.Fragment>
                                <div >
                                    <Grid container alignItems='center'>
                                        <Grid item><Icon color='primary' className={classes.add_icon}>add</Icon></Grid>
                                        <Grid item xs><DisplayText>{option.label}</DisplayText></Grid>
                                    </Grid>
                                    
                                </div>
                            </React.Fragment>
                        )
                    }
                    return (
                        <React.Fragment>
                            <div>
                                <DisplayText>{option.label}</DisplayText>
                            </div>
                        </React.Fragment>
                    )
                }}
            />
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    add_icon:{
        background:theme.palette.primary.main,
        color:'white',
        borderRadius:'50%',
        marginTop:6, marginRight:8
    }
}))
 
export default SearchCategories;