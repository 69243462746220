import { Grid } from '@material-ui/core'
import React from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import wareimage from '../../../../assets/warehouse.png'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFullAddress } from '../../../../shared/utility'
import MapView from './MapView.jsx'
import DisplayIconData from '../../../../components/Structure/DisplayData/DisplayIconData'
import InputForm from '../../../../components/Forms/InputForm'

const AddressCard = ({data}) => {

    const address_info = {
        street: data ? data.street : null, 
        suburb: data ? data.suburb : null, 
        municipality: data ? data.municipality : null, 
        state: data ? data.state : null
    }

    return(
        <SimpleCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={4} xs={12}>
                            <div style={{width:100}}>
                                <img src={wareimage} alt='' style={{width:'100%', height:'100%'}} />
                            </div> 
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Grid container spacing={2} >
                                <Grid item><DisplayText variant='subtitle1'>{data ? data.contact_name : null}</DisplayText> </Grid>
                                <Grid item><DisplayIconData icon='mail' data={data ? data.contact_email : '-'}/> </Grid>
                                <Grid item><DisplayIconData icon='smartphone' data={data ? data.contact_phone: '-'}/></Grid>
                            </Grid> 
                        </Grid>
                    </Grid>    
                </Grid>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1'>Dirección</DisplayText>
                    <DisplayText>{onGetFullAddress(address_info)}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <MapView data={data} />
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default AddressCard

