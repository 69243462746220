import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { pdf } from '@react-pdf/renderer';
import FileSaver from 'file-saver';
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { onGetErrorMessage } from '../../../shared/utility'
import { ORDER_STATUS_TYPES, PAYMENT_STATUSES, USER_TYPES } from '../../../variables/config'
import { nodata } from '../../../variables/config'
import { translations as translations_esp } from '../../../texts/esp/order-translations'

import { request_mercadopago_payment, request_order, request_patient, request_update_billing_address, 
    request_update_order, request_update_payment, request_update_shipping_address } from './requests'

import Page from '../../../components/Structure/Page/Page'

import ProductsContainer from './components/ProductsContainer' // products/ProductCheckoutList
import InvoiceDocument from './components/InvoiceDocument' //
import DeliveryDocument from './components/DeliveryDocument' //
import HeaderContainer from './components/HeaderContainer' //
import OverviewOrder from './components/OverviewOrder' //payment option
import UserInfoCard from './components/UserInfoCard' //
import DeliveryCard from './components/DeliveryCard' //


const OrderView = props => {

    const { match, user} = props
    const content = translations_esp

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [order, setOrder] = useState(null)
    const [error, setError] = useState(null)

    const order_id = match.params.id


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _order = await request_order(order_id)
            console.log(_order)
            setOrder(_order.orders)
            /* if(_order.orders.cart_id){
                const _cart = await request_cart(_order.orders.cart_id)
                setCart(_cart.)
            } */
            await onCheckPayment(_order)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const onCheckPayment = async(_order) => {
        try {
            if(_order.status_id === ORDER_STATUS_TYPES.PENDING){
                if(_order.payment){
                    const {token_id, payment_url, payment_id, gateway} = _order.payment
                    console.log(_order.payment)
                    if(gateway === 'mercadopago'){
                        const _payment = await request_mercadopago_payment(order_id)
                        console.log(_payment)
                        if(_payment.results && _payment.results.length){
                            // There is a payment
                            const last_item = _payment.results.length - 1
                            const mp_payment = _payment.results[last_item]
                            console.log(mp_payment)
                            if(mp_payment.status === 'approved' || mp_payment.status === 'authorized'){
                                console.log('Payment status is aprroved or authorized, therefore the order will be mark as completed')
                                let data2send = {payment_id:mp_payment.id}
                                if(mp_payment.status === 'approved') data2send.status_id = PAYMENT_STATUSES.COMPLETED
                                if(mp_payment.status === 'authorized') data2send.status_id = PAYMENT_STATUSES.COMPLETED
                                await request_update_payment(order_id, data2send)
                                await request_update_order(order_id, {status_id:ORDER_STATUS_TYPES.CONFIRMED})
                                const _updated_order = await request_order(order_id)
                                setOrder(_updated_order)
                            }else{
                                console.log(mp_payment.status)
                                console.log(_order.payment.status_id)
                                if(_order.payment.status_id === PAYMENT_STATUSES.PENDING){
                                    await request_update_payment(order_id, {status_id: mp_payment.status === 'in_process' ? PAYMENT_STATUSES.INPROGRESS : PAYMENT_STATUSES.ERROR})
                                    const _updated_order = await request_order(order_id)
                                    setOrder(_updated_order)
                                }
                                if(_order.payment.status_id === PAYMENT_STATUSES.ERROR && mp_payment.status === 'in_process'){
                                    await request_update_payment(order_id, {status_id: mp_payment.status === 'in_process' ? PAYMENT_STATUSES.INPROGRESS : PAYMENT_STATUSES.ERROR})
                                    const _updated_order = await request_order(order_id)
                                    setOrder(_updated_order)
                                }
                                
                            }
                        }
                    }
                }
            }else{
                console.log('Payment audit is not neccesary')
            }
        } catch (error) {
            
        }
    }

    const onDownloadInvoice = async() => {
        const pdfAsBlob = await pdf(
            <InvoiceDocument {...order} />
        ).toBlob();
        FileSaver.saveAs(pdfAsBlob,'invoice.pdf')
    }

    const onDownloadDelivery = async() => {
        const pdfAsBlob = await pdf(
            <DeliveryDocument {...order} />
        ).toBlob();
        FileSaver.saveAs(pdfAsBlob,'delivery.pdf')
    }

    const setAddPatient2Order = async(patient_id) => {
        try {await request_update_order(order_id, {user_id:patient_id})
        } catch (error) {setError(onGetErrorMessage(error))}
    }

    const onUpdateOrder = async(data) => {
        try {
            setSending(true)
            console.log(data)
            // Data
            if(data.user_id){
                const _patient = await request_patient(data.user_id)
                if(_patient && _patient.optical_id) data.optical_id =  _patient.optical_id
            }
            await request_update_order(order_id, data)
            const _order = await request_order(order_id)
            setOrder(_order)

        } catch (error) {
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onUpdateAddressCompleted = async() =>{
        const _order = await request_order(order_id)
        setOrder(_order)
    }

    const onAutoFill = async(id) => {
        let data2send
        console.log(id)
        try {
            if(id === 'shipping'){
                setSending(true)
                if(!order.user.shipping_address){
                    setError('El usuario no tiene una dirección de envío registrada')
                    setSending(false)
                    return
                }
                const user_shipping_address = order.user.shipping_address
                data2send = {
                    address1:user_shipping_address.address1,
                    address2:user_shipping_address.address2,
                    city:user_shipping_address.city,
                    province:user_shipping_address.province,
                    country:user_shipping_address.country,
                }
                if(data2send){
                    await request_update_shipping_address(order_id, data2send)
                    const _order = await request_order(order_id)
                    setOrder(_order)
                }else{
                    setError('El usuario no tiene una dirección registrada')
                }
            }else{
                setSending(true)
                if(!order.user.billing_address){
                    setError('El usuario no tiene una dirección de facturación registrada')
                    setSending(false)
                    return
                }
                const user_billing_address = order.user.billing_address
                data2send = {
                    address1:user_billing_address.address1,
                    address2:user_billing_address.address2,
                    city:user_billing_address.city,
                    province:user_billing_address.province,
                    country:user_billing_address.country,
                }
                if(data2send){
                    await request_update_billing_address(order_id, data2send)
                    const _order = await request_order(order_id)
                    setOrder(_order)
                }else{
                    setError('El usuario no tiene una dirección registrada')
                }
            }
        } catch (error) {
            setError('Hubo un error')
        }
        setSending(false)
    }


    let date = ''; let is_user = false
    let listItems = []
    if(order){
        //console.log(order)
        const timezone = moment.tz.guess()
        date = moment(order.created_at).tz(timezone)
        date = date.isValid() ? date.format('DD MMM YYYY, HH:mm:ss') : nodata
        is_user = order.user_id ? true : false
        listItems = order.cart ? order.cart.line_items : []

    }

    let currentOpticalID = null
    let userType = null

    if(user && user.user_type_id === USER_TYPES.OPTICAL) currentOpticalID = user._id
    if(user && user.user_type_id === USER_TYPES.OPTOMETRIST) currentOpticalID = user.optical_id
    if(user) userType = user.user_type_id


    return(
        <Page title={content.title} loading={loading} goback sending={sending} error={error} onCloseErrorModal={() => setError(null)}>
            {/* <ShippingAddressModal id={order_id} open={shippingModal} onClose={() => setShippingModal(false)} inputdata={order ? order.shipping_address : null}
            onUpdateData={(data) => setOrder(data)}/>
            <BillingAddressModal id={order_id} open={billing_address_modal} onClose={() => setBillingAddressModal(false)} inputdata={order ? order.billing_address : null}
            onUpdateData={(data) => setOrder(data)}/> */}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HeaderContainer userType={userType} order={order} sending={sending} onUpdateOrder = {onUpdateOrder} />
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <UserInfoCard content={content.client_info} user={order ? order.user : null} email={order ? order.email : null} mobile={order ? order.mobile : null}/>
                        </Grid>
                        <Grid item xs={12}>
                            <OverviewOrder order={order} content={content.summary} onDispatchSending={(data) => setSending(data)} onDispatchError={(e) => setError(e)} 
                            onUpdateOrder={(data) => setOrder(data)} onUpdateOrder={(data) => setOrder(data)}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}  lg={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <DeliveryCard order={order} content={content.delivery} />
                        </Grid>
                        <Grid item xs={12}>
                            <ProductsContainer listItems={listItems} content={content.list_products} />
                        </Grid>     
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(OrderView)

const formData = {
    
}