import { success_msg } from "./general-translations"

export const translations = {
    title:'Sucursal',
    success_msg:success_msg,
    menu:[{id:1, label:'Información general'}, {id:2, label:'Inventario'}],
    edit_card:{
        title:'Información general',
        title_secondary:'Información de la dirección',
        button:'Guardar',
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_name:{
                label:'Nombre de contacto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_email:{
                label:'Email',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_phone:{
                label:'Telefono de contacto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            street:{
                label:'Calle',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            ext:{
                label:'Número exterior',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            int:{
                label:'Número interior',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            additional_indications:{
                label:'Indicaciones adicionales',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            zip_code:{
                label:'Código postal',
                helperText:'Debe seleccionar una opción'
            },
            municipality_id:{
                label:'Municipio',
                helperText:'Debe seleccionar una opción',
            },
            state_id:{
                label:'Estado',
                helperText:'Debe seleccionar una opción'
            },
            country_id:{
                label:'País',
                helperText:'Debe seleccionar una opción'
            },
            suburb_id:{
                label:'Colonia',
                helperText:'Debe seleccionar una opción'
            },
            map_url:{
                label:'URL del mapa',
                helperText:'Debe contener una url'
            },
            latitude:{
                label:'Latitud',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            longitude:{
                label:'Longitud',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
        },
        formControl:{
            store:'Productos en tienda',
            pickup:'Entrega en tienda',
            delivery:'Entrega a domicilio'
        }
    },
    inventory:{
        table: {
            header:[
                {id:1, label:'ID'},
                {id:2, label:'ID del producto'},
                {id:3, label:'Producto'},
                {id:4, label:'Cantidad'},
                {id:4, label:''},
            ],
            nodata:'No existen productos registrados'
        },
        modal:{
            add_title:'Agregar',
            edit_title:'Editar cantidad',
            button:'Agregar',
            form:{
                product_id:{
                    label:'Producto',
                    helperText: 'Debe elegir una opción'
                },
                available:{
                    label:'Productos disponibles',
                    helperText:'No admite números negativos'
                }
            },
        }
    }
    
}
