import axios from 'axios'
const { mainServer, BASIC_AUTH_HEADER } = require("../../../../../variables/config");

//gets
export const request_segments = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/segment/list${params}`)
    return response.data.data
}

export const request_segment = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/segment/${id}`)
    return response.data.data
}

export const request_products_segments = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/filter/${params}`)
    return response.data.data
}

export const request_subcategories = async() => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/subcategory/list` )
    return response.data.data
}

export const request_products = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/filter${params}` )
    return response.data.data
}

//posts
export const request_create_segment = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/segment`, data)
    return response.data.data
}

export const request_create_product_segment = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/productsegment`, data)
    return response.data.data
}

//patchs
export const request_update_segment = async (id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/segment/${id}`, data)
    return response.data.data
}

//deletes
export const request_delete_product_segment= async (id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/productsegment/${id}`)
    return response.data.data
}

export const request_delete_segment= async (id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/segment/${id}`)
    return response.data.data
}

/*----------- MEDIA -----------*/
//posts
export const request_upload_segment_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/segment/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
}